import { Box, Grid, IconButton, Paper, Typography } from "@material-ui/core"
import { useContext } from "react"
import { Helmet } from "react-helmet"
import { Calling, Chat, Message } from "react-iconly"
import { IoLogoFacebook } from "react-icons/io5"
import textTransform from "../../config/functions/text"
import { UserContext } from "../../contexts/user.context"

const ContactRoute = () => {
    const { chi_nhanh, user } = useContext(UserContext)

    return (
        <>
            <Helmet>
                <title>Hỗ trợ</title>
            </Helmet>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h6" style={{ fontWeight: 700 }}>Giới Thiệu Bưu Cục</Typography>
                    <Box height={16} />
                    <Paper variant="outlined" style={{ padding: 16 }}>
                        <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>{chi_nhanh.sp_intro}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" style={{ fontWeight: 700 }}>Thông Tin Liên Hệ</Typography>
                    {/* <Box height={16} />
                    <Alert severity="info">Thời gian làm việc từ 7h30 đến 18h, tất cả các ngày trong tuần.</Alert> */}
                </Grid>
                {
                    chi_nhanh.sp_phone &&
                    <>
                        <Grid item xs={12} md={4}>
                            <Paper variant="outlined" style={{ padding: 16 }}>
                                <Typography variant="h6" style={{ fontWeight: 700 }}>Hotline (Bưu Cục)</Typography>
                                <Box marginTop={2} display="flex" justifyContent="space-between">
                                    <Typography variant="h4" style={{ fontWeight: 'initial' }}>{textTransform.mobile(chi_nhanh.sp_phone)}</Typography>
                                    <div>
                                        <IconButton onClick={() => window.location.href = 'tel:' + chi_nhanh.sp_phone} style={{ border: '1px solid #f2f2f2' }}>
                                            <Calling />
                                        </IconButton>
                                    </div>
                                </Box>
                            </Paper>
                        </Grid>
                    </>
                }
                {
                    chi_nhanh.sp_zalo &&
                    <>
                        <Grid item xs={12} md={4}>
                            <Paper variant="outlined" style={{ padding: 16 }}>
                                <Typography variant="h6" style={{ fontWeight: 700 }}>Zalo (Bưu Cục)</Typography>
                                <Box marginTop={2} display="flex" justifyContent="space-between">
                                    <Typography variant="h4" style={{ fontWeight: 'initial' }}>{textTransform.mobile(chi_nhanh.sp_zalo)}</Typography>
                                    <div>
                                        <IconButton style={{ border: '1px solid #f2f2f2', marginRight: 8 }} onClick={() => window.open('https://zalo.me/' + chi_nhanh.sp_zalo, '_blank')}>
                                            <Chat />
                                        </IconButton>
                                    </div>
                                </Box>
                            </Paper>
                        </Grid>
                    </>
                }
                {
                    chi_nhanh.sp_mail &&
                    <>
                        <Grid item xs={12} md={4}>
                            <Paper variant="outlined" style={{ padding: 16 }}>
                                <Typography variant="h6" style={{ fontWeight: 700 }}>Email (Bưu Cục)</Typography>
                                <Box marginTop={2} display="flex" justifyContent="space-between">
                                    <Typography variant="h4" style={{ fontWeight: 'initial' }}>{chi_nhanh.sp_mail}</Typography>
                                    <div>
                                        <IconButton onClick={() => window.location.href = 'mailto:' + chi_nhanh.sp_mail + '?subject=GHSV::Yêu cầu hỗ trợ: ' + chi_nhanh.code + '.' + user.username} style={{ border: '1px solid #f2f2f2', marginRight: 8 }}>
                                            <Message />
                                        </IconButton>
                                    </div>
                                </Box>
                            </Paper>
                        </Grid>
                    </>
                }
                <Grid item xs={12} md={4}>
                    <Paper variant="outlined" style={{ padding: 16 }}>
                        <Typography variant="h6" style={{ fontWeight: 700 }}>Hotline (GHSV)</Typography>
                        <Typography variant="caption">Nếu gặp vấn đề chưa thể giải quyết với bưu cục thuộc GHSV, hãy liên lạc với chúng tôi qua số này.</Typography>
                        <Box marginTop={2} display="flex" justifyContent="space-between">
                            <Typography variant="h4" style={{ fontWeight: 'initial' }}>1900 633 584</Typography>
                            <div>
                                <IconButton onClick={() => window.location.href = 'tel:1900633584'} style={{ border: '1px solid #f2f2f2' }}>
                                    <Calling />
                                </IconButton>
                            </div>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper variant="outlined" style={{ padding: 16 }}>
                        <Typography variant="h6" style={{ fontWeight: 700 }}>Email (GHSV)</Typography>
                        <Typography variant="caption">... Hoặc email</Typography>
                        <Box marginTop={2} display="flex" justifyContent="space-between">
                            <Typography variant="h4" style={{ fontWeight: 'initial' }}>contact@ghsv.vn</Typography>
                            <div>
                                <IconButton onClick={() => window.location.href = 'mailto:contact@ghsv.vn?subject=GHSV::Yêu cầu hỗ trợ: ' + chi_nhanh.code + '.' + user.username} style={{ border: '1px solid #f2f2f2' }}>
                                    <Message />
                                </IconButton>
                            </div>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" style={{ fontWeight: 700 }}>Mạng Xã Hội</Typography>
                </Grid>
                {
                    chi_nhanh.sp_fb &&
                    <>
                        <Grid item xs={12} md={4}>
                            <Paper variant="outlined" style={{ padding: 16, background: '#2d6cdf', color: '#fff' }}>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography variant="h4" style={{ fontWeight: 'initial' }}>Fanpage (BC)</Typography>
                                    <div>
                                        <IconButton onClick={() => window.open('https://fb.com/' + chi_nhanh.sp_fb, '_blank')} style={{ border: '1px solid #f2f2f2', marginRight: 8 }}>
                                            <IoLogoFacebook color="#fff" />
                                        </IconButton>
                                    </div>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper variant="outlined" style={{ padding: 16, borderColor: '#4fa8e9', color: '#4fa8e9' }}>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography variant="h4" style={{ fontWeight: 'initial' }}>Fanpage (GHSV)</Typography>
                                    <div>
                                        <IconButton onClick={() => window.open('https://fb.com/ghsv.vn', '_blank')} style={{ border: '1px solid #4fa8e9', marginRight: 8 }}>
                                            <IoLogoFacebook color="#4fa8e9" />
                                        </IconButton>
                                    </div>
                                </Box>
                            </Paper>
                        </Grid>
                    </>
                }
            </Grid>
        </>
    )
}

export default ContactRoute