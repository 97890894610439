import { CircularProgress, Fade, Tooltip, Zoom, Box, Container, useMediaQuery } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { ArrowDown, Bag, Filter, User } from 'react-iconly';
import { useHistory } from 'react-router';
import color from '../config/color';
import textTransform from '../config/functions/text';
import { UserContext } from '../contexts/user.context';
import tableStyles from '../css/Table.module.sass';
import Copy from './Copy';
import orderModel from "../config/order";
import { Pagination } from '@material-ui/lab';
import { kmess, sdmY } from '../config/config';
import MobilePaper from './MobilePaper';
import CopyToClipboard from './Copy'
import UserDetect from './UserDetect';

const MobileOrder = ({ data }) => {
    return <MobilePaper variant="outlined">
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <span style={{ fontWeight: 700 }}>
                <CopyToClipboard text={data.code_supership}>
                    {data.code_supership}
                </CopyToClipboard>
            </span>
            <Box fontSize={12}>{sdmY(data.date_create)}</Box>
        </Box>
        {data.soc && <div style={{
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 'calc(100vw - 32px)',
            whiteSpace: 'nowrap'
        }}><CopyToClipboard text={data.code_orders}>MĐKH: {data.code_orders}</CopyToClipboard></div>}
        <Box marginTop={1} />
        <div style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 2
        }}><Bag set="bold" color={color.svg} size={16} style={{ marginRight: 2 }} />
            <div style={{
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 'calc(100vw - 48px)',
                whiteSpace: 'nowrap',
                fontWeight: 500,
            }}>{textTransform.ucfirst(data.product)}</div>
        </div>
        <Box fontSize="small">
            Khối lượng: {Number(data.mass).toLocaleString('en')}g <div style={{ color: color.secondary2, display: 'inline-block', border: '1px solid ' + color.svg, borderTop: 'none', borderBottom: 'none', padding: '0 6px', margin: '0 4px' }}>COD: {Number(data.collect).toLocaleString('en')}đ</div> <span style={{ color: color.secondary }}>Phí: {Number(data.fee).toLocaleString('en')}đ</span>
        </Box>
        <Box marginTop={1} />
        <CopyToClipboard text={data.phone}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 2
            }}><User set="bold" color={color.svg} size={16} style={{ marginRight: 2 }} />
                <div style={{
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 'calc(100vw - 48px)',
                    whiteSpace: 'nowrap',
                    fontWeight: 500,
                }}><span style={{ textTransform: 'capitalize' }}>{data.receiver}</span>, {textTransform.mobile(data.phone)}</div>
            </div>
        </CopyToClipboard>
        <Box fontSize="small">{data.district}, {data.city}</Box>
        <Box fontSize="small" marginTop={1}>* {textTransform.ucfirst(data.note) || 'Không có ghi chú'}
            {data.dh_mot_phan === "1" && <>, <Box display="inline-block" color="#f11042" fontWeight={500}>Đơn hàng một phần</Box></>}</Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <div className={tableStyles.status} style={{
                background: data.background,
                color: data.text,
                fontWeight: data.fontWeight
            }}>
                {data.status}
            </div>
            <UserDetect id={data.id_customer} />
        </Box>
        {/* <Box marginTop={1} />
        <div style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 2,
            color: color.secondary2,
            fontSize: 12
        }}><Show set="bold" size={14} /> Xem chi tiết / hành trình</div> */}
    </MobilePaper>
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} />;
});

export default forwardRef((_, ref) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [body, setBody] = useState(null)
    const [url_filter, set_url_filter] = useState('')
    const [title, setTitle] = useState('')
    const history = useHistory()
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const { userFetch } = useContext(UserContext)
    const isXs = useMediaQuery(useTheme().breakpoints.only('xs'))

    useImperativeHandle(ref, () => ({
        open: ({ body, url_filter, title }) => {
            setOpen(true)
            setBody(body)
            set_url_filter(url_filter)
            setTitle(title)
        }
    }))

    const handleClose = () => {
        setOpen(false);
        set_url_filter(null)
        setPage(1)
    };

    const handleOpenFilter = () => {
        setOpen(false)
        setTimeout(() => {
            history.push(url_filter)
        }, 250)
    }

    const getData = async (delay = true) => {
        setLoading(true)
        if (delay)
            await new Promise(res => setTimeout(() => res(true), 250))
        let res = await userFetch('/models/orders/query.php?page=' + page, {
            method: 'POST',
            body
        })
        let resj = await res.json()
        setTotal(resj.total)
        setData(
            resj.orders.map(v => {
                v.background = orderModel.status_handling_color.background
                v.text = orderModel.status_handling_color.text
                if (v.status === "Không Giao Được") {
                    v.background = "rgb(234, 194, 194)"
                    v.text = "rgb(222, 55, 55)"
                } else if (orderModel.status_success.indexOf(v.status) > -1) {
                    v.background = orderModel.status_success_color.background
                    v.text = orderModel.status_success_color.text
                } else if (orderModel.status_failed.indexOf(v.status) > -1) {
                    v.background = orderModel.status_failed_color.background
                    v.text = orderModel.status_failed_color.text
                }
                v.status = textTransform.ucfirst(v.status.toLowerCase())
                return v
            })
        )
        setLoading(false)
    }

    useEffect(() => {
        if (!url_filter)
            return
        getData()
        document.getElementById('quickViewWrapper')?.scrollIntoView({ block: 'start' })
    }, [url_filter, page])

    return (
        <div>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar} elevation={0} color="secondary">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            {loading ? <CircularProgress size={24} color="inherit" /> : <CloseIcon />}
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {title}
                        </Typography>
                        <Box>
                            <Tooltip title="Lọc danh sách">
                                <IconButton onClick={() => document.getElementById('quickViewWrapper').scrollIntoView({ block: 'end', behavior: 'smooth' })} size={isXs ? 'small' : 'medium'}>
                                    <ArrowDown set="bold" color="#fff" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Lọc danh sách">
                                <IconButton size={isXs ? 'small' : 'medium'} onClick={handleOpenFilter}>
                                    <Filter set="bold" color="#fff" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Container style={{ overflowX: 'hidden', width: '100vw' }}>
                    {!loading &&
                        <Fade in>
                            <div id="quickViewWrapper">
                                {isXs ?
                                    <Box marginTop={10}>
                                        {
                                            data.map(order => <MobileOrder key={order.id} data={order} />)
                                        }
                                    </Box>
                                    :
                                    <>
                                        <Box className={`${tableStyles.realTable} ${tableStyles.c5}`} style={{ marginTop: 100, gridTemplateColumns: '2.5fr 2.5fr 2.5fr 2.5fr 0px' }}>
                                            <Box>Mã yêu cầu</Box>
                                            <Box>Sản phẩm</Box>
                                            <Box>Người nhận</Box>
                                            <Box>Ghi chú</Box>
                                            <Box />
                                            {data.map(order =>
                                                <Box className={tableStyles.rowWrapper} key={order.id}>
                                                    <Box>
                                                        <ul className={tableStyles.ul}>
                                                            <li style={{ fontWeight: 500 }}>
                                                                <Copy text={order.code_supership}>
                                                                    {order.code_supership}
                                                                </Copy>
                                                            </li>
                                                            {order.code_orders && <li style={{ color: '#333', maxWidth: '230px' }}><Copy text={order.code_orders}>MĐKH: {order.code_orders}</Copy></li>}
                                                            <li>Duyệt lúc: {order.date_create}</li>
                                                            <div className={tableStyles.status} style={{
                                                                background: order.background,
                                                                color: order.text,
                                                                fontWeight: order.fontWeight
                                                            }}>
                                                                {order.status}
                                                            </div>
                                                        </ul>
                                                    </Box>
                                                    <Box>
                                                        <ul className={tableStyles.ul}>
                                                            <li><Tooltip title={textTransform.ucfirst(order.product)}><span>{textTransform.ucfirst(order.product)}</span></Tooltip></li>
                                                            <li>Khối lượng: {Number(order.mass).toLocaleString('en')}g</li>
                                                            <li style={{ color: color.secondary2 }}>Thu hộ: {Number(order.collect).toLocaleString('en')}đ</li>
                                                            <li style={{ color: color.secondary }}>Phí dịch vụ: {Number(order.fee).toLocaleString('en')}đ</li>
                                                        </ul>
                                                    </Box>
                                                    <Box>
                                                        <ul className={tableStyles.ul}>
                                                            <li>{order.city}</li>
                                                            <li style={{ textTransform: 'capitalize' }}>{order.receiver}</li>
                                                            <li>
                                                                <Copy text={order.phone}>
                                                                    {textTransform.mobile(order.phone)}
                                                                </Copy>
                                                            </li>
                                                            <Tooltip title={textTransform.ucfirst(order.address)}><li>{textTransform.ucfirst(order.address)}</li></Tooltip>
                                                        </ul>
                                                    </Box>
                                                    <Box>
                                                        {order.dh_mot_phan === "1" && <span style={{ color: '#f11042', fontWeight: 500 }}>Đơn hàng một phần</span>}
                                                        <Tooltip title={textTransform.ucfirst(order.note || 'Không có ghi chú.')}>
                                                            <Box className={tableStyles.note}>

                                                                {textTransform.ucfirst(order.note || 'Không có ghi chú.')}
                                                            </Box>
                                                        </Tooltip>
                                                        <div style={{ fontSize: 14 }}>
                                                            <UserDetect fullSize id={order.id_customer} />
                                                        </div>
                                                    </Box>
                                                    <Box />
                                                </Box>)
                                            }
                                        </Box>

                                    </>
                                }
                                <div className="pageWrapper">
                                    <Pagination onChange={(_, p) => setPage(p)} page={page} color="secondary" count={Math.ceil(total / kmess)} shape="rounded" />
                                </div>
                                <Box height={24} />
                            </div>
                        </Fade>}
                </Container>
            </Dialog>
        </div>
    );
})