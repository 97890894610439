import {
    AppBar, Box, Button, Checkbox, Chip, CircularProgress, Collapse, Container,
    Dialog, Drawer, Fade, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment,
    InputLabel, makeStyles, MenuItem, Paper, Select, Slide, Switch, TextField,
    Toolbar, Tooltip, Typography, useMediaQuery, useTheme, withStyles
} from "@material-ui/core"
import { Close as CloseIcon } from '@material-ui/icons'
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab"
import { useSnackbar } from "notistack"
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react"
import { Check, X } from "react-feather"
import { Bag, Buy, Camera, Discount, Edit, Send, User } from "react-iconly"
import color, { phoneStatusStyle } from "../config/color"
import { provinces } from "../config/config"
import { UserContext } from "../contexts/user.context"
import '../css/uploadPhoto.css'
import Copy from './Copy'
import Warehouse from "./Warehouse"

const CollectTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: color.secondary2,
        }
    }
})(TextField)

const FeeTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: color.secondary,
        }
    }
})(TextField)


const StyledAlert = withStyles({
    message: {
        width: '100%'
    }
})(Alert)

const useStyles = makeStyles((theme) => ({
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}))

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const CustomerPaper = (props) => <Paper {...props} style={{ background: '#f2f2f2', margin: '8px 0' }} />

const MenuProps = {
    PaperProps: {
        style: {
            background: '#f2f2f2'
        },
    },
    getContentAnchorEl: null
}

const DrawerAddConfig = forwardRef((props, ref) => {
    const [cau_hinh_thu_ho, setCHTH] = useState(0)
    const [cau_hinh_giao_hang, setCHGH] = useState(1)
    const [open, setOpen] = useState(false)
    const [ten_san_pham, set_ten_san_pham] = useState('')
    const [ten_template, set_ten_template] = useState('')
    const [ghi_chu, set_ghi_chu] = useState('')
    const [khoi_luong, set_khoi_luong] = useState('')
    const [length, setLength] = useState('')
    const [width, setWidth] = useState('')
    const [height, setHeight] = useState('')
    const [tien_hang, set_tien_hang] = useState('')
    const [tri_gia, set_tri_gia] = useState('')
    const [is_default, set_is_default] = useState(false)
    const { userFetch } = useContext(UserContext)
    const { enqueueSnackbar } = useSnackbar()
    const isXl = useMediaQuery(useTheme().breakpoints.only('xl'))

    const handleChangeCHGH = (e) => setCHGH(e.target.value)
    const handleChangeCHTH = (e) => setCHTH(e.target.value)

    const numberFormat = (e) => {
        if (e.target.value.trim()) {
            let newValue = Number(e.target.value.replace(/,/g, ''))
            if (!isNaN(newValue))
                e.target.value = newValue.toLocaleString('en')
            else
                e.target.value = ''
        }
    }

    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true)
        }
    }))

    const addTemplate = async (e) => {
        e.preventDefault()
        let formData = new FormData()
        let _khoi_luong = khoi_luong ? khoi_luong.replace(/,/g, '') : 0
        let _tien_hang = tien_hang ? tien_hang.replace(/,/g, '') : 0
        let _tri_gia = tri_gia ? tri_gia.replace(/,/g, '') : 0
        formData.append('cau_hinh_thu_ho', cau_hinh_thu_ho)
        formData.append('cau_hinh_giao_hang', cau_hinh_giao_hang)
        formData.append('ten_san_pham', ten_san_pham)
        formData.append('ten_template', ten_template)
        formData.append('ghi_chu', ghi_chu)
        formData.append('khoi_luong', _khoi_luong)
        formData.append('tien_hang', _tien_hang)
        formData.append('width', width.replace(/,/g, ''))
        formData.append('length', length.replace(/,/g, ''))
        formData.append('height', height.replace(/,/g, ''))
        formData.append('tri_gia', _tri_gia)
        formData.append('is_default', is_default)
        let res = await userFetch(`/models/pending/template_methods.php`, {
            method: 'POST',
            body: formData
        })
        let resj = await res.json()
        if (resj.response_code === 200) {
            enqueueSnackbar('Thêm mẫu mới thành công', { variant: 'success' })
            handleClose()
        } else {
            enqueueSnackbar(resj.msg, { variant: 'error' })
        }
    }

    const handleClose = () => {
        setTimeout(() => {
            props.changeKey()
        }, 225);
        setOpen(false)
    }

    return (
        <Drawer anchor="right" open={open} onClose={handleClose}>
            <form onSubmit={addTemplate}>
                <Box lineHeight={isXl ? '80px' : '60px'} padding={2} width={380} maxWidth="100%" display="flex" flexDirection="column" minHeight="100vh">
                    <Box flex={1}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">Quản lý mẫu đơn hàng</Typography>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box height={20} />
                        <TextField size={isXl ? 'medium' : 'small'} value={ten_template} onChange={e => set_ten_template(e.target.value)} variant="outlined" required fullWidth label="Tên mẫu" />
                        <TextField size={isXl ? 'medium' : 'small'} value={ten_san_pham} onChange={e => set_ten_san_pham(e.target.value)} variant="outlined" fullWidth label="Tên sản phẩm" />

                        <TextField size={isXl ? 'medium' : 'small'} value={tien_hang}
                            onChange={e => {
                                numberFormat(e)
                                set_tien_hang(e.target.value)
                            }}
                            variant="outlined" fullWidth label="Tiền hàng (đ)" />

                        <TextField size={isXl ? 'medium' : 'small'} value={khoi_luong} onChange={e => {
                            numberFormat(e)
                            set_khoi_luong(e.target.value)
                        }} variant="outlined" fullWidth label="Khối lượng (g)" />
                        <Box height={8} />
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <TextField size={isXl ? 'medium' : 'small'} value={length} onChange={e => {
                                    numberFormat(e)
                                    setLength(e.target.value)
                                }} variant="outlined" fullWidth label="Dài" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField size={isXl ? 'medium' : 'small'} value={width} onChange={e => {
                                    numberFormat(e)
                                    setWidth(e.target.value)
                                }} variant="outlined" fullWidth label="Rộng" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField size={isXl ? 'medium' : 'small'} value={height} onChange={e => {
                                    numberFormat(e)
                                    setHeight(e.target.value)
                                }} variant="outlined" fullWidth label="Cao" />
                            </Grid>
                        </Grid>
                        <TextField size={isXl ? 'medium' : 'small'} value={tri_gia} onChange={e => {
                            numberFormat(e)
                            set_tri_gia(e.target.value)
                        }} variant="outlined" fullWidth label="Trị giá (đ)" />
                        <FormControl fullWidth variant="outlined" size={isXl ? 'medium' : 'small'}>
                            <InputLabel>Cấu hình thu hộ</InputLabel>
                            <Select MenuProps={MenuProps} onChange={handleChangeCHTH} value={cau_hinh_thu_ho} label="Cấu hình thu hộ" variant="outlined">
                                <MenuItem value={0}>Thu hộ = Tiền Hàng + Phí</MenuItem>
                                <MenuItem value={1}>Thu hộ = Tiền Hàng</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth variant="outlined" size={isXl ? 'medium' : 'small'}>
                            <InputLabel>Cấu hình giao hàng</InputLabel>
                            <Select MenuProps={MenuProps} onChange={handleChangeCHGH} value={cau_hinh_giao_hang} label="Cấu hình giao hàng" variant="outlined">
                                <MenuItem value={1}>Cho Xem Hàng Nhưng Không Cho Thử Hàng</MenuItem>
                                <MenuItem value={2}>Cho Thử Hàng</MenuItem>
                                <MenuItem value={3}>Không Cho Xem Hàng</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField size={isXl ? 'medium' : 'small'} value={ghi_chu} onChange={e => set_ghi_chu(e.target.value)} multiline variant="outlined" fullWidth label="Ghi chú" inputProps={{
                            style: {
                                minHeight: isXl ? 109 : 79
                            }
                        }} />
                        <FormControlLabel control={<Checkbox color="primary" checked={is_default} onClick={() => set_is_default(!is_default)} />} label="Đặt làm mẫu mặc định" />
                    </Box>
                    <Box height={8} />
                    <Button type="submit" variant="contained" size="large" color="secondary">Thêm Mẫu</Button>
                    <Box height={8} />
                </Box>
            </form>
        </Drawer>
    )
})

const Chipp = ({ color, onClick, label, onDelete }) => {
    const [confirm_delete, set_confirm_delete] = useState(0)
    const deleteTimeout = useRef(null)

    const handleDelete = () => {
        if (confirm_delete == 1)
            onDelete()
        else {
            set_confirm_delete(1)
            deleteTimeout.current = setTimeout(() => {
                set_confirm_delete(0)
            }, 3000)
        }
    }

    useEffect(() => {
        return () => {
            clearTimeout(deleteTimeout.current)
        }
    }, [])

    return (
        <Fade in>
            <Chip deleteIcon={[
                <Tooltip title="Xóa">
                    <X />
                </Tooltip>,
                <Tooltip title="Xác nhận">
                    <Check />
                </Tooltip>
            ][confirm_delete]} color={color} onDelete={handleDelete} onClick={onClick} label={label} style={{ marginRight: 8 }} />
        </Fade>
    )
}

const CreateDialog = forwardRef((props, ref) => {
    const classes = useStyles()
    const [editData, setEditData] = useState(null)
    const [key, setKey] = useState(0)
    const [templates, setTemplates] = useState([])
    const [template, setTemplate] = useState(-1)
    const [repoSelected, setRepoSelected] = useState(0)
    const [barter, setBarter] = useState(false)
    const [prevPhone, setPrevPhone] = useState('')
    const [phone, setPhone] = useState('')
    const [phoneStatus, setPhoneStatus] = useState(null)
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [open, setOpen] = useState(false)
    const [cau_hinh_thu_ho, setCHTH] = useState(0)
    const [cau_hinh_giao_hang, setCHGH] = useState(1)
    const [region_info, set_region_info] = useState(null)
    const [city, setCity] = useState({})
    const [district, setDistrict] = useState({})
    const [commune, setCommune] = useState({})
    const [districts, setDistricts] = useState([])
    const [communes, setCommunes] = useState([])
    const [collect, setCollect] = useState(0)
    const [ten_san_pham, set_ten_san_pham] = useState('')
    const [khoi_luong, set_khoi_luong] = useState('')
    const [length, setLength] = useState('')
    const [width, setWidth] = useState('')
    const [height, setHeight] = useState('')
    const [tien_hang, set_tien_hang] = useState('')
    const [tri_gia, set_tri_gia] = useState('')
    const [ma_don_shop, set_ma_don_shop] = useState('')
    const [ghi_chu, set_ghi_chu] = useState('')
    const [success, setSuccess] = useState(null)
    const [created, setCreated] = useState(false)
    const [creating, setCreating] = useState(false)
    const [errorReason, setErrorReason] = useState('')
    const repoSelectedRef = useRef(null)
    const [nameRecommend, setNameRecommend] = useState([])
    const nameController = useRef(null)
    const [isCalcing, setIsCalcing] = useState(false)
    const isXs = useMediaQuery(useTheme().breakpoints.only('xs'))
    const [fees, setFees] = useState({
        pvc: 0,
        pvkl: 0,
        tbh: 0
    })
    const [analyzing, setAnalyzing] = useState(false)
    const [finding_phone, set_finding_phone] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const
        [cityRef, districtRef, communeRef, massRef, valueRef, drawerAddConfigRef] =
            [useRef(null), useRef(null), useRef(null), useRef({ value: 0 }), useRef({ value: 0 }), useRef(null)]
    const { user, userFetch, pVRef } = useContext(UserContext)
    const [files, setFiles] = useState([])
    const [deleteFiles, setDeleteFiles] = useState([]) // use for edit files

    const removeFiles = (e, i) => {
        e.stopPropagation()
        if (creating) return;
        let cloneFiles = [...files]
        if (typeof cloneFiles[i] == 'string')
            setDeleteFiles([...deleteFiles, cloneFiles[i]])
        cloneFiles.splice(i, 1)
        setFiles(cloneFiles)
    }

    const handleChooseFile = (e) => {
        if (creating) return;
        let newFiles = [];
        ([...e.target.files]).forEach(el => {
            if (el.size < 1024 * 1024 * 5)
                newFiles.push(el)
            else
                enqueueSnackbar('File không được vượt quá 5mb', { variant: 'warning' })
        })
        newFiles = [...files, ...newFiles]
        if (newFiles.length > 9) {
            enqueueSnackbar('Tối đa 9 ảnh', { variant: 'warning' })
            newFiles = newFiles.slice(0, 9)
        }
        setFiles(newFiles)
    }

    const handleCreate = async (e) => {
        e.preventDefault()
        setErrorReason('')
        setSuccess(null)
        setCreating(true)
        await new Promise(res => setTimeout(() => res(true), 700))
        let formData = new FormData()
        formData.append('warehouse_id', repoSelectedRef.current.id)
        formData.append('product', ten_san_pham)
        formData.append('name', name)
        formData.append('phone', phone)
        formData.append('barter', Number(barter))
        formData.append('the_fee_bearer', cau_hinh_thu_ho)
        formData.append('address', address)
        formData.append('province', city.name)
        formData.append('district', district.name)
        formData.append('commune', commune.name)
        formData.append('amount', tien_hang.replace(/,|\./g, ''))
        formData.append('weight', khoi_luong.replace(/,|\./g, ''))
        formData.append('length', length.replace(/,|\./g, ''))
        formData.append('width', width.replace(/,|\./g, ''))
        formData.append('height', height.replace(/,|\./g, ''))
        formData.append('bom_rate', phoneStatus === null ? '' : phoneStatus.result + ',' + phoneStatus.rate)
        formData.append('soc', ma_don_shop)
        formData.append('note', ghi_chu.trim())
        formData.append('supership_value', fees.pvc)
        formData.append('config', cau_hinh_giao_hang)
        formData.append('cod', collect.replace(/,|\./g, ''))
        formData.append('value', tri_gia.replace(/,/g, ''))
        if (files.length > 0) {
            files.filter(el => typeof el == 'object').forEach(el => {
                formData.append('photo[]', el)
            })
        }
        if (deleteFiles.length > 0)
            formData.append('deleteFiles', deleteFiles.join(','))

        if (editData) {
            if (editData.status_cancel === '0') {
                if (!editData.error) {
                    formData.append('id', editData.id)
                } else
                    formData.append('required_code', editData.required_code)
            }
        }
        let res = await userFetch(`/models/pending/methods.php`, {
            method: 'POST',
            body: formData
        })
        let resj = await res.json()
        if (resj.response_code === 200) {
            if (resj.reason) { // Đẩy tự động sang đối tác thì dính lỗi
                setErrorReason(resj.reason)
            } else {
                // reset
                setPrevPhone('')
                setName('')
                setPhone('')
                setAddress('')
                set_ma_don_shop('')
                set_tien_hang('')
                set_tri_gia('')
                setBarter(false)
                setCity({})
                setDistrict({})
                setCommune({})
                setDistricts([])
                setCommunes([])
                set_region_info(null)
                setFiles([])
                if (repoSelected) {
                    setTemplate(-1)
                    setTemplate(0)
                } else {
                    set_ten_san_pham('')
                    set_khoi_luong('')
                    setLength('')
                    setWidth('')
                    setHeight('')
                    setCHGH(1)
                    setCHTH(0)
                    set_ghi_chu('')
                }
                //
                if (!editData) {
                    setSuccess(resj)
                    setCreated(true)
                    document.getElementById('create_order').scrollIntoView({ block: 'start', behavior: 'smooth' })
                } else {
                    handleClose()
                    if (editData.status_cancel === '0') {
                        enqueueSnackbar('Đã sửa ' + editData.required_code, { variant: 'success' })
                        editData.update(editData.index, resj.data)
                    } else {
                        enqueueSnackbar('Kéo lại thành công!', { variant: 'success' })
                    }
                }
            }
        } else
            enqueueSnackbar(resj.msg, { variant: 'error' })
        setCreating(false)
    }

    const handleChangeCHGH = (e) => setCHGH(e.target.value)
    const handleChangeCHTH = (e) => setCHTH(e.target.value)
    const analyze = async (_address) => {
        setAnalyzing(true)
        let res = await userFetch(`/models/app/query/address_analyze.php?str=${typeof _address === "string" ? _address : address}`)
        let data = await res.json()
        setAnalyzing(false)
        if (data == null || (!data.province || !data.district || !data.commune)) {
            enqueueSnackbar('Không tìm thấy địa chỉ, chọn thủ công!', { variant: 'warning' })
            return
        }
        let tinh = data.province;
        let huyen = data.district;
        let xa = data.commune;
        try {
            autoCompleteAddress(tinh, huyen, xa)
        } catch (e) {
            console.log(e)
            enqueueSnackbar('Lỗi phân tích địa chỉ!', { variant: 'error' })
        }
    }

    const getTempalte = async () => {
        let res = await userFetch('/models/pending/template_methods.php')
        let resj = await res.json()
        setTemplates(resj.data.reverse())
        chooseTemplate(resj.data.length - 1)
        setTimeout(() => {
            document.querySelector('#templateScroll')?.scroll({ behavior: 'smooth', left: document.querySelector('#templateScroll').scrollWidth + 100 })
        }, 100)
    }

    const autoCompleteAddress = async (tinh, huyen, xa) => {
        // find out index of provinces arr
        let index
        for (var i = 0; i < provinces.length; i++) {
            if (provinces[i].name.match(new RegExp(tinh, 'gi'))) {
                index = i
                break
            }
        }
        setCity(provinces[index])
        //

        setDistrict({})
        setCommune({})

        setDistricts([])
        setCommunes([])

        // find out index of district arr
        let res = await userFetch(
            `/models/app/query/address.php?act=get_district_by_code&code=${provinces[index].code}`
        )
        let resj = await res.json()
        setDistricts(resj.data)

        index = -1;

        for (var i = 0; i < resj.data.length; i++) {
            if (resj.data[i].name.match(new RegExp(huyen, 'gi'))) {
                console.log(huyen);
                console.log(resj.data[i].name)
                index = i
                break
            }
        }
        if (index > -1) {
            setDistrict(resj.data[index])
            // 

            // find out index of commune arr
            res = await userFetch(
                `/models/app/query/address.php?act=get_commune_by_code&code=${resj.data[index].code}`
            )
            resj = await res.json()
            setCommunes(resj.data)
            index = -1;
            for (var i = 0; i < resj.data.length; i++) {
                if (resj.data[i].name.match(new RegExp(xa, 'gi'))) {
                    index = i
                    break
                }
            }
            if (index > -1) {
                setCommune(resj.data[index])
            }
        }
    }

    const handleChangeCity = async (_, v) => {
        try {
            setCity(v)
            districtRef.current.value = ''
            communeRef.current.value = ''
            districtRef.current.blur()
            setDistrict({})
            setCommune({})
            set_region_info(null)
            setDistricts([])
            setCommunes([])
            let res = await userFetch(
                `/models/app/query/address.php?act=get_district_by_code&code=${v.code}`
            )
            let resj = await res.json()
            setDistricts(resj.data)
            districtRef.current.focus()
        } catch { }
    }
    const handleChangeDistrict = async (_, v) => {
        setDistrict(v)
        communeRef.current.value = ''
        setCommune({})
        setCommunes([])
        if (v) {
            let res = await userFetch(
                `/models/app/query/address.php?act=get_commune_by_code&code=${v.code}`
            )
            let resj = await res.json()
            setCommunes(resj.data)
            communeRef.current.focus()
        } else {
            setCommunes([])
        }
    }
    const handleChangeCommune = (_, v) => setCommune(v)

    const numberFormat = (e) => {
        if (e.target.value.trim()) {
            let newValue = Number(e.target.value.replace(/,/g, ''))
            if (!isNaN(newValue))
                e.target.value = newValue.toLocaleString('en')
            else
                e.target.value = ''
        }
    }

    const handleChangeNameRecommend = (_, v) => {
        console.log(v)
        if (!v)
            return
        setName(v.receiver)
        setPrevPhone(v.phone)
        setPhone(v.phone)
        setAddress(v.address)
        autoCompleteAddress(v.city, v.district, v.ward)
    }

    const handleChangeName = (e) => {
        setName(e.target.value)
        if (phone)
            return
        if (nameController.current)
            clearTimeout(nameController.current)
        nameController.current = setTimeout(async () => {
            let res = await userFetch(`/models/app/query/customer_hint_by_name.php?name=${e.target.value}`)
            let resj = await res.json()
            setNameRecommend(resj.data)
        }, 200)
    }

    const getPhoneStatus = async (e) => {
        setPhoneStatus(null)
        const res = await userFetch(`/models/pending/actions.php?act=checkBlockPhone&phone=${e.target.value}`)
        const resj = await res.json()
        setPhoneStatus(resj)
    }

    const get_user_by_phone = async (e) => {
        let phones = e.target.value.split(',')
        let arr_phone = []
        phones.forEach(value => {
            value = value.trim()
            value = value.replace(/\D/g, '')
            value = value.replace(/^[+]?84/, '0')
            if (value.length < 10)
                return
            arr_phone.push(value)
        })
        let _phone = arr_phone.join(', ')
        setPhone(_phone)
        if (prevPhone === _phone || !_phone)
            return
        setPrevPhone(_phone)
        set_finding_phone(true)
        getPhoneStatus(e)
        let res = await userFetch(`/models/app/query/customer_hint_by_phone.php?phone=${_phone}`)
        let resj = await res.json()
        if (resj.data) {
            setName(resj.data.receiver)
            setAddress(resj.data.address)
            autoCompleteAddress(resj.data.city, resj.data.district, resj.data.ward)
        }
        set_finding_phone(false)
    }

    useEffect(() => calcFees(), [region_info, cau_hinh_thu_ho, khoi_luong, tien_hang, tri_gia, repoSelected, city, district, length, width, height])
    useEffect(() => getTempalte(), [key])

    const calcFees = async () => {
        let _length = Number(length.replace(/,|\./g, ''))
        let _width = Number(width.replace(/,|\./g, ''))
        let _height = Number(height.replace(/,|\./g, ''))
        let _khoi_luong = Number(khoi_luong.replace(/,|\./g, ''))
        let _tien_hang = Number(tien_hang.replace(/,|\./g, ''))
        let _tri_gia = Number(tri_gia.replace(/,|\./g, ''))
        _tri_gia = isNaN(_tri_gia) ? 0 : _tri_gia
        _tien_hang = isNaN(_tien_hang) ? 0 : _tien_hang
        _khoi_luong = isNaN(_khoi_luong) ? 0 : _khoi_luong
        _length = isNaN(_length) ? 0 : _length
        _width = isNaN(_width) ? 0 : _width
        _height = isNaN(_height) ? 0 : _height
        if (repoSelected == 0 || !city?.name || !district?.name || _khoi_luong === 0) return;
        setIsCalcing(true)
        let formData = new FormData()
        formData.append('amount', _tien_hang)
        formData.append('weight', _khoi_luong)
        formData.append('value', _tri_gia)
        formData.append('length', _length)
        formData.append('width', _width)
        formData.append('height', _height)
        city && formData.append('province', city.name)
        district && formData.append('district', district.name)
        commune && formData.append('commune', commune.name)
        formData.append('warehouse_id', repoSelected)
        formData.append('the_fee_bearer', cau_hinh_thu_ho)
        const res = await userFetch('/models/pending/actions.php?act=calc_fee', {
            method: 'POST',
            body: formData
        })
        const _fees = await res.json()
        setFees(_fees);
        setCollect(Number(_fees.collect).toLocaleString('en'))
        setIsCalcing(false)
    }

    useImperativeHandle(ref, () => ({
        edit: async (data) => {
            setOpen(true)
            setRepoSelected(data.warehouse_id)
            setTimeout(() => {
                document.querySelector('#templateScroll')?.scroll({ behavior: 'smooth', left: document.querySelector('#templateScroll').scrollWidth + 100 })
            }, 100)
            setEditData(data)
            if (data.reason)
                setErrorReason(data.reason)
            // parse from data
            setFiles(data.photos_url ? data.photos_url.split(',') : [])
            setBarter(Boolean(Number(data.barter)))
            setPhone(data.phone)
            if (data.bom_rate){
                setPhoneStatus({
                    result: data.bom_rate.split(',')[0],
                    rate: data.bom_rate.split(',')[1],
                })
            }
            setName(data.name)
            setAddress(data.address)
            setCHTH(Number(data.the_fee_bearer))
            setCHGH(Number(data.config))
            set_ten_san_pham(data.product)
            setLength(data.length != 0 ? Number(data.length).toLocaleString('en') : '')
            setWidth(data.width != 0 ? Number(data.width).toLocaleString('en') : '')
            setHeight(data.height != 0 ? Number(data.height).toLocaleString('en') : '')
            set_khoi_luong(Number(data.weight).toLocaleString('en'))
            set_tri_gia(data.value !== "0" ? Number(data.value).toLocaleString('en') : '')
            set_ma_don_shop(data.soc)
            set_tien_hang(Number(data.cod).toLocaleString('en'))
            set_ghi_chu(data.note.replace(/(Cho Xem Hàng Nhưng Không Cho Thử Hàng|Cho Thử Hàng|Không Cho Xem Hàng|Có Hàng Đổi Trả\. Ship Lấy Hàng Về Giúp Shop)/g, ''))
            await analyze(data.commune + ',' + data.district + ',' + data.province)
            // end parse
            // calcFees()
        },
        open: () => {
            setOpen(true)
            setTimeout(() => {
                document.querySelector('#templateScroll')?.scroll({ behavior: 'smooth', left: document.querySelector('#templateScroll').scrollWidth + 100 })
            }, 100)
        }
    }))

    const deleteTemplate = (i) => {
        let _cloneTemplate = [...templates]
        _cloneTemplate.splice(i, 1)
        setTemplates(_cloneTemplate)
        if (_cloneTemplate.length) {
            if (i > template)
                chooseTemplate(template + 1)
            else
                chooseTemplate(template - 1)
        }
        userFetch('/models/pending/template_methods.php?id=' + templates[i].id, {
            method: 'DELETE'
        })
    }

    const chooseTemplate = (i) => {
        if (template === i) {
            fillTemplate()
        } else
            setTemplate(i)
    }

    const fillTemplate = () => {
        let i = template
        if (templates[i]) {
            set_ghi_chu(templates[i].ghi_chu)
            set_ten_san_pham(templates[i].ten_san_pham)
            set_tien_hang(templates[i].tien_hang !== "0" ? Number(templates[i].tien_hang).toLocaleString('en') : '')
            set_tri_gia(templates[i].tri_gia !== "0" ? Number(templates[i].tri_gia).toLocaleString('en') : '')
            set_khoi_luong(templates[i].khoi_luong !== "0" ? Number(templates[i].khoi_luong).toLocaleString('en') : '')
            setCHTH(Number(templates[i].cau_hinh_thu_ho))
            setCHGH(Number(templates[i].cau_hinh_giao_hang))
            setWidth(templates[i]?.width != 0 ? Number(templates[i].width).toLocaleString('en') : '')
            setHeight(templates[i]?.height != 0 ? Number(templates[i].height).toLocaleString('en') : '')
            setLength(templates[i]?.length != 0 ? Number(templates[i].length).toLocaleString('en') : '')
            calcFees()
        }
    }

    useEffect(() => {
        fillTemplate()
    }, [template])

    const handleClose = () => {
        if (creating) return;
        pVRef.current.close()
        setOpen(false)
        setTimeout(() => {
            props.onClose(created)
        }, 225)
    }

    const handleChangePhone = e => setPhone(e.target.value)

    return (
        <Dialog TransitionComponent={Transition} fullScreen open={open} onClose={handleClose}>
            <DrawerAddConfig key={key} changeKey={() => setKey(Date.now())} ref={drawerAddConfigRef} />
            <form onSubmit={handleCreate} id="create_order">
                <AppBar className={classes.appBar} color="secondary" elevation={0} position="fixed">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {editData ? editData.required_code : 'Tạo đơn hàng'}
                        </Typography>
                        <Button disabled={(!user.policy_id && !user.policy_v2) || creating} autoFocus color="inherit" type="submit">
                            {creating ? <CircularProgress style={{ color: '#fff' }} size={24} /> : (!editData ? 'Xác nhận' : (editData.status_cancel === '1' ? 'Kéo lại' : 'Cập nhật'))}
                        </Button>
                    </Toolbar>
                </AppBar>
                <Container>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Box height={92} />
                            <Collapse in={Boolean(success)}>
                                <StyledAlert style={{ margin: '8px 0 24px 0', width: '100%' }} severity="success">
                                    <AlertTitle>Tạo đơn hàng thành công</AlertTitle>
                                    <Box display="flex" justifyContent="space-between" alignItems={isXs ? 'flex-end' : 'center'} flexDirection={isXs ? 'column' : 'row'}>
                                        {(success != null && success.code_supership) ?
                                            <Box>Mã vận đơn của đơn hàng là: <Copy text={success.code_supership}><b style={{ color: 'red', fontWeight: 500 }}>{success.code_supership}</b></Copy>, vui lòng ghi mã vận đơn lên đơn hàng.</Box> :
                                            <Box>Mã yêu cầu của đơn hàng là: <Copy text={success?.required_code}><b style={{ color: 'red', fontWeight: 500 }}>{success?.required_code}</b></Copy>, vui lòng ghi mã yêu cầu lên đơn hàng.</Box>
                                        }
                                        <Box>
                                            <Button onClick={() => window.open(window.location.origin + '/khachhang/api/create_order/print?list_id=' + success.id, '_blank')}>In A5</Button>
                                            <Button onClick={() => window.open(window.location.origin + '/khachhang/api/create_order/print?type=s9&list_id=' + success.id, '_blank')}>In S9</Button>
                                            <IconButton size="small" onClick={() => setSuccess(null)}>
                                                <CloseIcon color="#333" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </StyledAlert>
                            </Collapse>
                            <Grid container spacing={4} justify="space-between">
                                <Grid item xs={12} md={6}>
                                    <Warehouse value={repoSelected} onChange={(id) => setRepoSelected(id)} onClose={handleClose} ref={repoSelectedRef} />
                                </Grid>
                                <Grid item xs={12} md={6} className="templateScroll" id="templateScroll">
                                    {templates.map((v, i) => <Chipp key={v.id} deleteIcon={<X />} color={i === template ? 'primary' : 'default'} onDelete={() => deleteTemplate(i)} onClick={() => chooseTemplate(i)} label={v.ten_template} />)}
                                    <Chip onClick={() => drawerAddConfigRef.current.open()} label="Thêm mẫu" variant="outlined" />
                                </Grid>
                            </Grid>
                            {(editData && editData.status_cancel === '1') && <Alert style={{ marginTop: 16 }} severity="info">
                                Kéo lại đơn sẽ <b>TẠO ĐƠN MỚI</b>, đơn hủy từ trước vẫn sẽ tồn tại.
                            </Alert>}
                            <Collapse in={Boolean(errorReason)}>
                                {errorReason && <Alert style={{ marginTop: '16px', whiteSpace: 'pre-line' }} severity="warning">
                                    {errorReason}
                                </Alert>}
                            </Collapse>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <div style={{ lineHeight: '90px' }}>
                                <Typography variant="h6"><Buy set="broken" style={{ verticalAlign: -3, marginRight: 4 }} /> Người nhận</Typography>
                                <Box height={isXs ? 12 : 16} />
                                <TextField helperText="Nếu nhiều SĐT hãy thêm vào ghi chú." variant="outlined" value={phone} onChange={handleChangePhone} onBlur={get_user_by_phone} fullWidth required label="SĐT người nhận"
                                    InputProps={{
                                        endAdornment: !finding_phone ?
                                            (phoneStatus === null ? <></> :
                                                <Tooltip title={`Tỉ lệ bom hàng`}>
                                                    <Chip label={phoneStatus.result} style={phoneStatusStyle[phoneStatus.result]} />
                                                </Tooltip>)
                                            : (
                                                <>
                                                    <InputAdornment position="end">
                                                        <CircularProgress size={24} />
                                                    </InputAdornment>
                                                </>
                                            )
                                    }}
                                />
                                <Autocomplete
                                    freeSolo
                                    autoSelect
                                    PaperComponent={CustomerPaper}
                                    onChange={handleChangeNameRecommend}
                                    options={phone ? [] : nameRecommend}
                                    disableClearable
                                    getOptionLabel={(option) => {
                                        if (typeof option === 'object')
                                            return option.receiver + ' - *' + option.phone?.slice(option.phone.length - 3, option.phone.length)
                                        else
                                            return ''
                                    }}
                                    inputValue={name}
                                    value={name}
                                    renderInput={(params) => <TextField onChange={handleChangeName} inputProps={{ style: { textTransform: 'capitalize' } }} required {...params} label="Họ và tên" variant="outlined" />}
                                />
                                <TextField value={address} onChange={e => setAddress(e.target.value)} variant="outlined" fullWidth required label="Địa chỉ" InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Phân tích">
                                                <IconButton size="small" onClick={analyze}>
                                                    {analyzing ? <CircularProgress size={24} /> : <Discount set="bold" color={color.svg} />}
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }} />
                                <Autocomplete
                                    id="choose-city"
                                    options={provinces}
                                    getOptionLabel={(option) => option.name}
                                    noOptionsText="Không tìm thấy tỉnh"
                                    blurOnSelect
                                    openOnFocus
                                    onChange={handleChangeCity}
                                    PaperComponent={CustomerPaper}
                                    value={city}
                                    renderInput={(params) => <TextField inputRef={cityRef} required {...params} label="Tỉnh / Thành Phố" variant="outlined" />}
                                />
                                <Autocomplete
                                    id="choose-district"
                                    noOptionsText="Không tìm thấy huyện"
                                    options={districts}
                                    getOptionLabel={(option) => option.name ?? ''}
                                    blurOnSelect
                                    openOnFocus
                                    disabled={districts.length === 0}
                                    value={district}
                                    PaperComponent={CustomerPaper}
                                    onChange={handleChangeDistrict}
                                    renderInput={(params) => <TextField inputRef={districtRef} required {...params} label="Huyện / Quận" variant="outlined" />}
                                />
                                <Autocomplete
                                    id="choose-commune"
                                    options={communes}
                                    noOptionsText="Không tìm thấy xã"
                                    value={commune}
                                    getOptionLabel={(option) => option.name ?? ''}
                                    blurOnSelect
                                    openOnFocus
                                    disabled={communes.length === 0}
                                    PaperComponent={CustomerPaper}
                                    onChange={handleChangeCommune}
                                    renderInput={(params) => <TextField FormHelperTextProps={{ style: { color: '#f11042', fontSize: 14, fontWeight: 500, marginTop: 10 } }} required {...params} inputRef={communeRef} label="Xã / Phường" variant="outlined" />}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div style={{ lineHeight: '90px' }}>
                                <Typography variant="h6"><Bag set="broken" style={{ verticalAlign: -3, marginRight: 4 }} /> Sản phẩm</Typography>
                                <Box height={isXs ? 12 : 16} />
                                <TextField value={ten_san_pham} onChange={e => set_ten_san_pham(e.target.value)} variant="outlined" fullWidth required label="Tên sản phẩm" />
                                <TextField inputRef={valueRef} value={tien_hang} onChange={e => {
                                    numberFormat(e)
                                    set_tien_hang(e.target.value)
                                }} variant="outlined" fullWidth required label="Tiền hàng (đ)" />
                                <TextField value={khoi_luong} inputRef={massRef} onChange={e => {
                                    numberFormat(e)
                                    set_khoi_luong(e.target.value)
                                }} variant="outlined" fullWidth required label="Khối lượng (g)" helperText="Kích thước không bắt buộc (cm) 👇" />
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <TextField value={length} onChange={e => {
                                            numberFormat(e)
                                            setLength(e.target.value)
                                        }} variant="outlined" fullWidth label="Dài" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField value={width} onChange={e => {
                                            numberFormat(e)
                                            setWidth(e.target.value)
                                        }} variant="outlined" fullWidth label="Rộng" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField value={height} onChange={e => {
                                            numberFormat(e)
                                            setHeight(e.target.value)
                                        }} variant="outlined" fullWidth label="Cao" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tooltip title="Khối lượng quy đổi">
                                            <TextField disabled value={Math.ceil(height * width * length / 5) + 'g'} variant="outlined" fullWidth label="KLQĐ" />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <TextField value={tri_gia} onChange={e => {
                                    numberFormat(e)
                                    set_tri_gia(e.target.value)
                                }} variant="outlined" fullWidth label="Trị giá (đ)" helperText="Có thể bỏ qua." />
                                <TextField inputProps={{ style: { textTransform: 'uppercase' } }} value={ma_don_shop} onChange={e => set_ma_don_shop(e.target.value)} variant="outlined" fullWidth label="Mã đơn của shop" helperText="Có thể bỏ qua." />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div style={{ lineHeight: '90px' }}>
                                <Typography variant="h6"><Edit set="broken" style={{ verticalAlign: -3, marginRight: 4 }} /> Thông tin thêm</Typography>
                                <Box height={isXs ? 12 : 16} />
                                <TextField value={ghi_chu} onChange={e => set_ghi_chu(e.target.value)} multiline variant="outlined" fullWidth label="Ghi chú giao hàng" inputProps={{
                                    style: {
                                        minHeight: 109
                                    }
                                }} />
                                <Box height={33} />
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Cấu hình giao hàng</InputLabel>
                                    <Select MenuProps={MenuProps} onChange={handleChangeCHGH} value={cau_hinh_giao_hang} label="Cấu hình giao hàng" variant="outlined">
                                        <MenuItem value={1}>Cho Xem Hàng Nhưng Không Cho Thử Hàng</MenuItem>
                                        <MenuItem value={2}>Cho Thử Hàng</MenuItem>
                                        <MenuItem value={3}>Không Cho Xem Hàng</MenuItem>
                                    </Select>
                                </FormControl>
                                {/* <Box style={{ lineHeight: 0, marginTop: -11 }}>
                                    <FormControlLabel control={<Switch color="primary" checked={barter} onClick={() => setBarter(!barter)} />} label="Đổi / Lấy hàng về" />
                                </Box> */}
                                <div className="uploadContainer" style={{ marginTop: 0 }}>
                                    {files.map((e, i) => <><div onClick={() => {
                                        pVRef.current.open(i, files.map(e => typeof e == 'object' ? URL.createObjectURL(e) : e))
                                    }} key={i} style={{ backgroundImage: `url(${typeof e == 'object' ? URL.createObjectURL(e) : e})` }}>
                                        <span onClick={(e) => removeFiles(e, i)}>
                                            <X />
                                        </span>
                                    </div></>)}
                                    {files.length < 9 && <label for="files">
                                        <div style={{ lineHeight: '18px', textAlign: 'center', fontSize: 14, color: color.svg, cursor: 'pointer' }}><Camera set="bold" color={color.svg} /><br />Thêm ảnh</div>
                                    </label>}
                                </div>
                                <input id="files" style={{ display: 'none' }} type="file" multiple accept="image/png,image/jpg" onChange={handleChooseFile} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div style={{ lineHeight: '90px' }}>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography variant="h6"><Send set="broken" style={{ verticalAlign: -3, marginRight: 4 }} /> Thu hộ & Phí</Typography>
                                    {isCalcing && <CircularProgress size={24} />}
                                </Box>
                                <Box height={isXs ? 12 : 16} />
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Cấu hình thu hộ</InputLabel>
                                    <Select MenuProps={MenuProps} onChange={handleChangeCHTH} value={cau_hinh_thu_ho} label="Cấu hình thu hộ" variant="outlined">
                                        <MenuItem value={0}>Thu hộ = Tiền Hàng + Phí</MenuItem>
                                        <MenuItem value={1}>Thu hộ = Tiền Hàng</MenuItem>
                                    </Select>
                                    <FormHelperText>Chỉ có giá trị tại thời điểm lên đơn.</FormHelperText>
                                </FormControl>
                                <Collapse style={{ overflow: 'visible' }} in={fees.pvc > 0}>
                                    <TextField
                                        InputProps={{
                                            style: {
                                                color: '#333'
                                            }
                                        }}
                                        disabled variant="outlined" fullWidth label="Phí vận chuyển (đ)" value={Number(fees.pvc).toLocaleString('en')} />
                                </Collapse>
                                <Collapse style={{ overflow: 'visible' }} in={fees.pvkl > 0}>
                                    <TextField
                                        InputProps={{
                                            style: {
                                                color: '#333'
                                            }
                                        }}
                                        disabled variant="outlined" fullWidth label="Phí vượt khối lượng (đ)" value={fees.pvkl.toLocaleString('en')} />
                                </Collapse>
                                <Collapse in={fees.tbh > 0} style={{ overflow: 'visible' }}>
                                    <TextField
                                        InputProps={{
                                            style: {
                                                color: '#333'
                                            }
                                        }}
                                        disabled variant="outlined" fullWidth label="Tiền bảo hiểm (đ)" value={fees.tbh.toLocaleString('en')} />
                                </Collapse>
                                <FeeTextField disabled variant="outlined" fullWidth label="Tổng phí dịch vụ (đ)" value={(fees.tbh + fees.pvkl + Number(fees.pvc)).toLocaleString('en')}
                                    InputLabelProps={{ style: { color: color.secondary } }}
                                    InputProps={{
                                        style: { color: color.secondary, fontWeight: 500 },
                                    }} />
                                <CollectTextField disabled variant="outlined" fullWidth label="Tổng thu hộ (đ)" InputLabelProps={{ style: { color: color.secondary2 } }} inputProps={{ style: { color: color.secondary2, fontWeight: 500 } }} value={collect.toLocaleString('en')} />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </Dialog>
    )
})

export default CreateDialog