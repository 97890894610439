import { AppBar, Box, Button, CircularProgress, Collapse, Container, Dialog, IconButton, makeStyles, Slide, Toolbar, Typography, useMediaQuery, useTheme, withStyles } from "@material-ui/core"
import Close from "@material-ui/icons/Close"
import { Alert, AlertTitle } from "@material-ui/lab"
import { useSnackbar } from "notistack"
import { forwardRef, useCallback, useContext, useImperativeHandle, useRef, useState } from "react"
import { useDropzone } from "react-dropzone"
import { PaperDownload, Upload } from "react-iconly"
import SampleXLSX from '../assets/xlsx/Mau_Tao_Don_Hang.xlsx'
import color from "../config/color"
import { UserContext } from "../contexts/user.context"
import Warehouse from "./Warehouse"

const StyledAlert = withStyles({
    message: {
        width: '100%'
    }
})(Alert)

const useStyles = makeStyles((theme) => ({
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}))

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const UploadDialog = forwardRef((props, ref) => {
    const theme = useTheme()
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const { user, userFetch } = useContext(UserContext)
    const [uploading, setUploading] = useState(false)
    const [data, setData] = useState([])
    const [created, setCreated] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const repoSelected = useRef(null)
    const isXs = useMediaQuery(useTheme().breakpoints.only('xs'))
    const handleClose = () => {
        if (uploading) return
        setOpen(false)
        setTimeout(() => {
            props.onClose(created)
        }, 225)
    }
    const onDrop = useCallback((acceptedFiles) => {
        if (uploading) return
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                console.log(binaryStr)
            }
            reader.readAsArrayBuffer(file)
        })

    }, [])

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ disabled: uploading, onDrop, multiple: false, accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' })

    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true)
        }
    }))

    const handleUpload = async (e) => {
        e.preventDefault()
        setUploading(true)
        let formData = new FormData()
        formData.append('excel', acceptedFiles[0])
        formData.append('warehouse_id', repoSelected.current?.id)
        let res = await userFetch('/models/pending/upload.php', {
            method: 'POST',
            body: formData
        })
        let resj = await res.json()
        if (resj.response_code !== 200)
            enqueueSnackbar('File không đúng định dạng!', { variant: 'error' })
        if (resj.success)
            setCreated(true)
        console.log(resj)
        setData(resj)
        acceptedFiles.pop()
        setUploading(false)
    }

    return <Dialog TransitionComponent={Transition} open={open} fullScreen fullWidth maxWidth="sm" onClose={handleClose}>
        <form onSubmit={handleUpload} id="upload_order">
            <AppBar className={classes.appBar} color="secondary" elevation={0} position="fixed">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <Close />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Tạo đơn bằng Excel
                </Typography>
                    <Button disabled={(!user.policy_id && !user.policy_v2) || uploading} autoFocus color="inherit" type="submit">
                        {uploading ? <CircularProgress style={{ color: '#fff' }} size={24} /> : 'Tải lên'}
                    </Button>
                </Toolbar>
            </AppBar>
            <Container>
                <Box height={92} />
                {(!user.policy_id && !user.policy_v2) && <Alert style={{ margin: '8px 0 24px 0' }} severity="error">
                    Tài khoản này chưa có chính sách, không thể tạo đơn.
            </Alert>}
                {isXs ? <>
                    <Warehouse onClose={handleClose} ref={repoSelected} />
                    <Button fullWidth href={SampleXLSX} style={{ height: 40, marginTop: 16 }} variant="outlined" color="primary">
                        <PaperDownload /> <Box width={3} /> Tải File mẫu
                        </Button>
                </> :
                    <Box display="flex">
                        <Box flex={1}>
                            <Warehouse ref={repoSelected} onClose={handleClose} />
                        </Box>
                        <Box paddingLeft={1} height="100%">
                            <Button href={SampleXLSX} style={{ height: 40 }} variant="outlined" color="primary">
                                <PaperDownload /> <Box width={3} /> Tải File mẫu
                        </Button>
                        </Box>
                    </Box>
                }
                <Box height={16} />
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Upload set="bulk" size={72} color={color.svg} />
                    <p style={{ marginBottom: 4 }}>{acceptedFiles.length ? <span style={{ color: theme.palette.primary.main, fontWeight: 500 }}>{acceptedFiles[0].name}</span> : isXs ? 'Click để chọn file Excel' : <>Kéo thả file excel vào đây hoặc <span style={{ color: theme.palette.primary.main, fontWeight: 500 }}>Click để chọn file</span></>}</p>
                    <small>( Định dạng: .xlsx, .xls )</small>
                </div>
                <Box height={8} />
                <Collapse in={!uploading}>
                    {data.success > 0 &&
                        <Alert style={{ margin: '8px 0 12px 0' }} severity="success">
                            {data.success} đơn upload thành công!
            </Alert>}
                    {!Boolean(data.logs?.length) ? <Box height={12} />
                        :
                        <StyledAlert style={{ margin: '8px 0 24px 0', width: '100%' }} severity="warning">
                            <AlertTitle>{data.error} đơn upload thất bại!</AlertTitle>
                            <Box maxHeight={190} style={{ overflowY: 'auto', width: '100%' }}>
                                {data.logs.map((v, i) =>
                                    <div key={i}>{v}</div>
                                )}
                            </Box>
                        </StyledAlert>
                    }
                </Collapse>
            </Container>
        </form>
    </Dialog>
})

export default UploadDialog