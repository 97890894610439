import { createMuiTheme } from "@material-ui/core"
import { grey } from "@material-ui/core/colors"
import { createContext, useEffect, useRef, useState } from "react"
import PhotoViewer from "../components/PhotoViewer"
import color from "../config/color"
import { dev } from "../config/config"

const UserContext = createContext(null)

const theTheme = () => {
    let cnJSON;
    try {
        cnJSON = JSON.parse(localStorage.getItem('chi_nhanh'))
    } catch {
        localStorage.clear();
    }
    return ({
        palette: {
            primary: {
                main: cnJSON?.primary_color ?? '#3da495'
            },
            secondary: {
                main: cnJSON?.secondary_color ?? '#1976d2'
            },
            success: {
                main: '#22baa6'
            }
        },
        overrides: {
            MuiButton: {
                root: {
                    boxShadow: 'none!important',
                    textTransform: 'initial',
                },
            },
            MuiTooltip: {
                tooltip: {
                    backgroundColor: '#212121'
                }
            },
            MuiTableCell: {
                root: {
                    padding: '16px 0',
                    borderBottomColor: grey[200],
                    verticalAlign: 'top'
                },
                head: {
                    color: grey[900]
                }
            },
            MuiPaper: {
                rounded: {
                    borderRadius: 8
                },
            },
            MuiOutlinedInput: {
                notchedOutline: {
                    borderColor: color.svg,
                }
            },
            MuiCheckbox: {
                root: {
                    color: color.svg
                }
            },
            MuiSwitch: {
                track: {
                    backgroundColor: color.svg
                }
            },
            MuiContainer: {
                root: {
                    ['@media(min-width: 1920px)']: {
                        paddingLeft: 48,
                        paddingRight: 48
                    },
                },
                maxWidthLg: {
                    ['@media(min-width: 1280px)']: {
                        maxWidth: '1920px',
                    },
                }
            },
        },
        typography: {
            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif`,
        },
        props: {
            MuiGrid: {
                spacing: 2
            },
        },
    })
}

const defaultTheme = {
    'primary_color': '#3da495',
    'secondary_color': '#1976d2',
    'sp_intro': 'Giao Hàng Siêu Việt CO., JSC',
    'sp_phone': '0355025465',
    'sp_zalo': '0355025465',
    'sp_mail': 'hotro@ghsv.vn',
    'sp_fb': '',
    'logo': '',
    'header_text_logo': 'GHSV'
};

const UserProvider = ({ children }) => {
    const [notification_token, set_notification_token] = useState(null)
    const [themeOption, setThemeOption] = useState(createMuiTheme(theTheme()))
    const [miniDesign, _setMiniDesign] = useState(false) //useState(localStorage.getItem('miniDesign') === "true" ?? false)
    const [nested_account, _set_nested_account] = useState(() => {
        if (localStorage.getItem('nested_account')) {
            try {
                let userJSON = JSON.parse(localStorage.getItem('nested_account'))
                if (typeof (userJSON) === 'object')
                    return userJSON
            } catch (e) {
                console.log('Invalid Account JSON')
            }
        }
        return []
    })
    const [user, _setUser] = useState(() => {
        if (localStorage.getItem('account')) {
            try {
                let userJSON = JSON.parse(localStorage.getItem('account'))
                if (typeof (userJSON) === 'object')
                    return userJSON
            } catch (e) {
                localStorage.clear();
                console.log('Invalid Account JSON')
            }
        }
        return null
    })
    const [chi_nhanh, _set_chi_nhanh] = useState(() => {
        if (localStorage.getItem('chi_nhanh')) {
            try {
                let cnJSON = JSON.parse(localStorage.getItem('chi_nhanh'))
                if (typeof (cnJSON) === 'object' && cnJSON !== null)
                    return cnJSON
            } catch (e) {
                localStorage.clear();
                console.log('Invalid Account JSON')
            }
        }
        return defaultTheme;
    })
    const pVRef = useRef(null)

    const set_nested_account = (newData) => {
        localStorage.setItem('nested_account', JSON.stringify(newData))
        _set_nested_account(newData)
    }

    const setMiniDesign = (newData) => {
        localStorage.setItem('miniDesign', newData)
        _setMiniDesign(newData)
    }

    const setUser = (newData) => {
        if (newData === null) {
            sessionStorage.clear()
            localStorage.clear()
            set_notification_token(null)
            setThemeOption(createMuiTheme(theTheme()))
        }
        else if (typeof newData === 'object')
            localStorage.setItem('account', JSON.stringify(newData))
        _setUser(newData)
    }

    const set_chi_nhanh = (newData) => {
        try {
            localStorage.setItem('chi_nhanh', newData)
            setThemeOption(createMuiTheme(theTheme()))
            _set_chi_nhanh(JSON.parse(newData))
        } catch {
            _set_chi_nhanh(defaultTheme);
        }
    }

    const userFetch = async (path, config) => {
        let url = dev ? 'https://ns.ghsv.vn/v2/api' : (chi_nhanh.domain ? 'https://'+chi_nhanh.domain+'/v2/api' : '/v2/api')
        url += path

        let headers = {
            ...config?.headers,
            authorization: user?.token
        }
        if (dev) {
            if (url.search(/\?/) > -1)
                url += '&remote_dev'
            else
                url += '?remote_dev'
            delete headers.authorization
        }
        let res = await fetch(url, {
            ...config,
            headers,
        })
        if (res.status === 403)
            return setUser(null)
        return res
    }

    const getNested = () => {
        if (user.user_ref === "0")
            userFetch('/models/account/nested_account.php')
                .then(r => r.json())
                .then(r => set_nested_account(r.data))
    }

    useEffect(() => {
        if (!user) return

        userFetch('/login.php', {
            method: 'POST'
        })
            .then(r => r.json())
            .then(r => {
                setUser({ ...user, ...r })
                if (r.app)
                    set_chi_nhanh(r.app)
            })

        getNested()

    }, [])

    return (
        <UserContext.Provider value={{
            chi_nhanh, user, setUser, userFetch, miniDesign, setMiniDesign, set_chi_nhanh, themeOption, notification_token, set_notification_token,
            nested_account, set_nested_account, getNested, pVRef
        }}>
            <PhotoViewer ref={pVRef} />
            {children}
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider }
