import { Button, CircularProgress, TextField, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { Alert, Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Copy as CopyIcon, Smartphone } from 'react-feather';
import { dev } from '../config/config';
import { UserContext } from '../contexts/user.context';
import Copy from './Copy';

const WrapperSection = ({ children, onSubmit, onClose, text = 'Cập nhật' }) => {
    const [loading, setLoading] = useState(false)
    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        await onSubmit()
        setLoading(false)
    }

    return (
        <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" height={356}>
                <Box flex={1} lineHeight={4}>
                    {children}
                </Box>
                <Box textAlign="right">
                    <Button disabled={loading} onClick={onClose}>Đóng</Button>
                    {' '}
                    <Button disabled={loading} variant="contained" color="primary" type="submit">{text}</Button>
                </Box>
            </Box>
        </form>
    )
}

const Account1 = ({ onClose }) => {
    const { user, userFetch, setUser } = useContext(UserContext)
    const [email, setEmail] = useState(user.customer_email ?? '')
    const [zalo, setZalo] = useState(user.customer_phone_zalo ?? '')
    const [name, setName] = useState(user.customer_shop_code ?? '')
    const [phone, setPhone] = useState(user.customer_phone ?? '')
    const { enqueueSnackbar } = useSnackbar()

    const handleSubmit = async () => {
        await new Promise(res => setTimeout(() => res(true), 500))
        let formData = new FormData()
        formData.append('customer_email', email)
        formData.append('customer_phone', phone)
        formData.append('customer_shop_name', name)
        let res = await userFetch('/models/account/change_info.php', {
            method: 'POST',
            body: formData
        })
        let resj = await res.json()
        if (resj.status === 'error')
            return enqueueSnackbar(resj.msg, { variant: 'error' })
        else {
            setUser({ ...user, ...resj.data })
            return enqueueSnackbar('Cập nhật thành công!', { variant: 'success' })
        }
    }
    return (
        <WrapperSection onSubmit={handleSubmit} onClose={onClose}>
            <TextField fullWidth value={user.username} label="Tên đăng nhập" disabled />
            <TextField disabled fullWidth onChange={e => setPhone(e.target.value)} value={phone} label="Số điện thoại" inputProps={{ minLength: 10, maxLength: 11 }} />
            {/* <TextField fullWidth value={user.customer_shop_code} label="Shop code" disabled /> */}
            <TextField required fullWidth onChange={e => setName(e.target.value)} value={name} label="Tên shop" inputProps={{ minLength: 3, maxLength: 50 }} />
            <TextField required fullWidth onChange={e => setEmail(e.target.value)} value={email} label="Email" type="email" />
            {/* <TextField fullWidth inputProps={{ maxLength: 10 }} onChange={e => setZalo(e.target.value)} value={zalo} label="Zalo" /> */}
        </WrapperSection>
    )
}

const Account2 = ({ onClose }) => {
    const { user, userFetch, setUser } = useContext(UserContext)
    const [name_bank, set_name_bank] = useState(null)
    const [number_bank, set_number_bank] = useState(user.customer_number_bank ?? '')
    const [id_bank, set_id_bank] = useState(user.customer_id_bank ?? '')
    const [otp, setOTP] = useState('')
    const [banks, setBanks] = useState([])
    const [sending, setSending] = useState(0)

    const { enqueueSnackbar } = useSnackbar()

    const handleCB = async () => {
        await new Promise(res => setTimeout(() => res(true), 500))
        let formData = new FormData()
        formData.append('customer_name_bank', name_bank.name)
        formData.append('customer_number_bank', number_bank)
        formData.append('customer_id_bank', id_bank)
        if (user.customer_name_bank !== '')
            formData.append('otp', otp)
        let res = await userFetch('/models/account/change_info.php', {
            method: 'POST',
            body: formData
        })
        let resj = await res.json()
        if (resj.status === 'error')
            return enqueueSnackbar(resj.msg, { variant: 'error' })
        else {
            if (user.customer_name_bank === '')
                enqueueSnackbar('Cập nhật thành công, có thể ĐÓNG HỘP THOẠI!', { variant: 'success' })
            else
                enqueueSnackbar('Cập nhật thành công!', { variant: 'success' })
            setUser({ ...user, ...resj.data })
            setOTP('')
        }
    }

    const getOTP = async () => {
        if (sending) return
        setSending(60)
        try {
            const res = await userFetch('/models/account/send_otp.php?func=change_bank', {
                method: 'POST'
            })
            const resj = await res.json()
            if (resj.status != 'success') throw resj.msg
            enqueueSnackbar('Đã gửi OTP đến SĐT đăng ký', { variant: 'success' })
        } catch (e) {
            console.log(e)
            enqueueSnackbar(typeof e == 'string' ? e : 'Gửi OTP thất bại, thử lại sau!', { variant: 'error' })
        }
    }

    const getBank = async () => {
        try {
            const res = await userFetch('/models/app/bank.php')
            const resj = await res.json()
            setBanks(resj.banks)
            const found = resj.banks.find((e) => e.name == user.customer_name_bank)
            if (found)
                set_name_bank(found)
        } catch (e) {
            enqueueSnackbar('Lỗi khi lấy danh sách ngân hàng', { variant: 'error' })
        }
    }

    useEffect(() => {
        getBank()
        console.log(dev, typeof dev)
    }, [])

    useEffect(() => {
        if (sending === 0) return
        setTimeout(() => {
            setSending(sending - 1)
        }, 1000)
    }, [sending])

    return (
        <WrapperSection onSubmit={handleCB} onClose={onClose}>
            <Alert severity="info" style={{ marginBottom: 16 }}><b>Tiền đối soát</b> sẽ được chuyển về tài khoản ngân hàng này.</Alert>
            <Autocomplete loading={banks.length === 0} loadingText={<CircularProgress size={20} />}
                value={name_bank}
                onChange={(_, newValue) => set_name_bank(newValue)}
                options={banks.map((e) => {
                    e.label = e.code + ' - ' + e.name.replace(/Ngân hàng\s*/ig, '')
                    return {
                        name: e.name,
                        label: e.label
                    }
                })}
                noOptionsText="Không có kết quả"
                getOptionLabel={(option) => option.label || ""}
                renderInput={(params) => <TextField {...params} label="Tên ngân hàng" required />} />
            <TextField required fullWidth onChange={e => set_number_bank(e.target.value)} value={number_bank} label="Số tài khoản" />
            <TextField required fullWidth onChange={e => set_id_bank(e.target.value)} value={id_bank} label="Chủ tài khoản" />
            {user.customer_name_bank === '' ? null :
                <>
                    <TextField required fullWidth onChange={e => setOTP(e.target.value)} value={otp} label="Mã xác nhận gửi về điện thoại" />
                    <Button disabled={sending > 0} variant="outlined" onClick={getOTP} startIcon={<Smartphone />}>{sending === 0 ? 'Lấy mã xác nhận' : 'Gửi lại sau ' + sending + 's'}</Button>
                </>
            }
        </WrapperSection>
    )
}

const Account3 = ({ onClose }) => {

    const { userFetch, setUser } = useContext(UserContext)
    const { enqueueSnackbar } = useSnackbar()
    const [input_oldpw_ref, input_newpw_ref, input_newpw2_ref] =
        [useRef(null), useRef(null), useRef(null)]

    const handleCP = async () => {
        await new Promise(res => setTimeout(() => res(true), 500))
        if (input_newpw2_ref.current.value != input_newpw_ref.current.value)
            return enqueueSnackbar('Mật khẩu nhập lại không khớp', { variant: 'error' })
        let formData = new FormData()
        formData.append('old_password', input_oldpw_ref.current.value)
        formData.append('new_password', input_newpw_ref.current.value)
        let res = await userFetch('/models/account/change_password.php', {
            method: 'POST',
            body: formData
        })
        let resj = await res.json()
        if (resj.status === 'error')
            return enqueueSnackbar(resj.msg, { variant: 'error' })
        return setUser(null)
    }

    return (
        <WrapperSection onSubmit={handleCP} onClose={onClose}>
            <Alert severity="info">Sau khi đổi mật khẩu, bạn sẽ đăng xuất.</Alert>
            <Box height={12} />
            <TextField inputRef={input_oldpw_ref} required fullWidth type="password" label="Mật khẩu cũ" />
            <TextField inputRef={input_newpw_ref} required fullWidth type="password" label="Mật khẩu mới" inputProps={{ maxLength: 20, minLength: 6 }} />
            <TextField inputRef={input_newpw2_ref} required fullWidth type="password" label="Nhập lại mật khẩu mới" inputProps={{ maxLength: 20, minLength: 6 }} />
        </WrapperSection>
    )
}

const Account4 = ({ onClose }) => {

    const { userFetch, setUser, user } = useContext(UserContext)
    const { enqueueSnackbar } = useSnackbar()

    const handleCP = async () => {
        if (user.api_token) {
            let c = window.confirm('Bạn chắc chắn muốn tạo mã mới? Mã cũ sẽ không còn truy cập được nữa!');
            if (!c) return;
        }
        await new Promise(res => setTimeout(() => res(true), 500))
        let res = await userFetch('/models/app/generate_token.php')
        let resj = await res.json()
        if (resj.status === 'error')
            return enqueueSnackbar(resj.msg, { variant: 'error' })
        setUser({ ...user, api_token: resj.token })
    }

    return (
        <WrapperSection onSubmit={handleCP} onClose={onClose} text="Tạo mã mới">
            <Alert severity="info">Token dùng để kết nối <b>phần mềm bán hàng</b> hoặc tích hợp vào <b>website của bạn</b>.</Alert>
            <Box height={16} />
            <TextField variant="outlined" value={user.api_token} multiline disabled fullWidth label="Token của bạn" />
            {user.api_token ? <Copy msg="Sao chép token thành công." text={user.api_token}><Button variant="outlined" startIcon={<CopyIcon />}> Sao chép</Button></Copy> : null}
        </WrapperSection>
    )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        //borderTop: '2px solid #90a4ae',
        //borderBottom: '2px solid #90a4ae',
        overflowY: 'hidden'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

export default function Account({ onClose }) {
    const classes = useStyles();
    const { user } = useContext(UserContext)
    const [value, setValue] = React.useState(user.customer_name_bank === '' ? 2 : 0);
    const isXs = useMediaQuery(useTheme().breakpoints.only('xs'))

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root} style={{
            display: isXs ? 'block' : 'flex',
            height: isXs ? '100%' : 404,
            borderTop: '1px solid #ddd'
        }}>
            <Tabs
                orientation={isXs ? 'horizontal' : 'vertical'}
                //variant="scrollable"
                //indicatorColor="secondary"
                centered
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Tài khoản" {...a11yProps(0)} />
                <Tab label="Tích hợp" {...a11yProps(1)} />
                <Tab label="Ngân hàng" {...a11yProps(2)} disabled={user.user_ref != 0} />
                {!isXs && <Tab disabled label="Cài đặt" {...a11yProps(3)} />}
                <Tab label="Mật khẩu" {...a11yProps(4)} />
            </Tabs>
            <TabPanel value={value} index={0} style={{ flex: 1 }}>
                <Account1 onClose={onClose} />
            </TabPanel>
            <TabPanel value={value} index={1} style={{ flex: 1 }}>
                <Account4 onClose={onClose} />
            </TabPanel>
            <TabPanel value={value} index={2} style={{ flex: 1 }}>
                <Account2 onClose={onClose} />
            </TabPanel>
            {!isXs &&
                <>
                    <TabPanel index={3} />
                </>
            }
            <TabPanel value={value} index={isXs ? 3 : 4} style={{ flex: 1 }}>
                <Account3 onClose={onClose} />
            </TabPanel>
        </div>
    );
}
