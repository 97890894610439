const textTransform = {
    ucfirst: (str) => {
        try {
            return str.length ? (str[0].toUpperCase() + str.slice(1, str.length)) : ''
        } catch {
            return str ?? ''
        }
    },
    mobile: (str) => {
        try {
            str = str.replace('+84', '0')
            if (str.length < 10)
                return str
            else {
                // 037.8872.140
                str = str.split('')
                str.splice(3, 0, '.')
                str.splice(7, 0, '.')
                return str.join('')
            }
        } catch {
            return str ?? ''
        }
    }
}

export default textTransform