import { Paper, withStyles } from "@material-ui/core"

const StyledPaper = withStyles(() => ({
    outlined: {
        margin: '0 -16px',
        borderRight: 'none',
        borderLeft: 'bottom',
        borderRadius: 0,
        padding: '8px 16px',
        borderColor: '#f4f7f9'
    }
}))(Paper)

export default (props) => <StyledPaper {...props} />