import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, TextField, useTheme } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import Lottie from "lottie-react"
import { useSnackbar } from "notistack"
import { useContext, useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { Call, Calling, Chat, Discovery, Home, Lock, Message, Scan, Send, Unlock, User } from "react-iconly"
import { Redirect, useHistory } from "react-router"
import { Link } from "react-router-dom"
import validator from "validator"
import appstoreIcon from "../assets/img/appstore.png"
import ggplayIcon from "../assets/img/ggplay.png"
import Hello from '../assets/json/car.json'
import color from "../config/color"
import { dev, provinces } from "../config/config"
import { UserContext } from "../contexts/user.context"
import LoginPageStyles from "../css/LoginPage.module.sass"

const LoginForm = ({ branch }) => {
    const { setUser, set_nested_account, set_chi_nhanh } = useContext(UserContext)
    const homeurl = '/v2/api'
    const [userRef, passwordRef] = [useRef(null), useRef(null)]
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(false)

    const handleLogin = async (e) => {
        e.preventDefault()
        setLoading(true)
        await new Promise(res => setTimeout(() => res(true), 500))
        const payload = new FormData()

        let username = userRef.current.value
        let _homeurl = dev ? 'https://demo.ghsv.vn/v2/api' : homeurl
        let formatUsername = userRef.current.value.split('.')
        if (formatUsername.length > 1) {
            if (branch.filter(e => e.code == formatUsername[0]).length) {
                username = formatUsername[1]
                let domain = branch.filter(e => e.code == formatUsername[0])[0].domain;
                if (window.location.host == 'pro.ghsv.vn')
                    _homeurl = 'https://' + domain + '/v2/api';
            }
        }

        payload.append('username', username)
        payload.append('password', passwordRef.current.value)

        const res = await fetch(_homeurl + '/login.php' + (dev ? '?remote_dev' : ''), {
            method: 'POST',
            body: payload
        })
        let resj = await res.json()
        setLoading(false)
        if (resj.response_code !== 200)
            enqueueSnackbar(resj.msg, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
            })
        else {
            setUser(resj)
            if (resj.app)
                set_chi_nhanh(resj.app)
            if (resj.user_ref === "0")
                fetch(_homeurl + '/models/account/nested_account.php', {
                    headers: {
                        authorization: resj.token
                    }
                })
                    .then(r => r.json())
                    .then(r => set_nested_account(r.data))
                    .catch(e => {
                        console.log(e)
                    })
        }
    }

    return <div className={LoginPageStyles.loginForm}>
        <div className={LoginPageStyles.logo}>Mừng Trở Lại</div>
        <div className={LoginPageStyles.slogan}>Đối tác giao hàng số 1 Việt Nam.</div>
        <form autoComplete="off" onSubmit={handleLogin}>
            <TextField
                disabled={loading}
                required
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <User color={color.svg} set="bold" />
                        </InputAdornment>
                    ),
                }}
                inputRef={userRef}
                variant="outlined" color="secondary" placeholder="Tài khoản" fullWidth />
            <Box height={16} />
            <TextField
                disabled={loading}
                required
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Lock color={color.svg} set="bold" />
                        </InputAdornment>
                    ),
                }}
                inputRef={passwordRef}
                variant="outlined" type="password" color="secondary" placeholder="Mật khẩu" fullWidth />
            <Box height={8} />
            <Box display="flex" justifyContent="flex-end"><Button color="primary" component={Link} to="/recovery" size="small">Quên mật khẩu?</Button></Box>
            <Box height={8} />
            <Button disabled={loading} type="submit" fullWidth size="large" variant="contained" color="secondary">{loading ? 'Đang đăng nhập...' : 'Đăng nhập'}</Button>
            <Box height={8} />
            <Button fullWidth component={Link} to="/register" size="large" color="secondary" variant="outlined">Tạo tài khoản</Button>
        </form>
    </div>
}

const RecoveryForm = ({ branch }) => {
    const homeurl = '/v2/api'
    const [userRef, passwordRef, otpRef] = [useRef(null), useRef(null), useRef(null)]
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(0)
    const history = useHistory()

    const getOTP = async () => {
        if (sending) return
        try {
            let username = userRef.current.value
            if (!username)
                return enqueueSnackbar('Tài khoản không được bỏ trống!', { variant: 'error' });

            let _homeurl = dev ? 'https://demo.ghsv.vn/v2/api' : homeurl
            let formatUsername = userRef.current.value.split('.')
            if (formatUsername.length > 1) {
                if (branch.filter(e => e.code == formatUsername[0]).length) {
                    username = formatUsername[1]
                    let domain = branch.filter(e => e.code == formatUsername[0])[0].domain;
                    if (window.location.host == 'pro.ghsv.vn')
                        _homeurl = 'https://' + domain + '/v2/api';
                }
            }
            setSending(60)
            const formData = new FormData()
            formData.append('username', username)
            const res = await fetch(`${_homeurl}/models/account/send_otp.php`, {
                method: 'POST',
                body: formData
            })
            const resj = await res.json()
            if (resj.status != 'success') throw resj.msg
            enqueueSnackbar('Đã gửi OTP đến SĐT đăng ký', { variant: 'success' })
        } catch (e) {
            console.log(e)
            enqueueSnackbar(typeof e == 'string' ? e : 'Gửi OTP thất bại, thử lại sau!', { variant: 'error' })
        }
    }

    const handleChangePassword = async (e) => {
        e.preventDefault();
        if (loading) return
        setLoading(true)
        try {
            let username = userRef.current.value
            let _homeurl = dev ? 'https://demo.ghsv.vn/v2/api' : homeurl
            let formatUsername = userRef.current.value.split('.')
            if (formatUsername.length > 1) {
                if (branch.filter(e => e.code == formatUsername[0]).length) {
                    username = formatUsername[1]
                    let domain = branch.filter(e => e.code == formatUsername[0])[0].domain;
                    if (window.location.host == 'pro.ghsv.vn')
                        _homeurl = 'https://' + domain + '/v2/api';
                }
            }
            const formData = new FormData()
            formData.append('username', username)
            formData.append('new_password', passwordRef.current.value)
            formData.append('otp', otpRef.current.value)
            const res = await fetch(`${_homeurl}/models/account/recovery_password.php`, {
                method: 'POST',
                body: formData
            })
            const resj = await res.json()
            if (resj.status != 'success') throw resj.msg
            enqueueSnackbar('Thao tác thành công, tiến hành đăng nhập', { variant: 'success' })
            history.push('/login')
        } catch (e) {
            console.log(e)
            enqueueSnackbar(typeof e == 'string' ? e : 'Có lỗi xảy ra!', { variant: 'error' })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (sending === 0) return
        setTimeout(() => {
            setSending(sending - 1)
        }, 1000)
    }, [sending])

    return <div className={LoginPageStyles.loginForm}>
        <div className={LoginPageStyles.logo}>Khôi Phục Mật Khẩu</div>
        <div className={LoginPageStyles.slogan}>Đối tác giao hàng số 1 Việt Nam.</div>
        <form autoComplete="off" onSubmit={handleChangePassword}>
            <TextField
                disabled={loading}
                required
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <User color={color.svg} set="bold" />
                        </InputAdornment>
                    ),
                }}
                inputRef={userRef}
                variant="outlined" color="secondary" placeholder="Tài khoản" fullWidth />
            <Box height={16} />
            <TextField
                disabled={loading}
                required
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Unlock color={color.svg} set="bold" />
                        </InputAdornment>
                    ),
                }}
                inputRef={passwordRef}
                variant="outlined" color="secondary" placeholder="Mật khẩu mới" fullWidth />
            <Box height={16} />
            <TextField
                disabled={loading}
                required
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Chat color={color.svg} set="bold" />
                        </InputAdornment>
                    ),
                }}
                inputRef={otpRef}
                variant="outlined" color="secondary" placeholder="Mã OTP" fullWidth />
            <Box height={8} />
            <Box display="flex" justifyContent="flex-end">
                <Button type="button" onClick={getOTP} disabled={sending > 0} variant="outlined" color="primary">
                    {sending > 0 ? `Có thể gửi lại sau ${sending}s` : 'Gửi mã OTP'}
                </Button>
            </Box>
            <Box height={8} />
            <Button disabled={loading} type="submit" fullWidth size="large" variant="contained" color="primary">{loading ? 'Đang thay đổi...' : 'Đổi mật khẩu'}</Button>
            <Box height={8} />
            <Button component={Link} to="/login" fullWidth size="large" color="secondary" variant="outlined">Đăng nhập</Button>
        </form>
    </div>
}

const RegistrationForm = ({ branch }) => {
    const [nameRef, addressRef, quantityPerMonthRef, phoneRef, branchCodeRef, awaitRef, formRef] =
        [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(true), useRef(null)]
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(false)
    const [isOpenSuccess, setIsOpenSuccess] = useState(false)
    const [pdc, setPDC] = useState([])
    const [pdcOptions, setPDCOptions] = useState(provinces)

    const closeSuccess = () => {
        setIsOpenSuccess(false)
        enqueueSnackbar('Đăng ký thành công! Chúng tôi sẽ liên hệ với bạn ngay khi tài khoản được duyệt.', { variant: 'success' })
    }

    const getOptions = async (_, newValue) => {
        setPDC(newValue)
        setPDCOptions([])
        awaitRef.current = true
        switch (newValue.length) {
            case 0:
                awaitRef.current = false
                setPDCOptions(provinces)
                break
            case 1:
                try {
                    const res = await fetch(`https://demo.ghsv.vn/v2/api/models/app/query/address.php?act=get_district_by_code&code=${newValue[0].code}`)
                    const resj = await res.json()
                    if (awaitRef.current)
                        setPDCOptions(resj.data)
                } catch (e) {
                    console.log(e)
                    enqueueSnackbar('Có lỗi khi lấy huyện!', { variant: 'error' })
                }
                break;
            case 2:
                try {
                    const res = await fetch(`https://demo.ghsv.vn/v2/api/models/app/query/address.php?act=get_commune_by_code&code=${newValue[1].code}`)
                    const resj = await res.json()
                    if (awaitRef.current)
                        setPDCOptions(resj.data)
                } catch (e) {
                    console.log(e)
                    enqueueSnackbar('Có lỗi khi lấy xã!', { variant: 'error' })
                }
                break;
            case 3:
                awaitRef.current = false
                setPDCOptions([])
                quantityPerMonthRef.current.focus()
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        nameRef.current.value = params.get('n') ?? ''
        addressRef.current.value = params.get('a') ?? ''
        quantityPerMonthRef.current.value = params.get('q') ?? ''
        phoneRef.current.value = params.get('p') ?? ''
        branchCodeRef.current.value = params.get('b') ?? ''        

        const found = branch.find(e => e.domain == window.location.host)
        if (found) {
            branchCodeRef.current.value = found.code
        }
    }, [branch])

    const handleRegister = async (e) => {
        e.preventDefault();
        if (loading) return
        setLoading(true)
        try {
            if (!validator.isLength(nameRef.current.value, { min: 5 })) throw 'Tên cửa hàng tối thiểu 5 ký tự.'
            if (!validator.isLength(addressRef.current.value, { min: 5 })) throw 'Địa chỉ tối thiểu 5 ký tự.'
            if (Number(quantityPerMonthRef.length) < 1) throw 'Lượng đơn mỗi tháng không hợp lệ.'
            if (!validator.isMobilePhone(phoneRef.current.value, 'vi-VN')) throw 'SĐT không hợp lệ.'
            if (branchCodeRef.current.value.length > 0 && branchCodeRef.current.value.length !== 2) throw 'Mã bưu cục không hợp lệ.'
            if (pdc.length < 3) throw 'Chưa chọn tỉnh / huyện / xã.'
            const res = await fetch('https://ghsv-webhook.whos.vn/v3/guest/register', {
                method: 'POST',
                body: JSON.stringify({
                    name: nameRef.current.value,
                    address: addressRef.current.value,
                    quantityPerMonth: quantityPerMonthRef.current.value,
                    phone: phoneRef.current.value,
                    branchCode: branchCodeRef.current.value,
                    province: pdc[0].name,
                    district: pdc[1].name,
                    commune: pdc[2].name,
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const resj = await res.json()
            if (resj.success === false) throw resj.msg
            formRef.current.reset()
            setPDC([])
            setPDCOptions(provinces)
            setIsOpenSuccess(true)
        } catch (e) {
            console.log(e)
            enqueueSnackbar(typeof e == 'string' ? e : 'Có lỗi xảy ra!', { variant: 'error' })
        } finally {
            setLoading(false)
        }
    }

    return <div className={LoginPageStyles.loginForm}>
        <div className={LoginPageStyles.logo}>Tạo Tài Khoản</div>
        <div className={LoginPageStyles.slogan}>Đối tác giao hàng số 1 Việt Nam.</div>
        <Dialog open={isOpenSuccess} maxWidth="xs">
            <DialogTitle>Đăng ký thành công</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Chúng tôi sẽ liên hệ và cung cấp tài khoản / mật khẩu ngay khi tài khoản được duyệt, bạn có thể liên hệ tổng đài nếu chờ quá lâu.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeSuccess}>Đã Hiểu</Button>
            </DialogActions>
        </Dialog>
        <form autoComplete="off" onSubmit={handleRegister} ref={formRef}>
            <TextField
                disabled={loading}
                required
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Home color={color.svg} set="bold" />
                        </InputAdornment>
                    ),
                }}
                inputRef={nameRef}
                variant="outlined" color="secondary" placeholder="Tên cửa hàng / người gửi hàng" fullWidth />
            <Box height={16} />
            <TextField
                disabled={loading}
                required
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Send color={color.svg} set="bold" />
                        </InputAdornment>
                    ),
                }}
                inputRef={addressRef}
                variant="outlined" color="secondary" placeholder="Địa chỉ chi tiết" fullWidth />
            <Box height={16} />
            <Autocomplete
                disableCloseOnSelect={pdc.length < 2}
                //disabled={pdc.length === 3}
                loading={pdcOptions.length === 0 && pdc.length < 3}
                loadingText={<CircularProgress size={20} />}
                noOptionsText={pdcOptions.length === 0 && pdc.length < 3 ? 'Không có kết quả.' : 'Đã chọn đầy đủ.'}
                getOptionLabel={(option) => option.name}
                options={pdcOptions}
                value={pdc}
                onChange={getOptions}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                            onDelete={index < pdc.length - 1 ? null : getTagProps({ index })['onDelete']}
                        />
                    ))
                }
                multiple
                renderInput={(params) => <TextField
                    {...params}
                    //disabled={loading}
                    size="small"
                    //inputRef={userRef}
                    variant="outlined" color="secondary" placeholder={['Tỉnh / Huyện / Xã', 'Chọn Huyện', 'Chọn Xã'][pdc.length]} fullWidth />}
            />
            <Box height={16} />
            <TextField
                disabled={loading}
                required
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Scan color={color.svg} set="bold" />
                        </InputAdornment>
                    ),
                }}
                inputRef={quantityPerMonthRef}
                type="number"
                variant="outlined" color="secondary" placeholder="Lượng đơn trung bình mỗi tháng" fullWidth />
            <Box height={16} />
            <TextField
                disabled={loading}
                required
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Call color={color.svg} set="bold" />
                        </InputAdornment>
                    ),
                }}
                inputRef={phoneRef}
                variant="outlined" color="secondary" placeholder="Số điện thoại" fullWidth />
            <Box height={16} />
            <TextField
                disabled={loading}
                size="small"
                helperText="Không bắt buộc, có thể bỏ qua."
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Discovery color={color.svg} set="bold" />
                        </InputAdornment>
                    ),
                }}
                inputRef={branchCodeRef}
                variant="outlined" color="secondary" placeholder="Mã bưu cục" fullWidth />
            <Box my={2}>
                <small style={{ color: color.light }}>* Nhập đầy đủ thông tin để có giá tốt nhất cho bạn.</small>
            </Box>
            <Button disabled={loading} type="submit" fullWidth size="large" variant="contained" color="primary">{loading ? 'Đang đăng ký...' : 'Đăng ký'}</Button>
            <Box height={8} />
            <Button component={Link} to="/login" fullWidth size="large" color="secondary" variant="outlined">Đăng nhập</Button>
        </form>
    </div>
}

const LoginPage = ({ path }) => {
    const theme = useTheme()
    const { user } = useContext(UserContext)
    const [page, setPage] = useState(path ?? 'login') // login || registration || recovery
    const [branch, setBranch] = useState([])

    const getBranch = async () => {
        let res = await fetch('https://demo.ghsv.vn/v2/api/chi_nhanh.php')
        let resj = await res.json()
        setBranch(resj.data)
    }

    useEffect(() => getBranch(), [])

    if (user)
        return <Redirect to={{
            pathname: '/',
            state: {
                logged_in: true
            }
        }} />

    return (
        <>
            <div className={LoginPageStyles.bg} />
            <div className={LoginPageStyles.wrapper}>
                <Helmet defer={false}>
                    <title>{page === 'login' ? 'Đăng nhập' : (page === 'registration' ? 'Tạo tài khoản' : 'Khôi phục mật khẩu')}</title>
                    <meta name="theme-color" content={theme.palette.primary.main} />
                    {/* <link rel="manifest" href="/v2/manifest.json" /> */}
                    <link rel="apple-touch-icon" href="/v2/logo192.png" />
                </Helmet>
                <Lottie style={{ height: 150 }} animationData={Hello} />
                {/* <div style={{ backgroundImage: `url(${chi_nhanh.logo || 'https://demo.ghsv.vn/system/assets/logo/default.png'})`, height: 150, width: 150, backgroundSize: 'cover' }} /> */}
                {
                    page === 'login' ? <LoginForm setPage={setPage} branch={branch} /> :
                        (page === 'registration' ?
                            <RegistrationForm branch={branch} setPage={setPage} />
                            :
                            <RecoveryForm setPage={setPage} branch={branch} />
                        )
                }
                <div className={LoginPageStyles.cookies}>
                    <a href="tel:1900633584">
                        <Calling size={16} set="bold" style={{ verticalAlign: -2, marginRight: 3 }} /> Tổng đài hỗ trợ: 1900 633 584
                    </a>
                    <div></div>
                    <a href="mailto:contact@ghsv.vn">
                        <Message size={16} set="bold" style={{ verticalAlign: -2, marginRight: 3 }} /> Hoặc: contact@ghsv.vn
                    </a>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                        <a href="https://play.google.com/store/apps/details?id=com.vivoo.ghsv" target="_blank"><img src={ggplayIcon} /></a>
                        <div style={{ width: 8 }} />
                        <a href="https://apps.apple.com/us/app/giao-h%C3%A0ng-si%C3%AAu-vi%E1%BB%87t/id1602941379" target="_blank"><img src={appstoreIcon} style={{ height: 46 }} /></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginPage