import { Tooltip } from "@material-ui/core"
import { useContext } from "react"
import { User } from "react-iconly"
import { UserContext } from "../contexts/user.context"

export default function UserDetect({ id, style, fullSize }) {
    const { user, nested_account } = useContext(UserContext)
    const i = nested_account.map(v => v.id).indexOf(id)
    if (user.user_ref !== "0" || nested_account.length === 0)
        return null
    if (!fullSize)
        return (
            <Tooltip title={i > -1 ? nested_account[i].customer_shop_code : 'Tài khoản chính'}>
                <User set="bold" style={{
                    width: 16,
                    height: 16,
                    color: i > -1 ? nested_account[i].user_color : '#000',
                    ...style
                }} />
            </Tooltip>
        )
    return (
        <span style={{ color: i > -1 ? nested_account[i].user_color : '#000', ...style }}>
            <User set="broken" size={14} style={{ verticalAlign: -2 }} /> {i > -1 ? nested_account[i].customer_shop_code : 'Tài khoản chính'}
        </span>
    )
}