import { Box, FormControlLabel, IconButton, makeStyles, Switch, Tooltip, useTheme } from '@material-ui/core'
import { useContext, useEffect, useRef, useState } from 'react'
import { Maximize } from 'react-feather'
import { Buy, Calling, Chart, Chat, Document, Download, Graph, Swap, User, Work } from 'react-iconly'
import { NavLink } from 'react-router-dom'
import { UserContext } from '../contexts/user.context'
import MenuStyle from '../css/Menu.module.sass'

const RandomImg = () => {
    const [author, setAuthor] = useState('')
    const [activeSlide, setActiveSlide] = useState(false)
    const slideController = useRef(null)
    const [imgConfig, setImgConfig] = useState(() => {
        if (localStorage.getItem('imgConfig')) {
            try {
                return JSON.parse(localStorage.getItem('imgConfig'))
            } catch {
                console.log('Invalid localStorage.imgConfig')
            }
        }
        return {
            time: null,
            slides: [],
            current: 0
        }
    })

    useEffect(() => {
        if (imgConfig.slides.length) {
            setAuthor(<span>Bởi <u>{imgConfig.slides[imgConfig.current].user.name}</u> trên <u>Unsplash</u></span>)
            setActiveSlide(true)
        }
        // get new list every day
        if (new Date(imgConfig.time).getDate() !== new Date().getDate()) {
            fetch('https://api.unsplash.com/photos/random?query=colorful&client_id=zgepBowzsbkI53qLxNzrFXw-jnCCVvIaGpSfD4fZhE4&count=10')
                .then(r => r.json())
                .then(r => {
                    if (Array.isArray(r)) {
                        let newImgConfig = {
                            time: Date.now(),
                            slides: r,
                            current: 0
                        }
                        localStorage.setItem('imgConfig', JSON.stringify(newImgConfig))
                        new Image().src = newImgConfig.slides[1].urls.small
                        setImgConfig(newImgConfig)
                        setAuthor(<span>Bởi <u>{r[0].user.name}</u> trên <u>Unsplash</u></span>)
                        setActiveSlide(true)
                    }
                })
        }
    }, [])

    useEffect(() => {
        if (!activeSlide) return;
        let i = parseInt(imgConfig.current)
        if (slideController.current)
            clearInterval(slideController.current)
        slideController.current = setInterval(() => {
            if (i === 8)
                i = 0
            let newImgConfig = {
                ...imgConfig,
                current: i
            }
            new Image().src = imgConfig.slides[i + 1].urls.small
            localStorage.setItem('imgConfig', JSON.stringify(newImgConfig))
            setImgConfig(newImgConfig)
            setAuthor(<span>Bởi <u>{imgConfig.slides[i].user.name}</u> trên <u>Unsplash</u></span>)
            let imgs = document.querySelectorAll('#slide div[data="slide"]')
            imgs.forEach((el, j) => {
                el.style.opacity = 0
                if (i === j)
                    el.style.opacity = 1
            })
            i++
        }, 12000)
        return () => clearInterval(slideController.current)
    }, [activeSlide])

    const viewImg = () => {
    }

    return (
        <div className={MenuStyle.img} id="slide">
            {imgConfig.slides.map((v, i) =>
                <div key={i} data="slide" style={{ backgroundImage: `url(${v.urls.small})`, opacity: Number(imgConfig.current === i) }} />
            )}
            <Box position="absolute" right={-10} bottom={-10} zIndex={1}>
                <Tooltip title={author}>
                    <IconButton onClick={viewImg}>
                        <Maximize color="#aabac1" />
                    </IconButton>
                </Tooltip>
            </Box>
        </div>
    )
}

const IconMenu = ({ variant }) => {
    const classes = useStyles()
    let icon

    switch (variant) {
        case 'work':
            icon = <Work set="bold" />
            break
        case 'graph':
            icon = <Graph set="bold" />
            break
        case 'buy':
            icon = <Buy set="bold" />
            break
        case 'don_tao':
            icon = <Document set="bold" />
            break
        case 'download':
            icon = <Download set="bold" />
            break
        case 'user':
            icon = <User set="bold" />
            break
        case 'call':
            icon = <Calling set="bold" />
            break
        case 'analytics':
            icon = <Chart set="bold" />
            break
        case 'ticket':
            icon = <Chat set="bold" />
            break
        case 'settings':
            icon = <Swap set="bold" />
    }

    return (
        <div className={classes.icon}>
            {icon}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    ul: {
        '& a[class="active"]': {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            '& > div': {
                color: '#fff',
                backgroundColor: theme.palette.primary.main
            }
        },
    },
    icon: {
        background: '#f3f3f8',
        borderRadius: 9,
        height: 30,
        width: 30,
        marginRight: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#637c88',
        '& svg': {
            width: 14,
            height: 14
        }
    }
}))

const Menu = () => {
    const classes = useStyles()
    const { miniDesign, setMiniDesign, chi_nhanh, user } = useContext(UserContext)
    //const handleSwitch = () => setMiniDesign(!miniDesign)
    //const theme = useTheme()

    return (
        <div className={MenuStyle.desktop}>
            {!miniDesign ?
                (false ? <RandomImg /> :
                    <div className={MenuStyle.img} id="slide" style={{ boxShadow: '0px 0px 30px 2px rgb(187 187 187 / 30%)' }}>
                        <div data="slide" style={{ backgroundImage: `url(${chi_nhanh.logo ? 'https://demo.ghsv.vn/system/' + chi_nhanh.logo : 'https://demo.ghsv.vn/system/assets/logo/default.png'})` }} />
                    </div>)
                : <Box height={10} />}
            <div className={MenuStyle.wrapper}>
                <div className={MenuStyle.title}>MENU</div>
                <div className={`${MenuStyle.ul} ${classes.ul}`}>
                    <NavLink to="/finance"><IconMenu variant="work" /> Đối soát</NavLink>
                    <NavLink to="/orders"><IconMenu variant="buy" /> Đơn hàng </NavLink>
                    <NavLink exact to="/"><IconMenu variant="don_tao" />Đơn chờ duyệt</NavLink>
                    <NavLink to="/report"><IconMenu variant="graph" /> Báo cáo</NavLink>
                    {/* <a href="#!" onClick={comingSoon}><IconMenu variant="analytics" /> Phân tích</a> */}
                </div>
                <div className={MenuStyle.title} style={{ marginTop: 15 }}>PHỤ LỤC</div>
                <div className={`${MenuStyle.ul} ${classes.ul}`}>
                    {/* <NavLink exact to="/account/settings"><IconMenu variant="settings" /> Cài đặt</NavLink>
                    <NavLink exact to="/account"><IconMenu variant="user" />Tài khoản</NavLink> */}
                    {/* <NavLink to="/download"><IconMenu variant="download" /> Tải xuống</NavLink> */}
                    <a href="//developer.ghsv.vn" target="_blank"><IconMenu variant="settings" /> Tích hợp API</a>
                    {/* <NavLink to="/tickets"><IconMenu variant="ticket" /> Yêu cầu <span style={{ color: theme.palette.primary.main, fontWeight: 'bold', marginLeft: 5 }}>(3)</span></NavLink> */}
                    <NavLink to="/contact"><IconMenu variant="call" /> Hỗ trợ</NavLink>
                    {/* <a>
                        <FormControlLabel
                            value="end"
                            control={<Switch onClick={handleSwitch} checked={miniDesign} color="primary" />}
                            label={<Box color="#424242" fontSize={14}>Tối giản</Box>}
                            labelPlacement="end"
                        />
                    </a> */}
                    {!miniDesign &&
                        <Tooltip title={`Tài khoản trên App là: ${chi_nhanh.code+'.'+user.username}`}>
                            <a style={{ fontSize: 13 }}>

                                <div>
                                    Đã có mặt trên <a href="https://apps.apple.com/us/app/giao-h%C3%A0ng-si%C3%AAu-vi%E1%BB%87t/id1602941379" target="_blank">App Store</a> và <a href="https://play.google.com/store/apps/details?id=com.vivoo.ghsv" target="_blank">Google Play</a>.
                    </div>

                            </a>
                        </Tooltip>}
                </div>
            </div>
        </div>
    )
}

export default Menu