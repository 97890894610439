const color = {
    light: '#aabac1',
    primary: '#3da495',
    secondary: '#fe753e',
    secondary2: '#1976d2',
    svg: '#aabac1',
    lightText: '#79817A'
}

const phoneStatusStyle = {
    'AN TOÀN': {
        color: '#fff',
        background: '#4caf50'
    },
    'NGUY CƠ THẤP': {
        color: '#fff',
        background: '#ff9800'
    },
    'NGUY CƠ CAO': {
        color: '#fff',
        background: '#ff5722'
    },
    'NGUY HIỂM': {
        color: '#fff',
        background: 'red'
    }
}

export default color
export { phoneStatusStyle }