import { Box, CircularProgress, Drawer, IconButton, Typography } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import { Alert, AlertTitle } from "@material-ui/lab"
import { forwardRef, useContext, useImperativeHandle, useState } from "react"
import { Bell } from "react-feather"
import { CloseSquare, Discovery, Swap, TickSquare, Wallet } from "react-iconly"
import { Link } from "react-router-dom"
import { UserContext } from "../contexts/user.context"
import UserDetect from "./UserDetect"

const severity = {
    hoan_giao_hang: 'info',
    da_giao_hang: 'success',
    khong_giao_duoc: 'error',
    doi_soat: 'warning',
    thong_bao: 'info'
}

const msg_pre = {
    hoan_giao_hang: 'Hoãn giao đơn hàng',
    da_giao_hang: 'Đã giao đơn hàng',
    khong_giao_duoc: 'Không giao được đơn hàng',
    doi_soat: 'Đã đối soát đơn hàng',
    thong_bao: ''
}

const icon = {
    hoan_giao_hang: <Swap set="bold" size={20} />,
    da_giao_hang: <TickSquare set="bold" size={20} />,
    khong_giao_duoc: <CloseSquare set="bold" size={20} />,
    doi_soat: <Wallet set="bold" size={20} />,
    thong_bao: <Discovery color="#ed8909" set="bold" size={20} />,
}

const Notification = forwardRef((_, ref) => {
    const [loadMore, setLoadMore] = useState(false)
    const [open, setOpen] = useState(false)
    const [page, setPage] = useState(1)
    const [data, setData] = useState([])
    const [init, setInit] = useState(true)
    const [loading, setLoading] = useState(true)
    const { userFetch } = useContext(UserContext)

    useImperativeHandle(ref, () => ({
        open: handleOpen,
        getNew,
        is_open: open,
        init
    }))

    const getNew = async () => {
        const res = await userFetch('/models/app/query/notifications.php?act=new')
        const resj = await res.json()
        let newData = [...resj.data, ...data].map(v => JSON.stringify(v))
        newData = [...new Set(newData)]
        newData = newData.map(v => JSON.parse(v))
        setData(newData)
    }

    const getData = async (page = 1) => {
        setLoading(true)
        setPage(page)
        if (page > 1)
            await new Promise(res => setTimeout(() => res(true), 500))
        const res = await userFetch('/models/app/query/notifications.php?act=list&page=' + page)
        const resj = await res.json()
        if (resj.data.length < 35)
            setLoadMore(false)
        else
            setLoadMore(true)
        let newData = [...data, ...resj.data].map(v => JSON.stringify(v))
        newData = [...new Set(newData)]
        newData = newData.map(v => JSON.parse(v))
        setData(newData)
        setLoading(false)
    }

    const handleOpen = async () => {
        setOpen(true)
        await getData()
        userFetch('/models/app/query/notifications.php?act=read')
    }

    const handleClose = () => {
        setInit(false)
        setOpen(false)
        setTimeout(() => {
            setData([])
        }, 300)
    }

    return (
        <Drawer open={open} anchor="right" onClose={handleClose}>
            <Box width={350} maxWidth="100%">
                <Box display="flex" padding="0 16px" justifyContent="space-between" alignItems="center" height={64} position="sticky" top={0} bgcolor="#fff" zIndex={1}>
                    <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}><Bell fill="inherit" style={{ marginRight: 5 }} /> Thông Báo</Typography>
                    <IconButton type="button" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
                {
                    data.length ?
                        data.map(v =>
                            <Alert icon={icon[v.type]} severity={severity[v.type]} key={v.id} style={{ marginBottom: 2, position: 'relative', backgroundColor: v.type == 'thong_bao' ? '#fde2bf' : null }}>
                                {v.is_read === "0" && <div className="unread_notifications" />}
                                {msg_pre[v.type]}
                                <div>
                                    {v.type == 'thong_bao' ?
                                        <a href={v.msg} target="_blank" style={{ color: 'inherit' }}><b>{v.code}</b></a>
                                        :
                                        <Link onClick={handleClose} to={'/orders/?order_code=' + v.code + '&date_range=null'} style={{ color: 'inherit' }}><b>{v.code}</b></Link>
                                    }
                                </div>
                                <small>
                                    {v.type != 'thong_bao' ?
                                        <div>
                                            {Boolean(v.msg) && <i style={{ color: 'red' }}>Lý do: {v.msg}. </i>}
                                        </div> : null
                                    }
                                    <div>
                                        <UserDetect id={v.customer_id} style={{ marginRight: 2, verticalAlign: -3, marginTop: 3 }} /> {v.created_at}
                                    </div>
                                </small>
                            </Alert>
                        ) : !loading && (
                            <Alert severity="info">
                                <AlertTitle>Chưa có thông báo nào.</AlertTitle>
                            Những thông báo, cập nhật đơn hàng sẽ được lưu trữ tại đây, hãy đảm bảo bạn đã bật thông báo để có thể nhận thông báo nhanh nhất nhé.
                            </Alert>
                        )
                }
                {loadMore &&
                    <Box textAlign="center" paddingY={1}>
                        <span style={{ cursor: loading ? 'default' : 'pointer' }} onClick={() => getData(page + 1)}>{loading ? <>Đang lấy dữ liệu <CircularProgress color="inherit" size={14} /></> : 'Xem cũ hơn'}</span>
                    </Box>
                }
            </Box>
        </Drawer>
    )
})

export default Notification