import { Badge, Box, Button, CircularProgress, Collapse, FormControl, Grid, Hidden, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import { Autocomplete, Pagination } from "@material-ui/lab"
import { useSnackbar } from "notistack"
import { useContext, useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { ArrowDown, ChevronUp, Filter, PaperDownload, Show, TimeSquare, Wallet } from "react-iconly"
import BalanceEl from "../../components/BalanceEl"
import LoadingData from "../../components/LoadingData"
import MobilePaper from "../../components/MobilePaper"
import QuickView from "../../components/QuickView"
import color from "../../config/color"
import { UserContext } from "../../contexts/user.context"
import tableStyles from '../../css/Table.module.sass'

const FinanceItem = ({ v, isXs, index, onView }) => {
    const [download, setDownload] = useState(false)
    const { userFetch } = useContext(UserContext)

    const getLink = async () => {
        if (download)
            return
        setDownload(true)
        try {
            await new Promise(res => setTimeout(() => res(true), 500))
            await userFetch('/models/finance/export_xlsx.php?control_code=' + v.control_code)
                .then(r => r.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.style.zIndex = "-1"
                    a.style.visibility = "hidden"
                    a.download = v.control_code + '.xlsx'
                    document.body.appendChild(a)
                    a.click()
                    a.remove()
                })
        } catch (e) {
            console.log(e)
        }
        setDownload(false)
    }

    if (isXs)
        return (
            <MobilePaper variant="outlined" onClick={onView}>
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={0.5}>
                    <span style={{ fontWeight: 700, color: color.secondary }}>{v.control_code}</span>
                    <Box fontSize={14}>{v.created_at}</Box>
                </Box>
                <Box display="flex" justifyContent="space-between" fontSize={14}>
                    <Box>Tổng thu hộ - {v.tong_don.toLocaleString('en')} đơn:</Box>
                    <Box><span>{v.thu_ho.toLocaleString('en')} đ</span></Box>
                </Box>
                <Box display="flex" justifyContent="space-between" fontSize={14}>
                    <Box>Giảm trừ</Box>
                    <Box><span>{v.giam_tru.toLocaleString('en')} đ</span></Box>
                </Box>
                <Box display="flex" justifyContent="space-between" fontSize={14}>
                    <Box>Tổng phí</Box>
                    <Box><span>{v.tong_phi.toLocaleString('en')} đ</span></Box>
                </Box>
                <Box display="flex" justifyContent="space-between" fontSize={14} fontWeight={500}>
                    <Box>Thực nhận</Box>
                    <Box><span>{v.thuc_nhan.toLocaleString('en')} đ</span></Box>
                </Box>
                {v.paid_at &&
                    <Box marginTop={0.5} textAlign="center" fontSize="14px">
                        <Wallet set="bold" color={color.svg} style={{
                            width: 16,
                            height: 16,
                            verticalAlign: -3,
                            color: 'rgb(255, 193, 68)'
                        }} /> Thanh toán lúc: {v.paid_at}
                    </Box>
                }
            </MobilePaper>
        )

    return (<Box className={tableStyles.rowWrapper}>
        <Box color="#79817A">{index}</Box>
        <Box>
            <ul className={tableStyles.ul}>
                <li style={{ fontWeight: 500, fontSize: 16 }}>
                    <span style={{
                        color: color.secondary,
                        background: 'rgb(254 117 62 / 12%)',
                        padding: 5,
                        borderRadius: 5,
                        display: 'inline-block'
                    }}>
                        {v.control_code}
                    </span>
                </li>
                <li>
                    <Tooltip title="Ngày đối soát">
                        <Box marginTop={0.5}>
                            <TimeSquare set="broken" color={color.svg} style={{
                                width: 16,
                                height: 16,
                                verticalAlign: -3
                            }} /> Tạo lúc: {v.created_at}
                        </Box>
                    </Tooltip>
                </li>
                {v.paid_at &&
                    <li>
                        <Box marginTop={0.5}>
                            <Wallet set="bold" color={color.svg} style={{
                                width: 16,
                                height: 16,
                                verticalAlign: -3,
                                color: 'rgb(255, 193, 68)'
                            }} /> Thanh toán lúc: {v.paid_at}
                        </Box>
                    </li>}
            </ul>
        </Box>
        <Box>{v.tong_don.toLocaleString('en')}</Box>
        <Box fontWeight={500}>{v.thu_ho.toLocaleString('en')}đ</Box>
        <Box fontWeight={500}>{v.tong_phi.toLocaleString('en')}đ</Box>
        <Box fontWeight={500}>{v.giam_tru.toLocaleString('en')}đ</Box>
        <Box fontWeight="500">{v.thuc_nhan.toLocaleString('en')}đ</Box>
        <Box>
            <Tooltip title={download ? 'Đang tải' : 'Tải Excel'}>
                <IconButton onClick={getLink}>
                    {download ? <CircularProgress size={24} color="secondary" /> : <PaperDownload set="bold" color="#8bc34a" />}
                </IconButton>
            </Tooltip>
            {' '}<Tooltip title="Xem">
                <IconButton onClick={onView}>
                    <Show set="bold" color={color.svg} />
                </IconButton>
            </Tooltip>
        </Box>
    </Box>)
}

const TicketsRoute = () => {
    const [showFilter, setShowFilter] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [status, setStatus] = useState(null)
    const quitPage = useRef(null)
    const { userFetch } = useContext(UserContext)
    const [topics, setTopics] = useState(() => {
        try {
            if (localStorage.getItem('topics'))
                return JSON.parse(localStorage.getItem('topics'))
        } catch {
            return []
        }
    })
    const [topic, setTopic] = useState('')
    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = useState(1)
    const [crrPage, setCrrPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [init, setInit] = useState(true)
    const isXs = useMediaQuery(useTheme().breakpoints.only('xs'))
    const [
        order_code_ref,
        quickViewRef
    ] = [useRef(null), useRef(null)]

    const handleChangePage = (_, p) => setPage(p)

    const handleSearch = () => page === 1 ? getData() : setPage(1)

    const getTopics = async () => {
        let res = await userFetch('/models/tickets/topics.php')
        let resj = await res.json()
        setTopics(resj.data)
        localStorage.setItem('topics', JSON.stringify(resj.data))
    }

    const getData = async () => {
        try {
            quitPage.current = false
            setLoading(true)
            let startTime = Date.now()
            // let query = `/models/finance/query.php?page=${page}`
            // if (order_code_ref.current.value)
            //     query += `&order_code=${order_code_ref.current.value}`
            // let res = await userFetch(query)
            // let resj = await res.json()
            if (quitPage.current)
                return
            let endTime = Date.now()
            if (endTime - startTime < 500)
                await new Promise(res => setTimeout(() => { res(true) }, 500 - endTime + startTime))
            setInit(false)
            setCrrPage(page)
            // setData(resj.data)
            // setTotal(resj.total)
            setData([])
            setTotal(0)
            setLoading(false)
        } catch (e) {
            enqueueSnackbar('Có lỗi xảy ra', { variant: 'error' })
        }
    }

    const handleView = (control_code, don) => {
        let formData = new FormData()
        formData.append('control_code', control_code)
        quickViewRef.current.open({
            body: formData,
            url_filter: '/orders/?control_code=' + control_code + '&date_range=null#view',
            title: isXs ? control_code : (control_code + ' - ' + don + ' đơn')
        })
    }

    useEffect(() => {
        getTopics()
    }, [])

    useEffect(() => {
        getData()
        window.scroll({
            top: 0,
        })
        return () => {
            quitPage.current = true
        }
    }, [page])

    return (
        <>
            <Helmet>
                <title>Danh sách yêu cầu</title>
            </Helmet>
            <QuickView ref={quickViewRef} />
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                        <Box position="relative" flex={1}>
                            <Typography variant="h6" style={{ fontWeight: 700, display: 'flex', alignItems: 'center' }}>Danh sách yêu cầu {(loading && !init) && <CircularProgress size="1.35rem" style={{ color: color.svg, marginLeft: 12 }} />}</Typography>
                            {!init &&
                                <div className="result-searched-yellow">
                                    <b>{total.toLocaleString('en')}</b> đang xử lý
                                    <Box style={{ verticalAlign: 1 }} display="inline-block" width={8} height={8} bgcolor="green" marginX={1} />
                                    <span style={{ color: 'green' }}><b>17</b> đã xử lý</span>
                                </div>
                            }
                        </Box>
                        <BalanceEl els={[
                            <Tooltip title="Chọn trang">
                                <IconButton size={isXs ? 'small' : 'medium'} onClick={() => window.scrollTo({
                                    top: document.body.scrollHeight,
                                    behavior: 'smooth'
                                })}>
                                    <Badge badgeContent={page} anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}>
                                        <ArrowDown set="bold" color={color.svg} size={20} />
                                    </Badge>
                                </IconButton>
                            </Tooltip>,
                            <Box width={8} />,
                            isXs ? <IconButton size="small" onClick={() => setShowFilter(!showFilter)}><Filter set="bold" color={color.svg} /></IconButton> :
                                <Button onClick={() => setShowFilter(!showFilter)} variant="outlined" style={{ color: 'rgb(121, 129, 122)', borderColor: '#aabac1' }}><Filter size={20} set="bold" style={{ marginRight: 8 }} /> Lọc Danh Sách</Button>,
                        ]} />
                    </Box>
                    <Collapse in={showFilter} style={{ marginTop: isXs ? 8 : 0 }}>
                        <Box className={tableStyles.wrapperFilter}>
                            <Grid container alignItems="center">
                                <Grid item md="auto" xs={12}>
                                    <TextField size="small" fullWidth={isXs} inputRef={order_code_ref} variant="outlined" label="Mã đơn hàng" inputProps={{ style: { minWidth: 220 } }} />
                                </Grid>
                                <Grid item md="auto" xs={12}>
                                    <Autocomplete
                                        options={topics}
                                        getOptionLabel={topic => topic.title}
                                        onChange={(_, v) => setTopic(v)}
                                        openOnFocus
                                        blurOnSelect
                                        size="small"
                                        value={topic}
                                        style={{
                                            minWidth: 200
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Vấn đề" variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item md="auto" xs={12}>
                                    <FormControl fullWidth={isXs} size="small" variant="outlined" style={{ minWidth: 160 }}>
                                        <InputLabel>Trạng thái</InputLabel>
                                        <Select
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                            label="Trạng thái"
                                        >
                                            <MenuItem value=""><em>Không chọn</em></MenuItem>
                                            <MenuItem value="0">Đang Xử Lý</MenuItem>
                                            <MenuItem value={1}>Đã Xử Lý</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md="auto" xs={12}>
                                    <Button disabled={loading} fullWidth={isXs} onClick={handleSearch} color="secondary" variant="contained">Tra cứu</Button>
                                </Grid>
                                <Hidden xsDown>
                                    <Grid item style={{ flex: 1, textAlign: 'right' }}>
                                        <IconButton onClick={() => setShowFilter(false)}>
                                            <ChevronUp color={color.svg} />
                                        </IconButton>
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </Box>
                    </Collapse>
                    {!isXs ?
                        <>
                            <Box className={tableStyles.preTable} />
                            <Box marginTop="-34px" />
                        </> : <Box marginTop={showFilter ? 2 : 6} />}
                    {
                        init ?
                            <Box position="relative" zIndex={2}>
                                <LoadingData />
                            </Box>
                            :
                            total === 0 ?
                                <Box className="empty">
                                    <Typography variant="h6">Trống rỗng!</Typography>
                                    <Box color={color.light}>
                                        Bạn chưa có yêu cầu nào.
                                    </Box>
                                </Box>
                                :
                                <>
                                    {isXs ? data.map((v, i) => {
                                        let cal_index = (crrPage - 1) * 15 + (i + 1)
                                        return <FinanceItem onView={() => handleView(v.control_code, v.tong_don)} index={cal_index} isXs v={v} key={i} />
                                    })
                                        :
                                        <Box className={`${tableStyles.realTable} ${tableStyles.c8}`}>
                                            <Box className={tableStyles.heading} style={{ color: '#79817A' }}>STT</Box>
                                            <Box className={tableStyles.heading}>Mã đối soát</Box>
                                            <Box className={tableStyles.heading}>Tổng đơn</Box>
                                            <Box className={tableStyles.heading}>Tổng thu hộ</Box>
                                            <Box className={tableStyles.heading}>Tổng phí</Box>
                                            <Box className={tableStyles.heading}>Tổng giảm trừ</Box>
                                            <Box className={tableStyles.heading}>Thực nhận</Box>
                                            <Box className={tableStyles.heading} />
                                            {data.map((v, i) => {
                                                let cal_index = (crrPage - 1) * 15 + (i + 1)
                                                return <FinanceItem onView={() => handleView(v.control_code, v.tong_don)} index={cal_index} isXs={false} v={v} key={i} />
                                            })}
                                        </Box>
                                    }
                                    <div className="pageWrapper">
                                        <Pagination onChange={handleChangePage} page={page} color="primary" count={Math.ceil(total / 15)} shape="rounded" />
                                    </div>
                                </>
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default TicketsRoute