import { makeStyles, Slide } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import es from 'date-fns/locale/vi';
import { SnackbarProvider } from "notistack";
import { useContext } from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { UserContext, UserProvider } from "./contexts/user.context";
import './css/global.sass';
import AppIndex from "./routes";
import LoginPage from "./routes/login";


registerLocale('vi', es)
setDefaultLocale('vi')

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const useStyles = makeStyles(() => ({
  contentRoot: {
    minWidth: 200
  },
}));

function App() {
  const classes = useStyles();
  const { user, themeOption } = useContext(UserContext)

  return (
    <ThemeProvider theme={themeOption}>
      <SnackbarProvider classes={classes} maxSnack={user ? 3 : 1} anchorOrigin={{ horizontal: user ? 'left' : 'center', vertical: user ? 'bottom' : 'top' }} autoHideDuration={3000} TransitionComponent={user ? TransitionUp : TransitionDown} >
        <BrowserRouter basename="/v2">
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/recovery" component={() => <LoginPage path="recovery" />} />
            <Route exact path="/register" component={() => <LoginPage path="registration" />} />
            <Route path="/" component={AppIndex} />
          </Switch>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

const ThemedApp = () => {
  return (
    <UserProvider>
      <App />
    </UserProvider>
  )
}

export default ThemedApp;
