
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, TextField, Tooltip, useMediaQuery, useTheme } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useSnackbar } from 'notistack'
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { X } from 'react-feather'
import { Chat, PaperDownload, PaperUpload, Plus, Show } from 'react-iconly'
import { Link } from 'react-router-dom'
import color from '../config/color'
import { dev, socket } from '../config/config'
import { UserContext } from '../contexts/user.context'
import '../css/LiveChat.css'
const shero = dev ? 'https://ns.ghsv.vn' : window.location.origin //shero

export const Request_Dialog = forwardRef((_, ref) => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [orderCode, setOrderCode] = useState('')
    const { enqueueSnackbar } = useSnackbar()
    const [topics, setTopics] = useState(() => {
        try {
            if (localStorage.getItem('topics'))
                return JSON.parse(localStorage.getItem('topics'))
        } catch {
            return []
        }
    })
    const { userFetch, user } = useContext(UserContext)
    const [topic, setTopic] = useState({})

    const getTopics = async () => {
        let res = await userFetch('/models/tickets/topics.php')
        let resj = await res.json()
        setTopics(resj.data)
        localStorage.setItem('topics', JSON.stringify(resj.data))
    }

    useEffect(() => {
        getTopics()
        return () => {
            setMessage('')
            setOrderCode('')
        }
    }, [])

    useImperativeHandle(ref, () => ({
        open: (order_code = '') => {
            setOpen(true)
            setOrderCode(order_code)
        }
    }))

    const handleClose = () => {
        if (!loading)
            setOpen(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()
        formData.append('code', orderCode)
        formData.append('topic_id', topic.id)
        formData.append('msg', message)
        const res = await userFetch('/models/tickets/create.php', {
            method: 'POST',
            body: formData
        })
        const resj = await res.json()
        if (resj.response_code === 200) {
            socket.emit('create_msg', {
                url: shero,
                token: user.token,
                ticket: resj.data
            })
            setMessage('')
            setOrderCode('')
            setLoading(false)
            setOpen(false)
            enqueueSnackbar('Tạo yêu cầu thành công!', { variant: 'success' })
        } else
            enqueueSnackbar('Có lỗi xảy ra!', { variant: 'error' })
    }

    return (
        <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <form onSubmit={handleSubmit}>
                <DialogTitle>Tạo yêu cầu hỗ trợ</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        options={topics}
                        getOptionLabel={topic => topic.title}
                        onChange={(_, v) => setTopic(v)}
                        PaperComponent={(props) => <Paper {...props} style={{ background: '#f2f2f2', margin: '8px 0' }} />}
                        openOnFocus
                        blurOnSelect
                        size="small"
                        value={topic}
                        style={{
                            minWidth: 200
                        }}
                        renderInput={(params) => <TextField required color="secondary" autoFocus {...params} label="Vấn đề" variant="outlined" />}
                    />
                    <Box height={16} />
                    <TextField value={orderCode} onChange={e => setOrderCode(e.target.value)} helperText="Bỏ trống để tạo hội thoại." variant="outlined" size="small" label="Mã đơn hàng" fullWidth />
                    <Box height={8} />
                    <TextField
                        multiline
                        rows={5}
                        margin="dense"
                        required
                        label="Nhập lời nhắn"
                        fullWidth
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        color="secondary"
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={loading}>
                        Hủy
            </Button>
                    <Button type="submit" color="secondary">
                        {loading ? <CircularProgress color="secondary" size={24} /> : 'Xác nhận'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
})

const TicketPopup = ({ data, onToggle, onClose }) => {
    const [is_close, set_is_close] = useState(data.is_close)
    const [unread, setUnread] = useState(data.unread)
    const [upload, setUpload] = useState(false)
    const [chat, setChat] = useState([])
    const [msgValue, setMsgValue] = useState('')
    const [newMsg, setNewMsg] = useState(false)
    const [typingMsg, setTypingMsg] = useState(false)
    const typingController = useRef(null)
    const chatView = useRef(null)
    const inputRef = useRef(null)
    const token = useContext(UserContext).user.token
    const { userFetch } = useContext(UserContext)
    const [disconnect, setDisconnect] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const updatedTicket = async () => {
        let res = await userFetch('/models/tickets/secure.php?updated_ticket=' + data.id)
        let resj = await res.json()
        setUnread(resj.unread)
        set_is_close(resj.is_close)
        getDataChat()
    }

    const getDataChat = async (init) => {
        let res = await userFetch('/models/tickets/secure.php?view=' + data.id)
        let resj = await res.json()
        if (resj.data) {
            setChat(resj.data)
            if (chatView.current) {
                if (init)
                    return chatView.current.scrollTo({ top: chatView.current.scrollHeight })
                if (data.open && isEnd())
                    readNewMsg()
                else
                    setNewMsg(true)
            }
        }
    }

    const isEnd = () => {
        console.log(Math.ceil(chatView.current.offsetHeight + chatView.current.scrollTop))
        console.log(chatView.current.scrollHeight - 80)
        return Math.ceil(chatView.current.offsetHeight + chatView.current.scrollTop) >= chatView.current.scrollHeight - 80
    }

    const readNewMsg = () => chatView.current.scrollTo({ top: chatView.current.scrollHeight, behavior: 'smooth' })

    const handleSend = (e) => {
        e.preventDefault()
        if (msgValue.trim()) {
            socket.emit('send_msg', {
                url: shero,
                ticket: data.id,
                msg: msgValue,
                token
            })
            setMsgValue('')
        }
    }

    const handleChooseFile = async (e) => {
        console.log(e)
        let newFiles;
        ([...e.target.files]).forEach(el => {
            if (el.size < 1024 * 1024 * 5)
                newFiles = el
            else
                enqueueSnackbar('File không được vượt quá 5mb', { variant: 'warning' })
        })

        if (newFiles) {
            try {
                setUpload(true)
                let formData = new FormData()
                formData.append('file', newFiles)
                let res = await userFetch(`/models/tickets/upload.php`, {
                    method: 'POST',
                    body: formData
                })
                if (res.status == 200) {
                    let file = await res.text()
                    socket.emit('send_msg', {
                        url: shero,
                        ticket: data.id,
                        msg: '[FILE=' + file + ']',
                        token
                    })
                } else {
                    throw '';
                }
            } catch (e) {
                enqueueSnackbar('Có lỗi xảy ra!', { variant: 'error' })
            } finally {
                setUpload(false)
            }
        }
    }

    const handleType = (e) => {
        if (e.key === "Enter")
            return
        socket.emit('typing_msg', {
            url: shero,
            ticket: data.id
        })
    }

    useEffect(() => {
        const event_seen = () => {
            console.log('handle seen')
            // if (unread === 0)
            //     return
            socket.emit('seen_msg', {
                url: shero,
                ticket: data.id,
                token
            })
            setUnread(0)
        }

        const event_scroll = () => {
            if (isEnd())
                setNewMsg(false)
        }

        const event_connect = () => {
            console.log('join room', shero + '_' + data.id)
            socket.emit('join_room', shero + '_' + data.id)
            updatedTicket()
            setDisconnect(false)
        }

        const event_disconnect = (reason) => {
            console.log('disconnect', reason)
            setDisconnect(true)
            if (reason === "io server disconnect") {
                // the disconnection was initiated by the server, you need to reconnect manually
                socket.connect();
            }
        }

        const event_updated_ticket = async () => {
            await updatedTicket()
            if (inputRef.current === document.activeElement)
                event_seen()
        }

        const event_typing_msg = (ticket) => {
            if (ticket !== data.id)
                return
            setTypingMsg(true)
            if (typingController.current)
                clearTimeout(typingController.current)
            typingController.current = setTimeout(() => {
                setTypingMsg(false)
            }, 500)
        }

        getDataChat(true)
        event_connect()
        event_seen()
        inputRef.current.addEventListener('focus', event_seen)
        chatView.current.addEventListener('scroll', event_scroll)
        socket.on('connect', event_connect)
        socket.on("disconnect", event_disconnect);
        socket.on('updated_ticket', event_updated_ticket)
        socket.on('typing_msg', event_typing_msg)

        return () => {
            console.log('leave room', shero + '_' + data.id)
            chatView.current?.removeEventListener('scroll', event_scroll)
            inputRef.current?.removeEventListener('focus', event_seen)
            socket.off('connect', event_connect)
            socket.off('disconnect', event_disconnect)
            socket.off('updated_ticket', event_updated_ticket)
            socket.off('typing_msg', event_typing_msg)
            socket.emit('leave_room', shero + '_' + data.id)
        }
    }, [])

    return (
        <div className="ticket_active">
            <div className="ticket_title" style={data.open ? { background: '#f2f2f2' } : {}}>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                    <div onClick={onToggle} style={{ flex: 1, textAlign: 'left' }}>{data.code_supership}</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Link to={'/orders/?order_code=' + data.code_supership}>
                            <Show size={16} style={{ marginRight: 5 }} color={color.svg} set='bold' />
                        </Link>
                        <div className={is_close === "0" ? 'dotUndone' : 'dotDone'} />
                        {Number(unread) > 0 && <div className="msg-unread">{unread}</div>}
                    </div>
                </div>
                <div className="msg-close" onClick={onClose}><X size={20} /></div>
            </div>
            <div className="ticket_chat_wrapper" style={{ height: data.open ? '400px' : '0' }}>
                <div className="ticket_info">{data.title}</div>
                <div style={{ flex: 1, overflowY: 'scroll' }} ref={chatView}>
                    {
                        chat.map(v => {
                            if (v[0].user_type === 'staff') {
                                return (
                                    <div className="bubbleWrapper1" key={v[0].id}>
                                        <div className="ticket_sender">{v[0].name}</div>
                                        {
                                            v.map(msg => {
                                                const regex = /\[FILE=(.*)\.(.*)]$/gm;
                                                const result = regex.exec(msg.message)
                                                if (result) {
                                                    //[FILE=https://ns.ghsv.vn/v2/api/assets/ticket_files/ns.ghsv.vn/1_1650500439.jpeg]
                                                    if (['png', 'jpg', 'jpeg'].indexOf(result[2]) > -1) {
                                                        return <div onClick={() => window.open(result[1]+'.'+result[2])} className="bubble" key={msg.id}><img style={{ maxWidth: '100%', borderRadius: 8 }} src={result[1] + '.' + result[2]} alt="" /></div>
                                                    } else {
                                                        return <div className="bubble user2" key={msg.id}><a href={result[1] + '.' + result[2]} target="_blank"><PaperDownload size={22} style={{ verticalAlign: -5 }} /> Tệp tin {result[2]}</a></div>;
                                                    }
                                                }
                                                return (
                                                    <div className="bubble user1" key={msg.id}>{msg.message}</div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="bubbleWrapper2" key={v[0].id}>
                                        {v.map(msg => {
                                            const regex = /\[FILE=(.*)\.(.*)]$/gm;
                                            const result = regex.exec(msg.message)
                                            if (result) {
                                                //[FILE=https://ns.ghsv.vn/v2/api/assets/ticket_files/ns.ghsv.vn/1_1650500439.jpeg]
                                                if (['png', 'jpg', 'jpeg'].indexOf(result[2]) > -1) {
                                                    return <div onClick={() => window.open(result[1]+'.'+result[2])} className="bubble" key={msg.id}><img style={{ maxWidth: '100%', borderRadius: 8 }} src={result[1] + '.' + result[2]} alt="" /></div>
                                                } else {
                                                    return <div className="bubble user2" key={msg.id}><a href={result[1] + '.' + result[2]} target="_blank"><PaperDownload size={22} style={{ verticalAlign: -5 }} /> Tệp tin {result[2]}</a></div>;
                                                }
                                            }
                                            return (
                                                <div className="bubble user2" key={msg.id}>{msg.message}</div>
                                            )
                                        })}
                                    </div>
                                )
                            }
                        })
                    }
                </div>
                <form onSubmit={handleSend} style={{ padding: 5, position: 'relative', display: is_close === "1" ? 'none' : 'block' }}>
                    {typingMsg && <div className="ticket_typing_msg">cskh đang nhập...</div>}
                    {newMsg && <div className="ticket_new_msg" onClick={readNewMsg}>có tin nhắn mới</div>}
                    <div style={{ position: 'absolute', bottom: 2, right: 8, zIndex: 1 }}>
                        <input onChange={handleChooseFile} accept="image/png, image/jpg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf, application/txt" type="file" style={{ display: 'none' }} id="msg_file" />
                        <IconButton disabled={disconnect || upload} onClick={() => {
                            document.getElementById('msg_file').click()
                        }}>
                            {upload ? <CircularProgress size={24} /> : <PaperUpload color="#000" />}
                        </IconButton>
                    </div>
                    <TextField inputProps={{
                        style: {
                            paddingRight: 42
                        }
                    }} onKeyPress={handleType} disabled={disconnect} color="secondary" variant="outlined" size="small" fullWidth inputRef={inputRef} value={disconnect ? 'mất kết nối...' : msgValue} onChange={e => setMsgValue(e.target.value)} className="form-control" placeholder="Nhập tin nhắn, enter để gửi" />
                </form>
            </div>
        </div>)
}

const TicketWrapper = () => {
    const { user } = useContext(UserContext)
    const [data, setData] = useState([])
    const isXs = useMediaQuery(useTheme().breakpoints.only('xs'))
    const [unread, setUnread] = useState(0)
    const [showMsgs, setShowMsgs] = useState(false)
    const { userFetch } = useContext(UserContext)
    const requestRef = useRef(null)
    const [activeTicket, setActiveTicket] = useState(() => {
        try {
            if (sessionStorage.getItem('v2_ticket'))
                return JSON.parse(sessionStorage.getItem('v2_ticket'))
            throw 'error'
        } catch (_) {
            return []
        }
    }) // ticket
    const searchRef = useRef({})
    const searchController = useRef(null)

    const getUnread = async () => {
        let res = await userFetch('/models/tickets/secure.php?unread')
        let resj = await res.json()
        setUnread(resj.unread)
    }

    const getData = async (create = false) => {
        let res = await userFetch('/models/tickets/secure.php?get_all_tickets&searchValue=' + (searchRef.current.value ?? ''))
        let resj = await res.json()
        setData(resj.data)
        getUnread()
        if (create) {
            const _activeTicket = [...activeTicket]
            _activeTicket[0] = { ...resj.data[0], open: true }
            setActiveTicket(_activeTicket)
        }
    }

    const handleShowTickets = () => setShowMsgs(!showMsgs)

    const handleSearch = (e) => {
        searchRef.current.value = e.target.value
        if (searchController.current)
            clearTimeout(searchController.current)
        searchController.current = setTimeout(() => getData(), 500)
    }

    const handleActive = (ticket) => {
        ticket = { ...ticket, open: true }
        if (isXs)
            setActiveTicket([ticket])
        else {
            let index = activeTicket.map(v => v.id).indexOf(ticket.id)
            if (index > -1) {
                let cloneActiveTicket = [...activeTicket]
                cloneActiveTicket[index] = ticket
                setActiveTicket(cloneActiveTicket)
            } else {
                if (activeTicket.length < 3)
                    setActiveTicket([...activeTicket, ticket])
                else {
                    let cloneActiveTicket = [...activeTicket]
                    cloneActiveTicket[0] = ticket
                    setActiveTicket(cloneActiveTicket)
                }
            }
        }
        setShowMsgs(false)
    }

    const handleCloseTicket = (ticket) => {
        console.log('trigger')
        let index = activeTicket.map(v => v.id).indexOf(ticket.id)
        let cloneActiveTicket = [...activeTicket]
        cloneActiveTicket.splice(index, 1)
        setActiveTicket(cloneActiveTicket)
    }

    const handleToggle = (ticket) => {
        let index = activeTicket.map(v => v.id).indexOf(ticket.id)
        let cloneActiveTicket = [...activeTicket]
        cloneActiveTicket[index] = { ...ticket, open: !ticket.open }
        setActiveTicket(cloneActiveTicket)
    }

    useEffect(() => {
        sessionStorage.setItem('v2_ticket', JSON.stringify(activeTicket))
    }, [activeTicket])

    useEffect(() => {
        getData()

        socket.emit('join_room', shero + '__' + user.id)
        console.log('join_room', shero + '__' + user.id)
        socket.on('connect', () => {
            socket.emit('join_room', shero + '__' + user.id)
        })

        socket.on('create_msg', ticket => {
            getData(true)
        })

        socket.on('unread_msg', ping => {
            if (ping) {
                const alert = new Audio('https://demo.ghsv.vn/system/assets/js/ping.mp3')
                alert.play()
            }
            getData()
        })

        return (() => {
            socket.emit('leave_room', shero + '__' + user.id)
            console.log('leave_room', shero + '__' + user.id)
        })
    }, [user.id])

    return (
        <>
            <Request_Dialog ref={requestRef} />
            <div className="ticket_active_wrapper">
                {activeTicket.map(v => {
                    return <TicketPopup onClose={() => handleCloseTicket(v)} onToggle={() => handleToggle(v)} key={v.id} data={v} />
                })}
            </div>
            <div id="highest_ticket_container">
                <IconButton onClick={handleShowTickets} size={isXs ? 'small' : 'medium'} style={isXs ? { height: 40 } : {}}>
                    <Box position="relative" lineHeight={0}>
                        <Chat set="bold" color="#fff" fill="#fff" />
                    </Box>
                </IconButton>
                <div className={showMsgs ? 'active' : ''}>
                    <div className="ticket_item" style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField color="secondary" size="small" variant="outlined" fullWidth onChange={handleSearch} label="Nhập mã đơn cần tìm" />
                        <Tooltip title="Tạo yêu cầu mới">
                            <IconButton onClick={() => {
                                setShowMsgs(false)
                                requestRef.current.open()
                            }}>
                                <Plus />
                            </IconButton>
                        </Tooltip>
                    </div>
                    {
                        data.length ?
                            data.map(v => (
                                <div key={v.id} onClick={() => handleActive(v)} className="ticket_item">
                                    <b style={{ fontWeight: 500 }}>
                                        {v.code_supership}
                                        {Boolean(v.unread) && <span style={{ color: 'var(--msg)' }}> +{v.unread}</span>}
                                    </b>
                                    <small>
                                        <div>
                                            <div className={v.is_close === "0" ? 'dotUndone' : 'dotDone'} />
                                            {' '}{v.title}
                                        </div>
                                        <div className="ticket_handler">
                                            <span>{v.handler}</span>{v.time}
                                        </div>
                                    </small>
                                </div>
                            ))
                            :
                            <div className="ticket_item">Không tìm thấy yêu cầu hỗ trợ nào.</div>
                    }
                </div>
                {Boolean(unread) && !showMsgs && <div>{unread}</div>}
            </div>
        </>
    )
}

export default TicketWrapper