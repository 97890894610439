import {
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Tab,
  TablePagination,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import {
  CloudDownloadOutlined,
  LinkRounded,
  PrintOutlined,
} from "@material-ui/icons";
import {
  Autocomplete,
  Timeline,
  TimelineConnector as TimelineConnectorDefault,
  TimelineContent,
  TimelineDot as TimelineDotDefault,
  TimelineItem,
  TimelineSeparator,
} from "@material-ui/lab";
import Lottie from "lottie-react";
import { useSnackbar } from "notistack";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ReactDatePicker from "react-datepicker";
import { Maximize, X } from "react-feather";
import { Helmet } from "react-helmet";
import {
  ArrowDown,
  Bag,
  Call,
  Chat,
  ChevronUp,
  Filter,
  Location,
  Message,
  PaperDownload,
  Send,
  Show,
  TimeSquare,
} from "react-iconly";
import { useLocation } from "react-router-dom";
import loadingTracking from "../../assets/json/loading_tracking2.json";
import Ready_for_deliver from "../../assets/svg/ready_for_deliver.svg";
import SelectSvg from "../../assets/svg/select2.svg";
import BalanceEl from "../../components/BalanceEl";
import {
  default as Copy,
  default as CopyToClipboard,
} from "../../components/Copy";
import { Request_Dialog } from "../../components/LiveChat";
import LoadingData from "../../components/LoadingData";
import MobilePaper from "../../components/MobilePaper";
import UserDetect from "../../components/UserDetect";
import color from "../../config/color";
import { cities, dmY, sdmY } from "../../config/config";
import textTransform from "../../config/functions/text";
import orderModel from "../../config/order";
import { UserContext } from "../../contexts/user.context";
import tableStyles from "../../css/Table.module.sass";
import "../../css/uploadPhoto.css";

const COD_Dialog = forwardRef(({ onEdit }, ref) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(0);
  const [COD, setCOD] = useState(0);
  const [option, setOption] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { userFetch, chi_nhanh } = useContext(UserContext);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setData(data);
      setCOD(Number(data.collect).toLocaleString("en"));
      setOpen(true);
    },
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    let _COD = Number(COD.replace(/,/g, ""));
    if (_COD == data.collect) return handleClose();
    setLoading(true);
    let res = await userFetch(
      "/models/orders/edit.php?dvvc=" +
        data.DVVC +
        "&id=" +
        data.id +
        "&cod=" +
        _COD +
        "&option=" +
        option
    );
    let resj = await res.json();
    console.log(resj);
    if (resj.response_code === 200) {
      onEdit(resj.data);
      enqueueSnackbar("Sửa COD sang " + COD + "đ thành công!", {
        variant: "success",
      });
      handleClose();
    } else enqueueSnackbar("Có lỗi xảy ra!", { variant: "error" });
    setLoading(false);
  };

  const handleClose = () => {
    if (loading) return;
    setOpen(false);
  };

  const numberFormat = (e) => {
    if (e.target.value.trim()) {
      let newValue = Number(e.target.value.replace(/,/g, ""));
      if (!isNaN(newValue)) e.target.value = newValue.toLocaleString("en");
      else e.target.value = "";
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            required
            autoFocus
            margin="dense"
            label="Nhập COD mới"
            fullWidth
            value={COD}
            onChange={(e) => {
              numberFormat(e);
              setCOD(e.target.value);
            }}
            color="secondary"
            disabled={loading}
          />
          {data.DVVC === "VNC" && chi_nhanh.return_1p > 0 && (
            <>
              <Box height={4} />
              <FormControl fullWidth size="small">
                <InputLabel>Tùy chọn</InputLabel>
                <Select
                  value={option}
                  onChange={(e) => setOption(e.target.value)}
                  label="Tùy chọn"
                >
                  <MenuItem value="-1">
                    <em>Không chọn</em>
                  </MenuItem>
                  <MenuItem value="0">Chỉ sửa COD không giao một phần</MenuItem>
                  <MenuItem value="1">Sửa COD và giao một phần</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Hủy
          </Button>
          <Button type="submit" color="secondary">
            {loading ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              "Xác nhận"
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});

const MobileOrder = ({ data, onQV, onCB }) => {
  return (
    <MobilePaper variant="outlined">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <span style={{ fontWeight: 700 }}>
          <CopyToClipboard text={data.code_supership}>
            {data.code_supership}
          </CopyToClipboard>
        </span>
        <IconButton size="small" onClick={onQV}>
          <Show color={color.svg} set="bold" />
        </IconButton>
      </Box>
      {data.code_orders && (
        <div
          style={{
            overflowX: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "calc(100vw - 32px)",
            whiteSpace: "nowrap",
          }}
        >
          <CopyToClipboard text={data.code_orders}>
            MĐKH: {data.code_orders}
          </CopyToClipboard>
        </div>
      )}
      {data.required_code && data.required_code != data.code_supership && (
        <div
          style={{
            overflowX: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "calc(100vw - 32px)",
            whiteSpace: "nowrap",
          }}
        >
          <CopyToClipboard text={data.required_code}>
            MYC: {data.required_code}
          </CopyToClipboard>
        </div>
      )}
      <Box marginTop={1} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Bag
          set="bold"
          color={color.svg}
          size={16}
          style={{ marginRight: 2 }}
        />
        <div
          style={{
            overflowX: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "calc(100vw - 48px)",
            whiteSpace: "nowrap",
            fontWeight: 500,
          }}
        >
          {textTransform.ucfirst(data.product)}
        </div>
      </div>
      <Box fontSize="small">
        Nặng: {Number(data.mass).toLocaleString("en")}g
        {data.value == 0 ? (
          ""
        ) : (
          <div
            style={{
              paddingLeft: 4,
              margin: "0 4px",
              borderLeft: "1px solid " + color.svg,
              display: "inline-block",
            }}
          >
            Trị giá: {Number(data.value).toLocaleString("en")}đ
          </div>
        )}
        <div
          style={{
            color: color.secondary2,
            display: "inline-block",
            border: "1px solid " + color.svg,
            borderTop: "none",
            borderBottom: "none",
            padding: "0 6px",
            margin: "0 4px",
          }}
        >
          COD: {Number(data.collect).toLocaleString("en")}đ
        </div>{" "}
        <span style={{ color: color.secondary }}>
          Phí: {Number(data.fee).toLocaleString("en")}đ
        </span>
      </Box>
      <Box marginTop={1} />
      <CopyToClipboard text={data.phone}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <Send
            set="bold"
            color={color.svg}
            size={16}
            style={{ marginRight: 2 }}
          />
          <div
            style={{
              overflowX: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "calc(100vw - 48px)",
              whiteSpace: "nowrap",
              fontWeight: 500,
            }}
          >
            <span style={{ textTransform: "capitalize" }}>{data.receiver}</span>
            , {textTransform.mobile(data.phone)}
          </div>
        </div>
      </CopyToClipboard>
      <Box fontSize="small">
        {data.district}, {data.city}
      </Box>
      <Box fontSize="small" marginTop={1}>
        * {textTransform.ucfirst(data.note) || "Không có ghi chú"}
        {data.dh_mot_phan === "1" && (
          <>
            ,{" "}
            <Box display="inline-block" color="#f11042" fontWeight={500}>
              Đơn hàng một phần
            </Box>
          </>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div
          className={tableStyles.status}
          style={{
            background: data.background,
            color: data.text,
            fontWeight: data.fontWeight,
            fontSize: 13,
          }}
        >
          {data.status}
        </div>
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <Box fontSize={12}>{sdmY(data.date_create)}</Box>
          <UserDetect id={data.id_customer} style={{ marginLeft: 8 }} />
          <Checkbox
            checked={data.checked}
            onClick={onCB}
            size="small"
            style={{ marginRight: -4 }}
          />
        </div>
      </Box>
    </MobilePaper>
  );
};

const status = [
  "Đã Nhập Kho",
  "Đang Chuyển Kho Giao",
  "Đã Chuyển Kho Giao",
  "Đang Giao Hàng",
  "Hoãn Giao Hàng",
  "Không Giao Được",
  "Đã Đối Soát Giao Hàng",
  "Đã Đối Soát Trả Hàng",
  "Đang Chuyển Kho Trả",
  "Đã Chuyển Kho Trả Toàn Bộ",
  "Đã Chuyển Kho Trả Một Phần",
  "Đang Trả Hàng",
  "Đã Trả Hàng Toàn Bộ",
  "Đã Trả Hàng Một Phần",
  "Hoãn Trả Hàng",
  "Đang Vận Chuyển",
  "Xác Nhận Hoàn",
  "Đang Trả Hàng Toàn Bộ",
  "Đang Trả Hàng Một Phần",
  "Đã Giao Hàng",
];
//const barters = ["Thành Phố Hải Dương", "Nội Tỉnh Huyện Gần", "Quận Phường Nội Miền", "Huyện Nội Miền", "Quận Phường Liên Miền", "Huyện Liên Miền", "Nội Tỉnh Huyện Xa"];

const TimelineDot = withStyles({
  defaultGrey: {
    background: color.svg,
  },
})(TimelineDotDefault);

const TimelineConnector = withStyles({
  root: {
    background: color.svg,
  },
})(TimelineConnectorDefault);

const TimelineDotActive = withStyles({
  defaultGrey: {
    background: "#f44336",
    display: "block",
    position: "relative",
    "&::after": {
      position: "absolute",
      top: -1,
      left: -1,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1s infinite ease-in-out",
      border: "1px solid #f44336",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
})(TimelineDot);

const StyledTimeline = withStyles({
  root: {
    paddingTop: 0,
    paddingLeft: 5,
  },
})(Timeline);

const StyledTimelineItem = withStyles({
  missingOppositeContent: {
    "&:before": {
      content: "unset",
    },
  },
})(TimelineItem);

const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    height: 3,
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    marginTop: 4,
    textAlign: "left!important",
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    minWidth: 0,
    padding: 0,
    marginRight: 32,
    paddingBottom: 16,
    color: "#79817A",
    "&:hover": {
      opacity: 1,
    },
    "&$selected": {
      color: "#212121",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#212121",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const RenderTab = ({ tab, data }) => {
  const { userFetch, pVRef } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [tracking, setTracking] = useState([]);
  const [loading, setLoading] = useState(true);
  const lottieRef = useRef();

  const getTracking = () => {
    setLoading(true);
    setTracking([]);
    let startTime = Date.now();
    let code = data.code_supership;
    userFetch(
      "/models/orders/tracking.php?dvvc=" + data.DVVC + "&code=" + code,
      {
        method: "POST",
      }
    )
      .then((r) => r.json())
      .then(async (r) => {
        let endTime = Date.now();
        if (endTime - startTime < 500)
          await new Promise((res) =>
            setTimeout(() => {
              res(true);
            }, 1000)
          );
        if (r.response_code !== 200) {
          setTracking([]);
          enqueueSnackbar(r.msg, { variant: "error" });
          return;
        }
        document
          .getElementById("tabWrapper")
          ?.scroll({ top: 0, behavior: "smooth" });
        setTracking(r.tracking);
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar("Có lỗi xảy ra!", { variant: "error" });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (tab === 0 || !loading) return;
    getTracking();
  }, [tab]);

  useEffect(() => {
    if (tab === 1) getTracking();
    else setLoading(true);
  }, [data.id]);

  useEffect(() => {
    lottieRef.current?.setSpeed(3);
  });

  if (tab === 0)
    return (
      <Box paddingRight={0.75}>
        {data.note && (
          <div className={tableStyles.note_gh}>
            {textTransform.ucfirst(data.note)}
          </div>
        )}
        {data.photos_url && (
          <div
            className="uploadContainer"
            style={{ marginBottom: 8, marginTop: 8 }}
          >
            {data.photos_url.split(",").map((e, i) => (
              <div
                key={i}
                onClick={() => {
                  pVRef.current.open(i, data.photos_url.split(","));
                }}
                style={{ backgroundImage: `url(${e})` }}
              />
            ))}
          </div>
        )}
        <div className={tableStyles.info}>
          <div>Trạng thái :</div>
          <div
            style={{
              color: data.text,
              fontWeight: 500,
              textTransform: "capitalize",
            }}
          >
            {data.status}
          </div>
          <div>Duyệt lúc :</div>
          <div>{data.date_create}</div>
          {data.date_success && (
            <>
              <div>Giao lúc :</div>
              <div>{data.date_success}</div>
            </>
          )}
          {data.control_code && (
            <>
              <div>Mã đối soát :</div>
              <div>
                <CopyToClipboard text={data.control_code}>
                  {data.control_code}
                </CopyToClipboard>
              </div>
            </>
          )}
          {data.code_orders && (
            <>
              <div>Mã đơn KH :</div>
              <div>
                <CopyToClipboard text={data.code_orders}>
                  {data.code_orders}
                </CopyToClipboard>
              </div>
            </>
          )}
          {data.required_code && data.required_code != data.code_supership && (
            <>
              <div>Mã yêu cầu :</div>
              <div>
                <CopyToClipboard text={data.required_code}>
                  {data.required_code}
                </CopyToClipboard>
              </div>
            </>
          )}
        </div>
        <div className={tableStyles.info}>
          <div>Sản phẩm :</div>
          <div>
            {textTransform.ucfirst(data.product)}
            {data.dh_mot_phan === "1" && (
              <div style={{ color: "#f11042", fontWeight: 500 }}>
                ( Đơn hàng một phần )
              </div>
            )}
          </div>
          <div>Khối lượng :</div>
          <div>{Number(data.mass).toLocaleString("en")} gram</div>
          {data.value == 0 ? (
            ""
          ) : (
            <>
              <div>Trị giá :</div>
              <div>{Number(data.value).toLocaleString("en")} đ</div>
            </>
          )}
          <div>Tiền thu hộ :</div>
          <div style={{ color: color.secondary2 }}>
            {Number(data.collect).toLocaleString("en")} đ
          </div>
          <div>Phí dịch vụ :</div>
          <div style={{ color: color.secondary }}>
            {Number(data.fee).toLocaleString("en")} đ
          </div>
        </div>
        <div className={tableStyles.info}>
          <div>Người nhận :</div>
          <div style={{ textTransform: "capitalize" }}>{data.receiver}</div>
          <div>Số điện thoại :</div>
          <div>
            <Tooltip title="Gọi">
              <IconButton
                size="small"
                onClick={() => (window.location.href = "tel:" + data.phone)}
              >
                <Call set="bold" color={color.light} size={16} />
              </IconButton>
            </Tooltip>
            <Tooltip title="SMS">
              <IconButton
                size="small"
                onClick={() => (window.location.href = "sms:" + data.phone)}
              >
                <Message set="bold" color={color.light} size={16} />
              </IconButton>
            </Tooltip>
            <CopyToClipboard text={data.phone}>
              {textTransform.mobile(data.phone)}
            </CopyToClipboard>
          </div>
          {data.sphone && (
            <>
              <div>Điện thoại phụ :</div>
              <div>
                <CopyToClipboard text={data.sphone}>
                  {textTransform.mobile(data.sphone)}
                </CopyToClipboard>
              </div>
            </>
          )}
          <div>Địa chỉ :</div>
          <div style={{ whiteSpace: "normal" }}>
            {textTransform.ucfirst(data.address)}
          </div>
          <div>
            <Tooltip title="Tỉnh / Huyện / Xã">
              <span>T/H/X :</span>
            </Tooltip>
          </div>
          <div>
            {data.city} / {data.district} / {data.ward}
          </div>
        </div>
        <div className={tableStyles.info}>
          <div>Gửi từ :</div>
          <div style={{ textTransform: "capitalize" }}>{data.name_send}</div>
          <div>Số điện thoại :</div>
          <div>
            <Tooltip title="Gọi">
              <IconButton
                size="small"
                onClick={() =>
                  (window.location.href = "tel:" + data.phone_send)
                }
              >
                <Call set="bold" color={color.light} size={16} />
              </IconButton>
            </Tooltip>
            <Tooltip title="SMS">
              <IconButton
                size="small"
                onClick={() =>
                  (window.location.href = "sms:" + data.phone_send)
                }
              >
                <Message set="bold" color={color.light} size={16} />
              </IconButton>
            </Tooltip>
            <CopyToClipboard text={data.phone_send}>
              {textTransform.mobile(data.phone_send)}
            </CopyToClipboard>
          </div>
          <div>Địa chỉ :</div>
          <div style={{ whiteSpace: "normal" }}>
            {textTransform.ucfirst(data.address_send)}
          </div>
          <div>
            <Tooltip title="Tỉnh / Huyện / Xã">
              <span>T/H/X :</span>
            </Tooltip>
          </div>
          <div>
            {data.city_send} / {data.district_send} / {data.ward_send}
          </div>
        </div>
        <div
          style={{
            marginBottom: 32,
            marginTop: 8,
            whiteSpace: "pre-line",
            display: "block",
            color: "#79817A",
            fontSize: "small",
          }}
        >
          {!data.note_public
            ? "Không có ghi chú nào."
            : data.note_public
                .trim()
                .split("\n")
                .map((v, i) => {
                  return (
                    <div style={{ marginTop: 8 }} key={i}>
                      {v.trim()}
                    </div>
                  );
                })}
        </div>
      </Box>
    );
  if (loading)
    return <Lottie lottieRef={lottieRef} animationData={loadingTracking} />;
  if (tracking.length === 0 && !loading)
    return (
      <Fade in>
        <Box textAlign="center">
          <img src={Ready_for_deliver} className={tableStyles.select} alt="" />
          <Box color={color.svg}>Đơn hàng này chưa có hành trình.</Box>
        </Box>
      </Fade>
    );
  return (
    <StyledTimeline>
      {tracking.map((v, i) => {
        if (i === 0)
          return (
            <StyledTimelineItem key={i}>
              <TimelineSeparator>
                <TimelineDotActive />
                <TimelineConnector style={{ background: "#f44336" }} />
              </TimelineSeparator>
              <TimelineContent>
                <Box style={{ color: "#f44336", fontWeight: 500 }}>
                  {v.status}
                </Box>
                <Box className={tableStyles.hint}>
                  <BalanceEl
                    els={[
                      <TimeSquare color={color.svg} set="broken" />,
                      v.time,
                    ]}
                  />
                  {v.note && (
                    <BalanceEl
                      els={[
                        <Chat color={color.svg} set="broken" />,
                        textTransform.ucfirst(v.note),
                      ]}
                      style={{ alignItems: "start" }}
                    />
                  )}
                  {v.address && (
                    <Box>
                      <BalanceEl
                        els={[
                          <Location color={color.svg} set="broken" />,
                          `${textTransform.ucfirst(v.address)}`,
                        ]}
                      />
                    </Box>
                  )}
                </Box>
              </TimelineContent>
            </StyledTimelineItem>
          );
        return (
          <StyledTimelineItem key={i}>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              {v.status}
              <Box className={tableStyles.hint}>
                <BalanceEl
                  els={[<TimeSquare color={color.svg} set="broken" />, v.time]}
                />
                {v.note && (
                  <BalanceEl
                    els={[
                      <Chat color={color.svg} set="broken" />,
                      textTransform.ucfirst(v.note),
                    ]}
                    style={{ alignItems: "start" }}
                  />
                )}
                {v.address && (
                  <Box>
                    <BalanceEl
                      els={[
                        <Location color={color.svg} set="broken" />,
                        `${textTransform.ucfirst(v.address)}`,
                      ]}
                    />
                  </Box>
                )}
              </Box>
            </TimelineContent>
          </StyledTimelineItem>
        );
      })}
    </StyledTimeline>
  );
};

const QuickView = ({ data, closeDrawer, COD_Dialog_ref }) => {
  const [tab, setTab] = useState(0);
  const isXs = useMediaQuery(useTheme().breakpoints.only("xs"));
  const [disabledCOD, setDisabledCOD] = useState(false);
  const request_dialog_ref = useRef(null);

  useEffect(() => {
    if (!data) return;
    const partner_cod = ["GHN", "NB", "VNC", "GHTK", "SNP", "JNT", "VT"];
    if (partner_cod.indexOf(data.DVVC) > -1) {
      if (!data.date_success && data.status_over == 0) {
        setDisabledCOD(false);
        return;
      }
    }
    setDisabledCOD(true);
  }, [data]);

  if (data)
    return (
      <>
        <Request_Dialog ref={request_dialog_ref} />
        <div
          className={tableStyles.quickView}
          style={
            isXs
              ? {
                  padding: 16,
                  height: "100%",
                  width: "100vw",
                  overflow: "hidden",
                }
              : {}
          }
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
            overflow="hidden"
          >
            <CopyToClipboard text={data.code_supership}>
              <Typography
                variant={isXs ? "h7" : "h6"}
                style={{ fontWeight: 700 }}
              >
                #{data.code_supership}
              </Typography>
            </CopyToClipboard>
            <IconButton disabled={!isXs} onClick={closeDrawer}>
              <X color={isXs ? color.svg : "transparent"} />
            </IconButton>
          </Box>
          <AntTabs value={tab} onChange={(_, v) => setTab(v)}>
            <AntTab label="Thông tin chi tiết" />
            <AntTab label="Hành trình" />
          </AntTabs>
          <Box height={16} />
          <div className={tableStyles.tabWrapper} id="tabWrapper">
            <RenderTab data={data} tab={tab} />
          </div>
          <Box className={tableStyles.sticky}>
            <Box className={tableStyles.copy} display="flex">
              <Copy
                msg="Đã sao chép link hành trình"
                text={`https://giaohangsieuviet.com/tracking?code=${data.required_code}`}
              >
                <Tooltip title="Sao chép link hành trình">
                  <Button variant="outlined" color="primary">
                    <LinkRounded />
                  </Button>
                </Tooltip>
              </Copy>
              <Box width={16} />
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() =>
                  request_dialog_ref.current.open(data.code_supership)
                }
              >
                Gửi yêu cầu
              </Button>
              <Box width={16} />
              <Button
                variant="contained"
                fullWidth
                color="secondary"
                disabled={disabledCOD}
                onClick={() => COD_Dialog_ref.current.open(data)}
              >
                Sửa COD
              </Button>
            </Box>
          </Box>
        </div>
      </>
    );

  return (
    <div className={tableStyles.quickView}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          Chi Tiết Đơn Hàng
        </Typography>
        <IconButton disabled>
          <Maximize color="transparent" />
        </IconButton>
      </Box>
      <Box textAlign="center" flex={1}>
        <img src={SelectSvg} className={tableStyles.select} alt="" />
        <Box color={color.svg}>
          Nhấn{" "}
          <IconButton size="small" style={{ background: "#eee" }} disabled>
            <Show color={color.svg} set="bold" />
          </IconButton>{" "}
          vào đơn hàng cần xem.
        </Box>
      </Box>
      <Box className={tableStyles.sticky}>
        <Box className={tableStyles.copy} display="flex">
          <Button
            variant="contained"
            fullWidth
            color={color.secondary}
            disabled
          >
            Gửi yêu cầu
          </Button>
          <Box width={8} />
          <Button variant="contained" fullWidth color="secondary" disabled>
            Sửa COD
          </Button>
        </Box>
      </Box>
    </div>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
      width: 250,
    },
    variant: "outlined",
  },
  variant: "outlined",
  getContentAnchorEl: null,
};

const OrdersRoute = () => {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const params = new URLSearchParams(location.search);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [init, setInit] = useState(true);
  const [barter, setBarter] = useState(null);
  const [showFilter, setShowFilter] = useState([...params].length !== 0);
  const [downloadExcel, setDownloadExcel] = useState(false);
  const [downloadExcelChecked, setDownloadExcelChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { userFetch } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [forceReload, setForceReload] = useState(0);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [rowsPerpage, setRowsPerPage] = useState(35);
  const [activeOrder, setActiveOrder] = useState(null);
  const quitPage = useRef(null);
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState(null);
  const [districts, setDistricts] = useState([]);
  const COD_Dialog_ref = useRef(null);
  const [dateRangeOld, setDateRangeOld] = useState(() => {
    if (params.get("date_range")) {
      let [start_date, end_date] = params.get("date_range").split("-");
      if (start_date && end_date) {
        let _start_date = start_date.split("/");
        let _end_date = end_date.split("/");
        if (_start_date.length === 3 && _end_date.length === 3) {
          start_date =
            _start_date[1] + " " + _start_date[0] + " " + _start_date[2];
          end_date = _end_date[1] + " " + _end_date[0] + " " + _end_date[2];
          if (
            !(
              new Date(start_date).toString() ===
                new Date(end_date).toString() &&
              new Date(start_date) === "Invalid Date"
            )
          ) {
            if (new Date(end_date) >= new Date(start_date))
              return [new Date(start_date), new Date(end_date)];
          }
        }
      }
      return [null, null];
    }
    return [new Date() - 86400 * 30 * 1000, new Date()];
  });
  const [dateRange, setDateRange] = useState(() => {
    if (params.get("date_range")) {
      let [start_date, end_date] = params.get("date_range").split("-");
      if (start_date && end_date) {
        let _start_date = start_date.split("/");
        let _end_date = end_date.split("/");
        if (_start_date.length === 3 && _end_date.length === 3) {
          start_date =
            _start_date[1] + " " + _start_date[0] + " " + _start_date[2];
          end_date = _end_date[1] + " " + _end_date[0] + " " + _end_date[2];
          if (
            !(
              new Date(start_date).toString() ===
                new Date(end_date).toString() &&
              new Date(start_date) === "Invalid Date"
            )
          ) {
            if (new Date(end_date) >= new Date(start_date))
              return [new Date(start_date), new Date(end_date)];
          }
        }
      }
      return [null, null];
    }
    return [new Date() - 86400 * 30 * 1000, new Date()];
  });
  const isXs = useMediaQuery(useTheme().breakpoints.only("xs"));
  const isLg = useMediaQuery(useTheme().breakpoints.only("lg"));
  const [order_code, set_order_code] = useState(params.get("order_code") ?? "");
  const [control_code, set_control_code] = useState(
    params.get("control_code") ?? ""
  );
  const [range_date_ref, order_code_ref] = [useRef(null), useRef(null)];
  const [statusName, setStatusName] = useState(() => {
    if (params.get("status")) {
      console.log(params.get("status"));
      console.log(status.indexOf(params.get("status")));
      if (status.indexOf(params.get("status")) > -1)
        return [params.get("status")];
    }
    return [];
  });

  const handleChangeBarter = (e) => setBarter(e.target.value);
  const handleChangeStatus = (_, newValue) => {
    setStatusName(newValue);
  };

  const editCOD = (newData) => {
    let newOrder = {
      ...activeOrder,
      ...newData,
    };
    setActiveOrder(newOrder);
    let id = data.map((v) => v.id).indexOf(activeOrder.id);
    let _data = [...data];
    _data[id] = newOrder;
    setData(_data);
  };

  const importSuccess = () =>
    setStatusName([
      "Đã Đối Soát Giao Hàng",
      "Đã Chuyển Kho Trả Một Phần",
      "Đã Trả Hàng Một Phần",
      "Đang Trả Hàng Một Phần",
      "Đã Giao Hàng",
    ]);
  const importFailed = () => setStatusName(orderModel.status_failed);
  const handleChangePage = (_, p) => {
    setPage(p);
  };

  const handleSearch = () => (page === 1 ? getData() : setPage(1));

  const handleQV = (order) => {
    setActiveOrder(order);
    setDrawerOpen(true);
  };

  const handleChangeCity = async (_, newValue) => {
    try {
      if (!newValue) {
        setCity("");
        setDistricts([]);
        setDistrict(null);
        return;
      }
      let res = await userFetch(
        `/models/app/query/address.php?act=get_district_by_city&city=${newValue}`
      );
      let resj = await res.json();
      setDistricts(resj.data);
      setCity(newValue);
    } catch (e) {
      console.log(e);
    }
  };

  const getData = async (fromURL = false) => {
    try {
      quitPage.current = false;
      setLoading(true);

      let formData = new FormData();
      district && formData.append("district", district);
      city && formData.append("province", city);
      order_code?.trim() && formData.append("order_code", order_code);
      barter && formData.append("barter", barter);
      statusName.length && formData.append("status", statusName);
      control_code.trim() && formData.append("control_code", control_code);
      let [date_start, date_end] = range_date_ref.current.value.split("-");
      if (date_start && date_end) {
        formData.append("date_start", date_start);
        formData.append("date_end", date_end);
      }

      let startLoad = Date.now();

      const res = await userFetch(
        "/models/orders/query.php?kmess=" + rowsPerpage + "&page=" + page,
        {
          method: "POST",
          body: formData,
        }
      );

      const resj = await res.json();

      let endLoad = Date.now();
      if (endLoad - startLoad < 500)
        await new Promise((res) => setTimeout(() => res(true), 500));

      if (quitPage.current) return;
      //if (!init)
      window.history.replaceState(null, null, window.location.pathname);
      setInit(false);
      setDateRangeOld(dateRange);
      setLoading(false);
      setTotal(resj.total);
      setData(
        resj.orders.map((v) => {
          v.checked = false;
          v.background = orderModel.status_handling_color.background;
          v.text = orderModel.status_handling_color.text;
          if (v.status === "Không Giao Được") {
            v.background = "rgb(234, 194, 194)";
            v.text = "rgb(222, 55, 55)";
          } else if (orderModel.status_success.indexOf(v.status) > -1) {
            v.background = orderModel.status_success_color.background;
            v.text = orderModel.status_success_color.text;
          } else if (orderModel.status_failed.indexOf(v.status) > -1) {
            v.background = orderModel.status_failed_color.background;
            v.text = orderModel.status_failed_color.text;
          }
          v.status = textTransform.ucfirst(v.status.toLowerCase());
          return v;
        })
      );
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Có lỗi xảy ra!", {
        variant: "error",
      });
    }
  };

  const handleDownloadExcel = async () => {
    if (downloadExcel) return;
    setDownloadExcel(true);

    let formData = new FormData();
    district && formData.append("district", district);
    city && formData.append("province", city);
    order_code?.trim() && formData.append("order_code", order_code);
    barter && formData.append("barter", barter);
    statusName.length && formData.append("status", statusName);
    control_code.trim() && formData.append("control_code", control_code);
    let [date_start, date_end] = range_date_ref.current.value.split("-");
    if (date_start && date_end) {
      formData.append("date_start", date_start);
      formData.append("date_end", date_end);
    }

    await userFetch("/models/orders/query.php?export_excel", {
      method: "POST",
      body: formData,
    })
      .then((r) => r.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.style.zIndex = "-1";
        a.style.visibility = "hidden";
        a.download = "ĐƠN_HÀNG.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    setDownloadExcel(false);
  };

  const handleCheck = (i) => {
    let cloneData = [...data];
    cloneData[i] = { ...cloneData[i], checked: !cloneData[i].checked };
    setData(cloneData);
  };

  const handleCheckAll = () => {
    let checked = data.filter((o) => o.checked === true).length;
    let cloneData;
    if (checked === data.length)
      cloneData = [...data].map((v) => {
        v.checked = false;
        return v;
      });
    else
      cloneData = [...data].map((v) => {
        v.checked = true;
        return v;
      });
    setData(cloneData);
  };

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    if ([...params].length > 0) setShowFilter(true);
    set_order_code(params.get("order_code"));
    console.log("handle get data");
    setForceReload(forceReload + 1);
  }, [location.search]);

  useEffect(() => {
    getData();
    window.scroll({
      top: 0,
    });
  }, [page, forceReload, rowsPerpage]);

  var checkeds = data.filter((el) => el.checked).length;

  const handleUncheck = () => {
    let cloneData = data.map((el) => ({ ...el, checked: false }));
    setData(cloneData);
  };

  const handlePrint = (type) => {
    let ids = data
      .filter((el) => el.checked)
      .map((el) => el.id)
      .join(",");
    window.open(
      "/khachhang/api/create_order/print?fromOrder&type=" +
        type +
        "&list_id=" +
        ids,
      "_blank"
    );
  };

  const handleDownloadExcelChecked = async () => {
    setDownloadExcelChecked(true);
    let formData = new FormData();
    let codes = data
      .filter((el) => el.checked)
      .map((el) => el.code_supership)
      .join("\n");
    formData.append("order_code", codes);
    await userFetch("/models/orders/query.php?export_excel", {
      method: "POST",
      body: formData,
    })
      .then((r) => r.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.style.zIndex = "-1";
        a.style.visibility = "hidden";
        a.download = "ĐƠN_HÀNG_ĐÃ_CHỌN.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    setDownloadExcelChecked(false);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Helmet defer={false}>
        <title>Quản lý đơn hàng</title>
      </Helmet>

      <Grid container spacing={0}>
        <Grid item sm={12} lg={8} className={tableStyles.pdr}>
          <Grid container spacing={0} alignItems="center">
            <Grid item md="auto" style={{ flex: 1, position: "relative" }}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                Quản Lý Đơn Hàng{" "}
                {loading && !init && (
                  <CircularProgress
                    size="1.25rem"
                    style={{ color: color.svg, marginLeft: 12 }}
                  />
                )}
              </Typography>
              {!init && (
                <div className="result-searched">
                  <b>{total.toLocaleString("en")}</b> đơn,{" "}
                  {dmY(dateRangeOld[0]).toString() !== "01/01/70"
                    ? `${dmY(dateRangeOld[0])} - ${dmY(dateRangeOld[1])}`
                    : "toàn thời gian"}
                </div>
              )}
            </Grid>
            <Grid item md="auto">
              <BalanceEl
                els={[
                  <Tooltip title="Tải xuống Excel">
                    <IconButton
                      size={isXs ? "small" : "medium"}
                      onClick={handleDownloadExcel}
                    >
                      {downloadExcel ? (
                        <CircularProgress size={20} />
                      ) : (
                        <PaperDownload
                          size={20}
                          set="bold"
                          color={color.light}
                        />
                      )}
                    </IconButton>
                  </Tooltip>,
                  <Tooltip title="Chọn trang">
                    <IconButton
                      size={isXs ? "small" : "medium"}
                      onClick={() =>
                        window.scrollTo({
                          top: document.body.scrollHeight,
                          behavior: "smooth",
                        })
                      }
                    >
                      <Badge
                        badgeContent={page}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                      >
                        <ArrowDown set="bold" color={color.svg} size={20} />
                      </Badge>
                    </IconButton>
                  </Tooltip>,
                  <Box width={8} />,
                  isXs ? (
                    <IconButton
                      size="small"
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      <Filter set="bold" color={color.svg} />
                    </IconButton>
                  ) : (
                    <Button
                      onClick={() => setShowFilter(!showFilter)}
                      variant="outlined"
                      style={{
                        color: "rgb(121, 129, 122)",
                        borderColor: "#aabac1",
                      }}
                    >
                      <Filter size={20} set="bold" style={{ marginRight: 8 }} />{" "}
                      Lọc Danh Sách
                    </Button>
                  ),
                ]}
              />
            </Grid>
          </Grid>

          <Collapse in={showFilter}>
            <Box
              className={tableStyles.wrapperFilter}
              style={{ display: "block" }}
            >
              <Grid container>
                <Grid item xs={12} md="auto">
                  <FormControl fullWidth={isXs} size="small" variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Loại đơn
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={barter}
                      onChange={handleChangeBarter}
                      label="Loại đơn"
                      style={{ width: isXs ? "100%" : 220 }}
                    >
                      <MenuItem value="">
                        <em>Không chọn</em>
                      </MenuItem>
                      <MenuItem value="0">Đơn hàng toàn phần</MenuItem>
                      <MenuItem value="1">Đơn hàng một phần</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md="auto">
                  <Autocomplete
                    value={city === "" ? null : city}
                    onChange={handleChangeCity}
                    options={cities}
                    renderInput={(params) => (
                      <TextField
                        style={{ minWidth: 220 }}
                        {...params}
                        variant="outlined"
                        label="Tỉnh/Thành phố"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md="auto">
                  <Autocomplete
                    value={district === "" ? null : district}
                    onChange={(_, newValue) => setDistrict(newValue)}
                    options={districts}
                    loadingText={<CircularProgress size={20} />}
                    loading={city && districts.length === 0}
                    noOptionsText={
                      city ? "Không tìm thấy huyện" : "Hãy chọn tỉnh trước"
                    }
                    renderInput={(params) => (
                      <TextField
                        style={{ minWidth: 220 }}
                        {...params}
                        variant="outlined"
                        label="Huyện/Quận"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                {!isLg && (
                  <Grid item xs={12} md="auto">
                    <TextField
                      fullWidth={isXs}
                      size="small"
                      value={control_code}
                      onChange={(e) => set_control_code(e.target.value)}
                      variant="outlined"
                      label="Mã đối soát"
                    />
                  </Grid>
                )}
              </Grid>
              <Box height={16} />
              <Grid container>
                <Grid item xs={12} md="auto">
                  <ReactDatePicker
                    wrapperClassName="xs-w100"
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    selectsRange
                    isClearable
                    customInput={
                      <TextField
                        fullWidth={isXs}
                        variant="outlined"
                        size="small"
                        label="Khoảng thời gian"
                        inputRef={range_date_ref}
                        inputProps={{ style: { minWidth: 220 } }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md="auto">
                  <Autocomplete
                    size="small"
                    value={statusName}
                    onChange={handleChangeStatus}
                    limitTags={1}
                    multiple
                    options={status}
                    renderInput={(params) => (
                      <TextField
                        style={{ minWidth: 220 }}
                        {...params}
                        variant="outlined"
                        label="Trạng thái"
                        size="small"
                        helperText={
                          <>
                            <Link
                              onClick={importSuccess}
                              href="#!"
                              color="inherit"
                            >
                              Thành công
                            </Link>
                            ,{" "}
                            <Link
                              onClick={importFailed}
                              href="#!"
                              color="inherit"
                            >
                              Thất bại
                            </Link>
                            ,{" "}
                            <Link
                              href="#!"
                              onClick={() => setStatusName([])}
                              color="inherit"
                            >
                              Xoá
                            </Link>
                          </>
                        }
                      />
                    )}
                  />
                  {/* <FormControl size="small" style={{ width: isXs ? '100%' : 240 }} variant="outlined">
                                        <InputLabel>Trạng thái</InputLabel>
                                        <Select
                                            multiple
                                            //displayEmpty
                                            value={statusName}
                                            onChange={handleChangeStatus}
                                            input={<OutlinedInput label="Trạng thái" />}
                                            MenuProps={MenuProps}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Chọn trạng thái</span>;
                                                }
                                                return selected.join(', ');
                                            }}
                                        >
                                            {status.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    <Checkbox checked={statusName.indexOf(name) > -1} />
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>
                                            <Link onClick={importSuccess} href="#!" color="inherit">Thành công</Link>
                                            , <Link onClick={importFailed} href="#!" color="inherit">Thất bại</Link>
                                            , <Link href="#!" onClick={() => setStatusName([])} color="inherit">Xoá</Link>
                                        </FormHelperText>
                                    </FormControl> */}
                </Grid>
                {isLg && (
                  <Grid item xs={12} md="auto">
                    <TextField
                      fullWidth={isXs}
                      size="small"
                      value={control_code}
                      onChange={(e) => set_control_code(e.target.value)}
                      variant="outlined"
                      label="Mã đối soát"
                    />
                  </Grid>
                )}
                <Grid item xs={12} md="auto">
                  <TextField
                    fullWidth={isXs}
                    multiline
                    size="small"
                    value={order_code}
                    ref={order_code_ref}
                    onChange={(e) => set_order_code(e.target.value)}
                    helperText="Mỗi dòng 1 giá trị"
                    variant="outlined"
                    label="Mã đơn hàng / SĐT"
                    inputProps={{ style: { minWidth: 240 } }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md="auto"
                  style={{
                    flex: 1,
                    paddingRight: 12,
                    display: "flex",
                    alignItems: "center",
                    height: 56,
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    style={{ height: 40, whiteSpace: "nowrap" }}
                    fullWidth={isXs}
                    disabled={loading}
                    onClick={handleSearch}
                    color="secondary"
                    variant="contained"
                  >
                    Tra cứu
                  </Button>
                  {!isXs && (
                    <IconButton onClick={() => setShowFilter(false)}>
                      <ChevronUp color={color.svg} />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Collapse>

          {!isXs ? (
            <>
              <Box className={tableStyles.preTable} />
              <Box marginTop="-34px" />
            </>
          ) : (
            <Box marginTop={showFilter ? 2 : 4} />
          )}

          {init ? (
            <Box position="relative" zIndex={2} id="view">
              <LoadingData />
            </Box>
          ) : total === 0 ? (
            <Box className="empty">
              <Typography variant="h6">Trống rỗng!</Typography>
              <Box color={color.light}>
                Bạn chưa có đơn hàng nào được duyệt cả.
              </Box>
            </Box>
          ) : (
            <>
              {!isXs ? (
                <>
                  <div
                    className={tableStyles.actions}
                    style={{ bottom: checkeds == 0 ? -72.5 : 0 }}
                  >
                    <div className={tableStyles.actions_container}>
                      <Button disabled>{checkeds} đơn</Button>
                      <Button
                        disabled={downloadExcelChecked}
                        onClick={() => handleDownloadExcelChecked()}
                      >
                        <CloudDownloadOutlined />
                        Tải Excel
                      </Button>
                      <Button onClick={() => handlePrint("a5")}>
                        <PrintOutlined />
                        In A5
                      </Button>
                      <Button onClick={() => handlePrint("s9")}>
                        <PrintOutlined />
                        In S9
                      </Button>
                      <Button onClick={handleUncheck}>
                        <X />
                      </Button>
                    </div>
                  </div>
                  <Box className={tableStyles.realTable}>
                    <Box
                      className={tableStyles.heading}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Checkbox
                        checked={checkeds == data.length}
                        style={{ marginLeft: -8 }}
                        size="small"
                        onClick={handleCheckAll}
                      />{" "}
                      Mã đơn hàng
                    </Box>
                    <Box className={tableStyles.heading}>Sản phẩm</Box>
                    <Box className={tableStyles.heading}>Người nhận</Box>
                    <Box className={tableStyles.heading}></Box>
                    {data.map((order, i) => (
                      <Box
                        className={
                          activeOrder?.code_supership === order.code_supership
                            ? `${tableStyles.rowWrapper} ${tableStyles.hover}`
                            : tableStyles.rowWrapper
                        }
                        key={i}
                      >
                        <Box>
                          <ul className={tableStyles.ul}>
                            <li style={{ fontWeight: 500 }}>
                              <CopyToClipboard text={order.code_supership}>
                                {order.code_supership}
                              </CopyToClipboard>
                            </li>
                            {order.code_orders && (
                              <li style={{ color: "#333", maxWidth: 230 }}>
                                <CopyToClipboard text={order.code_orders}>
                                  MĐKH: {order.code_orders}
                                </CopyToClipboard>
                              </li>
                            )}
                            {order.required_code &&
                              order.required_code != order.code_supership && (
                                <li style={{ color: "#333", maxWidth: 230 }}>
                                  <CopyToClipboard text={order.required_code}>
                                    MYC: {order.required_code}
                                  </CopyToClipboard>
                                </li>
                              )}
                            <li>Duyệt lúc: {order.date_create}</li>
                          </ul>
                          <Box display="flex" alignItems="center">
                            <Checkbox
                              checked={order.checked}
                              onClick={() => handleCheck(i)}
                              size="small"
                              style={{ marginTop: 8, marginLeft: -8 }}
                            />
                            <div
                              className={tableStyles.status}
                              style={{
                                background: order.background,
                                color: order.text,
                                fontWeight: order.fontWeight,
                              }}
                            >
                              {order.status}
                            </div>
                          </Box>
                        </Box>
                        <Box>
                          <ul
                            className={tableStyles.ul}
                            style={{ maxWidth: 250 }}
                          >
                            <li>
                              <Tooltip title={order.product}>
                                <span>
                                  {textTransform.ucfirst(order.product)}
                                </span>
                              </Tooltip>
                            </li>
                            <li>
                              Khối lượng:{" "}
                              {Number(order.mass).toLocaleString("en")}g
                            </li>
                            {order.value == 0 ? (
                              ""
                            ) : (
                              <li>
                                Trị giá:{" "}
                                {Number(order.value).toLocaleString("en")}đ
                              </li>
                            )}
                            <li style={{ color: color.secondary2 }}>
                              Thu hộ:{" "}
                              {Number(order.collect).toLocaleString("en")}đ
                            </li>
                            <li style={{ color: color.secondary }}>
                              Phí dịch vụ:{" "}
                              {Number(order.fee).toLocaleString("en")}đ
                            </li>
                            {user.user_ref === "0" && (
                              <li>
                                <UserDetect fullSize id={order.id_customer} />
                              </li>
                            )}
                          </ul>
                        </Box>
                        <Box>
                          <ul
                            className={tableStyles.ul}
                            style={{ maxWidth: 200 }}
                          >
                            <li>{order.city}</li>
                            <li style={{ textTransform: "capitalize" }}>
                              {order.receiver}
                            </li>
                            <li>
                              <CopyToClipboard text={order.phone}>
                                {textTransform.mobile(order.phone)}
                              </CopyToClipboard>
                            </li>
                            {order.dh_mot_phan === "1" && (
                              <li style={{ color: "#f11042", fontWeight: 500 }}>
                                Đơn hàng một phần
                              </li>
                            )}
                          </ul>
                        </Box>
                        <Box>
                          <IconButton onClick={() => setActiveOrder(order)}>
                            <Show set="bold" color={color.svg} />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </>
              ) : (
                <>
                  <div
                    className={tableStyles.actions}
                    style={{ bottom: checkeds == 0 ? -72.5 : 55 }}
                  >
                    <div className={tableStyles.actions_container}>
                      <Button disabled>{checkeds}</Button>
                      <Checkbox
                        checked={checkeds == data.length}
                        style={{ marginLeft: -8 }}
                        size="small"
                        onClick={handleCheckAll}
                      />
                      <Button
                        disabled={downloadExcelChecked}
                        onClick={handleDownloadExcelChecked}
                      >
                        <CloudDownloadOutlined />
                        Excel
                      </Button>
                      <Button onClick={() => handlePrint("a5")}>
                        <PrintOutlined />
                        A5
                      </Button>
                      <Button onClick={() => handlePrint("s9")}>
                        <PrintOutlined />
                        S9
                      </Button>
                      <Button onClick={handleUncheck}>
                        <X />
                      </Button>
                    </div>
                  </div>
                  {data.map((order, i) => (
                    <MobileOrder
                      onCB={() => handleCheck(i)}
                      onQV={() => handleQV(order)}
                      key={i}
                      data={order}
                    />
                  ))}
                </>
              )}
              <div
                className="pageWrapper"
                style={{ marginBottom: isXs ? 0 : 24 }}
              >
                <TablePagination
                  style={{ overflow: "hidden", margin: "0 -16px" }}
                  component="div"
                  count={total}
                  labelRowsPerPage={isXs ? "Hiển thị:" : "Hiển thị mỗi trang:"}
                  nextIconButtonText="Trang sau"
                  labelDisplayedRows={({ from, to, count }) =>
                    from + "-" + to + " / " + count
                  }
                  rowsPerPageOptions={[35, 100, 200, 500]}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerpage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            </>
          )}
        </Grid>
        <COD_Dialog ref={COD_Dialog_ref} onEdit={editCOD} />
        {!isXs ? (
          <Grid item lg={4}>
            <QuickView data={activeOrder} COD_Dialog_ref={COD_Dialog_ref} />
          </Grid>
        ) : (
          <Drawer
            anchor="right"
            COD_Dialog_ref={COD_Dialog_ref}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          >
            <QuickView
              COD_Dialog_ref={COD_Dialog_ref}
              closeDrawer={() => setDrawerOpen(false)}
              data={activeOrder}
            />
          </Drawer>
        )}
      </Grid>
    </>
  );
};

export default OrdersRoute;
