import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, IconButton, Switch, TextField, Tooltip, Typography, useMediaQuery, useTheme, withStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { HuePicker, TwitterPicker } from "react-color";
import { Check } from "react-feather";
import { AddUser, CloseSquare, EditSquare, Login, People, User } from "react-iconly";
import color from "../config/color";
import textTransform from "../config/functions/text";
import { UserContext } from "../contexts/user.context";

const StyledAlert = withStyles({
    message: {
        width: '100%',
        position: 'relative',
    },
    root: {
        background: '#fff',
        borderBottom: '1px solid #f4f7f9',
        borderRadius: 0
    }
})(Alert)

const Actions = ({ onEdit, onDelete, data, onChecked, onClose }) => {
    const [confirmDelete, setConfirmDelete] = useState(0)
    const { enqueueSnackbar } = useSnackbar()
    const { userFetch, setUser, user } = useContext(UserContext)
    const [login, setLogin] = useState(false)

    const handleDelete = async () => {
        if (confirmDelete === 2)
            return
        setConfirmDelete(confirmDelete + 1)
        if (confirmDelete === 1) {
            try {
                let url = '/models/account/nested_account.php?id=' + data.id
                let res = await userFetch(url, {
                    method: 'DELETE',
                })
                let resj = await res.json()
                if (resj.response_code !== 200)
                    enqueueSnackbar(resj.msg, { variant: 'error' })
                else {
                    enqueueSnackbar('Thao tác thành công', { variant: 'success' })
                    onDelete()
                }
            } catch (e) {
                setConfirmDelete(0)
                enqueueSnackbar('Có lỗi xảy ra', { variant: 'error' })
            }
        }
    }

    const handleLogin = async () => {
        setLogin(true)
        let res = await userFetch('/login.php?id=' + data.id, {
            method: 'POST'
        })
        let resj = await res.json()
        setUser({ ...resj, parent: user })
        enqueueSnackbar('Đăng nhập thành công', { variant: 'success' })
        onClose()
    }

    useEffect(() => {
        if (confirmDelete !== 1)
            return;
        let t = setTimeout(() => {
            enqueueSnackbar('Huỷ thao tác xóa', { variant: 'warning' })
            setConfirmDelete(0)
        }, 5000)
        return () => {
            clearTimeout(t)
        }
    }, [confirmDelete])

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <div style={{ marginLeft: -3 }}>
                <Tooltip title="Sửa">
                    <IconButton color="secondary" size="small" onClick={onEdit}>
                        <EditSquare set="bulk" />
                    </IconButton>
                </Tooltip>{''}
                <Tooltip title={['Xoá', 'Xác nhận xoá', 'Đang xoá'][confirmDelete]}>
                    <IconButton onClick={handleDelete} size="small">
                        {[<CloseSquare set="bulk" color={color.secondary} />, <Check color={color.secondary} />, <CircularProgress size={20} style={{ color: color.secondary }} />][confirmDelete]}
                    </IconButton>
                </Tooltip>{''}
                <Tooltip title={!login ? 'Đăng nhập' : 'Đợi chút'}>
                    <IconButton size="small" disabled={login || data.is_active === "0"} onClick={handleLogin}>
                        {!login ? <Login set="bulk" /> : <CircularProgress color="inherit" size={20} />}
                    </IconButton>
                </Tooltip>
            </div>
            <Switch onClick={onChecked} checked={data.is_active === "1"} color="primary" />
        </Box>
    )
}

const StaffPopup = forwardRef(function (_, ref) {
    const [open, setOpen] = useState(false)
    const [edit, setEdit] = useState(null)
    const [color, setColor] = useState('#' + Math.floor(Math.random() * 16777215).toString(16))
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [shop, setShop] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const { userFetch } = useContext(UserContext)
    const { enqueueSnackbar } = useSnackbar()
    const isXS = useMediaQuery(useTheme().breakpoints.only('xs'))
    const { nested_account, set_nested_account } = useContext(UserContext)

    useImperativeHandle(ref, () => ({
        open: (edit = null) => {
            setOpen(true)
            setEdit(edit)
            if (!edit) {
                setColor('#' + Math.floor(Math.random() * 16777215).toString(16))
                setPhone('')
                setUsername('')
                setShop('')
                setEmail('')
            } else {
                setColor(edit.user_color)
                setPhone(edit.customer_phone)
                setUsername(edit.username)
                setShop(edit.customer_shop_code)
                setEmail(edit.customer_email)
            }
        }
    }))

    const handleClose = () => setOpen(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        let formData = new FormData()
        formData.append('customer_phone', phone)
        formData.append('customer_email', email)
        formData.append('user_color', color)
        formData.append('username', username)
        formData.append('password', password)
        formData.append('shop', shop)
        if (edit)
            formData.append('edit', edit.id)
        let res = await userFetch('/models/account/nested_account.php', {
            method: 'POST',
            body: formData
        })
        let resj = await res.json()
        if (resj.status === 'error')
            enqueueSnackbar(resj.msg, { variant: 'error' })
        else {
            enqueueSnackbar('Thao tác thành công', { variant: 'success' })
            if (!edit) {
                set_nested_account([...nested_account, {
                    customer_phone: phone,
                    customer_email: email,
                    user_color: color,
                    username: username,
                    customer_shop_code: shop,
                    id: resj.id,
                    is_active: '1',
                    process: 0,
                    total: 0
                }])
            } else {
                let i = nested_account.map(v => v.id).indexOf(edit.id)
                let clone = [...nested_account]
                clone[i] = {
                    ...clone[i],
                    customer_phone: phone,
                    customer_email: email,
                    user_color: color,
                    username: username,
                    customer_shop_code: shop,
                }
                set_nested_account(clone)
            }
            handleClose()
        }
        setLoading(false)
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullScreen={isXS}>
            <form autoComplete="off" onSubmit={handleSubmit} method="post">
                <DialogTitle><User style={{ verticalAlign: -4 }} set="bold" color={color} />  {edit ? 'Sửa tài khoản' : 'Thêm tài khoản'}</DialogTitle>
                <DialogContent>
                    <div style={{
                        lineHeight: 4
                    }}>
                        <div>
                            <TwitterPicker color={color} onChangeComplete={c => setColor(c.hex)} />
                            <Box marginTop={2} marginBottom={1}>
                                <HuePicker color={color} onChange={c => setColor(c.hex)} onChangeComplete={c => setColor(c.hex)} />
                            </Box>
                            <TextField value={phone} onChange={e => setPhone(e.target.value)} fullWidth required label="Số điện thoại" helperText="Không bao gồm +84 ở đầu" inputProps={{ minLength: 10, maxLength: 11 }} />
                            <TextField value={email} onChange={e => setEmail(e.target.value)} fullWidth type="email" required label="Email" />
                            <TextField value={shop} onChange={e => setShop(e.target.value)} fullWidth required label="Tên hiển thị" inputProps={{ maxLength: 50, minLength: 3 }} />
                            <TextField value={username} onChange={e => setUsername(e.target.value)} fullWidth required label="Tên đăng nhập" inputProps={{ maxLength: 20, minLength: 5 }} />
                            <TextField helperText={edit ? 'Bỏ trống nếu không sửa mật khẩu' : null} value={password} onChange={e => setPassword(e.target.value)} fullWidth label="Mật khẩu" />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button type="button" onClick={handleClose}>Đóng</Button>
                    <Button variant="contained" type="submit" disabled={loading} color="secondary">{edit ? 'Lưu' : 'Thêm'}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
})

const Staff = (_, ref) => {
    const [open, setOpen] = useState(false)
    const staffRef = useRef(null)
    const { nested_account, set_nested_account, userFetch, getNested } = useContext(UserContext)

    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true)
            getNested()
        }
    }))

    const handleClose = () => setOpen(false)

    const handleDelete = (i) => {
        let cloneData = [...nested_account]
        cloneData.splice(i, 1)
        set_nested_account(cloneData)
    }

    const handleChecked = (i) => {
        let cloneData = [...nested_account]
        let newActive = cloneData[i].is_active === "0" ? "1" : "0"
        cloneData[i] = {
            ...cloneData[i],
            is_active: newActive
        }
        set_nested_account(cloneData)
        userFetch('/models/account/nested_account.php?active=' + newActive + '&id=' + cloneData[i].id)
    }

    return (
        <>
            <StaffPopup ref={staffRef} />
            <Drawer anchor="right" open={open} onClose={handleClose}>
                <Box width={350} maxWidth="100%">
                    <Box display="flex" padding="0 16px" justifyContent="space-between" alignItems="center" height={64} position="sticky" top={0} bgcolor="#fff" zIndex={1}>
                        <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}><People set="bold" style={{ marginRight: 5 }} /> Tài khoản</Typography>
                        <div>
                            <Tooltip title="Thêm">
                                <IconButton onClick={() => staffRef.current.open()}>
                                    <AddUser set="broken" />
                                </IconButton>
                            </Tooltip>
                            <IconButton type="button" onClick={handleClose}>
                                <Close />
                            </IconButton>
                        </div>
                    </Box>

                    {nested_account.length === 0 ?
                        <Alert severity="info">
                            <AlertTitle>Chưa có tài khoản nào.</AlertTitle>
                            Tài khoản này có thể lên đơn hàng và quản lý những đơn đó dưới vai trò là tài khoản phụ, tài khoản phụ sẽ đồng bộ dữ liệu ( đơn hàng, đối soát ) với tài khoản chính, ngược lại thì không. Có thể áp dụng nó cho nhân viên / cửa hàng.
                            </Alert>
                        :
                        <Alert severity="info">Gạt công tắc để ngưng hoạt động.</Alert>
                    }
                    {nested_account.map((v, i) => {
                        return (
                            <StyledAlert severity="info" key={v.id} icon={<User size={22} set="bold" color={v.user_color} />}>
                                <AlertTitle style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                                    {v.customer_shop_code}
                                    <Tooltip title="Đơn đang xử lý / tổng số">
                                        <Chip label={<><b>{v.process}</b> / {v.total}</>} size="small" />
                                    </Tooltip>
                                </AlertTitle>
                                @{v.username}, {textTransform.mobile(v.customer_phone)}
                                <Actions onClose={handleClose} onChecked={() => handleChecked(i)} onEdit={() => staffRef.current.open(v)} onDelete={() => handleDelete(i)} data={v} />
                            </StyledAlert>
                        )
                    })}
                </Box>
            </Drawer>
        </>
    )
}

export default forwardRef(Staff)