
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'

const Copy = ({text, msg, variant ,children}) => {
    const {enqueueSnackbar} = useSnackbar()
    const handleCopy = () => {
        enqueueSnackbar(msg ?? text, { variant: variant ?? 'success' })
    }

    return (
        <CopyToClipboard text={text} onCopy={handleCopy}>
            <span className="copy">
                {children}
            </span>
        </CopyToClipboard>
    )
}

export default Copy