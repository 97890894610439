import { Box, Button, Checkbox, CircularProgress, Drawer, Fade, FormControl, FormControlLabel, FormHelperText, IconButton, InputLabel, List, ListItem, MenuItem, Paper, Select, Slide, TextField, Tooltip, Typography, withStyles } from "@material-ui/core"
import Close from "@material-ui/icons/Close"
import { Autocomplete } from "@material-ui/lab"
import { useSnackbar } from "notistack"
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react"
import { Check, Trash } from "react-feather"
import { Home, Setting } from "react-iconly"
import color from "../config/color"
import { UserContext } from "../contexts/user.context"

const provinces = [
    {
        "code": "92",
        "name": "Thành phố Cần Thơ"
    },
    {
        "code": "48",
        "name": "Thành phố Đà Nẵng"
    },
    {
        "code": "1",
        "name": "Thành phố Hà Nội"
    },
    {
        "code": "31",
        "name": "Thành phố Hải Phòng"
    },
    {
        "code": "79",
        "name": "Thành phố Hồ Chí Minh"
    },
    {
        "code": "89",
        "name": "Tỉnh An Giang"
    },
    {
        "code": "77",
        "name": "Tỉnh Bà Rịa - Vũng Tàu"
    },
    {
        "code": "24",
        "name": "Tỉnh Bắc Giang"
    },
    {
        "code": "6",
        "name": "Tỉnh Bắc Kạn"
    },
    {
        "code": "95",
        "name": "Tỉnh Bạc Liêu"
    },
    {
        "code": "27",
        "name": "Tỉnh Bắc Ninh"
    },
    {
        "code": "83",
        "name": "Tỉnh Bến Tre"
    },
    {
        "code": "74",
        "name": "Tỉnh Bình Dương"
    },
    {
        "code": "52",
        "name": "Tỉnh Bình Định"
    },
    {
        "code": "70",
        "name": "Tỉnh Bình Phước"
    },
    {
        "code": "60",
        "name": "Tỉnh Bình Thuận"
    },
    {
        "code": "96",
        "name": "Tỉnh Cà Mau"
    },
    {
        "code": "4",
        "name": "Tỉnh Cao Bằng"
    },
    {
        "code": "66",
        "name": "Tỉnh Đắk Lắk"
    },
    {
        "code": "67",
        "name": "Tỉnh Đắk Nông"
    },
    {
        "code": "11",
        "name": "Tỉnh Điện Biên"
    },
    {
        "code": "75",
        "name": "Tỉnh Đồng Nai"
    },
    {
        "code": "87",
        "name": "Tỉnh Đồng Tháp"
    },
    {
        "code": "64",
        "name": "Tỉnh Gia Lai"
    },
    {
        "code": "2",
        "name": "Tỉnh Hà Giang"
    },
    {
        "code": "35",
        "name": "Tỉnh Hà Nam"
    },
    {
        "code": "42",
        "name": "Tỉnh Hà Tĩnh"
    },
    {
        "code": "30",
        "name": "Tỉnh Hải Dương"
    },
    {
        "code": "93",
        "name": "Tỉnh Hậu Giang"
    },
    {
        "code": "17",
        "name": "Tỉnh Hoà Bình"
    },
    {
        "code": "33",
        "name": "Tỉnh Hưng Yên"
    },
    {
        "code": "56",
        "name": "Tỉnh Khánh Hòa"
    },
    {
        "code": "91",
        "name": "Tỉnh Kiên Giang"
    },
    {
        "code": "62",
        "name": "Tỉnh Kon Tum"
    },
    {
        "code": "12",
        "name": "Tỉnh Lai Châu"
    },
    {
        "code": "68",
        "name": "Tỉnh Lâm Đồng"
    },
    {
        "code": "20",
        "name": "Tỉnh Lạng Sơn"
    },
    {
        "code": "10",
        "name": "Tỉnh Lào Cai"
    },
    {
        "code": "80",
        "name": "Tỉnh Long An"
    },
    {
        "code": "36",
        "name": "Tỉnh Nam Định"
    },
    {
        "code": "40",
        "name": "Tỉnh Nghệ An"
    },
    {
        "code": "37",
        "name": "Tỉnh Ninh Bình"
    },
    {
        "code": "58",
        "name": "Tỉnh Ninh Thuận"
    },
    {
        "code": "25",
        "name": "Tỉnh Phú Thọ"
    },
    {
        "code": "54",
        "name": "Tỉnh Phú Yên"
    },
    {
        "code": "44",
        "name": "Tỉnh Quảng Bình"
    },
    {
        "code": "49",
        "name": "Tỉnh Quảng Nam"
    },
    {
        "code": "51",
        "name": "Tỉnh Quảng Ngãi"
    },
    {
        "code": "22",
        "name": "Tỉnh Quảng Ninh"
    },
    {
        "code": "45",
        "name": "Tỉnh Quảng Trị"
    },
    {
        "code": "94",
        "name": "Tỉnh Sóc Trăng"
    },
    {
        "code": "14",
        "name": "Tỉnh Sơn La"
    },
    {
        "code": "72",
        "name": "Tỉnh Tây Ninh"
    },
    {
        "code": "34",
        "name": "Tỉnh Thái Bình"
    },
    {
        "code": "19",
        "name": "Tỉnh Thái Nguyên"
    },
    {
        "code": "38",
        "name": "Tỉnh Thanh Hóa"
    },
    {
        "code": "46",
        "name": "Tỉnh Thừa Thiên Huế"
    },
    {
        "code": "82",
        "name": "Tỉnh Tiền Giang"
    },
    {
        "code": "84",
        "name": "Tỉnh Trà Vinh"
    },
    {
        "code": "8",
        "name": "Tỉnh Tuyên Quang"
    },
    {
        "code": "86",
        "name": "Tỉnh Vĩnh Long"
    },
    {
        "code": "26",
        "name": "Tỉnh Vĩnh Phúc"
    },
    {
        "code": "15",
        "name": "Tỉnh Yên Bái"
    }
]

const CustomerPaper = (props) => <Paper {...props} variant="outlined" style={{ background: '#f2f2f2', margin: '16px 0' }} />

const MenuProps = {
    PaperProps: {
        style: {
            background: '#f2f2f2'
        },
    },
    getContentAnchorEl: null
}

const StyledWareHouse = withStyles(() => ({
    outlined: {
        '&:hover': {
            background: '#f2f2f2',
            cursor: 'pointer'
        }
    },
}))(Paper)

const Warehouse = forwardRef(({ onClose, onChange, value }, ref) => {
    const [repo, setRepo] = useState(() => {
        if (localStorage.getItem('repo')) {
            try {
                let cnJSON = JSON.parse(localStorage.getItem('repo'))
                if (typeof (cnJSON) === 'object')
                    return cnJSON
            } catch (e) {
                console.log('Invalid Account JSON')
            }
        }
        return [{ id: 0 }]
    })
    const [repo_name, set_repo_name] = useState('') // địa chỉ
    const [repo_name2, set_repo_name2] = useState('')
    const [repo_phone, set_repo_phone] = useState('')
    const [is_default, set_is_default] = useState(false)
    const [districts, setDistricts] = useState([])
    const [communes, setCommunes] = useState([])
    const [confirm_delete, set_confirm_delete] = useState(0)
    const [district, setDistrict] = useState(null)
    const [city, setCity] = useState(null)
    const [commune, setCommune] = useState(null)
    const [whContext, setWhContext] = useState(null)
    const [open, setOpen] = useState(false)
    const [repoSelected, setRepoSelected] = useState(value ?? {})
    const { userFetch } = useContext(UserContext)
    const [districtRef, communeRef, cityRef] = [useRef(null), useRef(null), useRef(null)]
    const { enqueueSnackbar } = useSnackbar()
    const deleteRef = useRef(null)

    useEffect(() => {
        getRepo()
    }, [value])

    const getRepo = async () => {
        let res = await userFetch('/models/pending/actions.php?act=get_repo')
        let resj = await res.json()
        setRepo(resj.data)
        if (!resj.data.length) {
            setOpen(true)
        } else {
            localStorage.setItem('repo', JSON.stringify(resj.data))
            if (value) {
                const found = resj.data.find(v => value === v.id)
                if (found){
                    setRepoSelected(found)
                    onChange(found.id)
                }
            } else {
                setRepoSelected(resj.data[0])
                if (onChange)
                    onChange(resj.data[0].id)
            }
        }
    }

    const handleDelete = async () => {
        if (confirm_delete === 2)
            return;
        if (deleteRef.current)
            clearTimeout(deleteRef.current)
        set_confirm_delete(confirm_delete + 1)
        if (confirm_delete === 1) {
            let res = await userFetch('/models/account/warehouse.php?id=' + repo[whContext].id, {
                method: 'DELETE'
            })
            let resj = await res.json()
            if (resj.response_code === 200) {
                enqueueSnackbar('Thao tác thành công!', { variant: 'success' })
                setWhContext(null)
                getRepo()
            } else {
                enqueueSnackbar('Thao tác thất bại!', { variant: 'error' })
                set_confirm_delete(0)
            }
        } else {
            deleteRef.current = setTimeout(() => {
                set_confirm_delete(0)
            }, 3000)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (whContext === null)
            handleEdit()
        else {
            if (!repo_name.trim() || !repo_name2.trim() || !repo_phone.trim() || !city || !district || !commune) {
                enqueueSnackbar('Bạn cần nhập đầy đủ thông tin!', { variant: 'warning' })
                return
            }
            let formData = new FormData()
            formData.append('name2', repo_name2)
            formData.append('name', repo_name)
            formData.append('phone', repo_phone)
            formData.append('province', city?.name)
            formData.append('district', district?.name)
            formData.append('commune', commune?.name)
            formData.append('is_default', is_default)
            if (whContext !== true)
                formData.append('id', repo[whContext].id)
            let res = await userFetch('/models/account/warehouse.php', {
                method: 'POST',
                body: formData
            })
            let resj = await res.json()
            if (resj.response_code === 200) {
                enqueueSnackbar('Thao tác thành công!', { variant: 'success' })
                setWhContext(null)
                getRepo()
            } else
                enqueueSnackbar('Thao tác thất bại!', { variant: 'error' })
            console.log(resj)
        }
    }

    const autoCompleteAddress = async (tinh, huyen, xa) => {
        // find out index of provinces arr
        let index
        for (var i = 0; i < provinces.length; i++) {
            if (provinces[i].name.match(new RegExp(tinh, 'gi'))) {
                index = i
                break
            }
        }
        setCity(provinces[index])
        setDistrict({})
        setCommune({})
        setDistricts([])
        setCommunes([])
        // find out index of district arr
        let res = await userFetch(
            `/models/app/query/address.php?act=get_district_by_code&code=${provinces[index].code}`
        )
        let resj = await res.json()
        setDistricts(resj.data)
        for (var i = 0; i < resj.data.length; i++) {
            if (resj.data[i].name.match(new RegExp(huyen, 'gi'))) {
                index = i
                break
            }
        }
        setDistrict(resj.data[index])
        // 

        // find out index of commune arr
        res = await userFetch(
            `/models/app/query/address.php?act=get_commune_by_code&code=${resj.data[index].code}`
        )
        resj = await res.json()
        setCommunes(resj.data)
        for (var i = 0; i < resj.data.length; i++) {
            if (resj.data[i].name.match(new RegExp(xa, 'gi'))) {
                index = i
                break
            }
        }
        setCommune(resj.data[index])
    }

    const handleEdit = (idx = null) => {
        setWhContext(idx === null ? true : idx)
        set_confirm_delete(0)
        if (idx !== null) {
            set_repo_name2(repo[idx].name)
            set_repo_name(repo[idx].nameAddress)
            set_repo_phone(repo[idx].phone)
            set_is_default(repo[idx].is_default === "1")
            autoCompleteAddress(repo[idx].province, repo[idx].district, repo[idx].commune)
        } else {
            set_repo_name2('')
            set_repo_name('')
            set_repo_phone('')
            set_is_default(false)
            setCity(null)
            setDistrict(null)
            setCommune(null)
            setCommunes([])
            setDistricts([])
        }
    }

    const handleChangeCity = async (_, v) => {
        try {
            setCity(v)
            districtRef.current.value = ''
            communeRef.current.value = ''
            districtRef.current.blur()
            setDistrict({})
            setCommune({})
            setDistricts([])
            setCommunes([])
            let res = await userFetch(
                `/models/app/query/address.php?act=get_district_by_code&code=${v.code}`
            )
            let resj = await res.json()
            setDistricts(resj.data)
            districtRef.current.focus()
        } catch { }
    }
    const handleChangeDistrict = async (_, v) => {
        setDistrict(v)
        communeRef.current.value = ''
        setCommune({})
        setCommunes([])
        if (v) {
            let res = await userFetch(
                `/models/app/query/address.php?act=get_commune_by_code&code=${v.code}`
            )
            let resj = await res.json()
            setCommunes(resj.data)
            communeRef.current.focus()
        } else {
            setCommunes([])
        }
    }
    const handleChangeCommune = (_, v) => setCommune(v)

    const handleClose = () => {
        if (whContext !== null) {
            setWhContext(null)
        } else {
            setOpen(false)
            if (!repo.length)
                onClose()
        }
    }

    useImperativeHandle(ref, () => ({
        id: repoSelected.id,
    }))

    useEffect(() => {
        return () => {
            if (deleteRef.current)
                clearTimeout(deleteRef.current)
        }
    }, [])
    return (
        <>
            <Drawer onClose={handleClose} anchor="right" open={open}>
                <Box padding={2} width={380} maxWidth="100%" display="flex" flexDirection="column" minHeight="100vh" style={{ overflowX: 'hidden' }}>
                    <Box flex={1}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">{whContext === null ? 'Danh sách cửa hàng' : (whContext === true ? 'Thêm cửa hàng' : 'Sửa cửa hàng')}</Typography>
                            <IconButton type="button" onClick={handleClose}>
                                <Close />
                            </IconButton>
                        </Box>
                        {
                            whContext === null
                                ?
                                <>
                                    <Box marginBottom={2} fontSize="small" color={color.lightText}>
                                        * Nhấn vào cửa hàng để chỉnh sửa / xóa.
                                    </Box>
                                    <Box>
                                        {repo.map((v, i) =>
                                            <StyledWareHouse onClick={() => handleEdit(i)} variant="outlined" key={i} style={{ marginBottom: 8, padding: 8 }}>
                                                <Home style={{ verticalAlign: -2 }} set="bold" size={18} color={color.svg} /> {v.name} {i === 0 ? <font color="red"> *</font> : ''}<br />
                                                <small><i>{v.formatted_address}</i></small>
                                            </StyledWareHouse>
                                        )}
                                    </Box>
                                </>
                                :
                                <Slide direction="left" in>
                                    <Box lineHeight="90px" marginTop={2}>
                                        <TextField helperText="Sẽ là tên người gửi khi Shiper đi giao hàng" value={repo_name2} onChange={e => set_repo_name2(e.target.value)} fullWidth variant="outlined" required label="Tên cửa hàng" />
                                        <TextField inputProps={{ maxLength: 10 }} helperText="Giúp người lấy hàng / giao hàng liên lạc khi có phát sinh." value={repo_phone} onChange={e => set_repo_phone(e.target.value)} fullWidth variant="outlined" required label="Số điện thoại" />
                                        <Autocomplete
                                            options={provinces}
                                            getOptionLabel={(option) => option.name}
                                            blurOnSelect
                                            openOnFocus
                                            onChange={handleChangeCity}
                                            PaperComponent={CustomerPaper}
                                            value={city}
                                            renderInput={(params) => <TextField size="small" inputRef={cityRef} required {...params} label="Tỉnh / Thành Phố" variant="outlined" />}
                                        />
                                        <Autocomplete
                                            options={districts}
                                            getOptionLabel={(option) => option.name ?? ''}
                                            blurOnSelect
                                            openOnFocus
                                            disabled={districts.length === 0}
                                            value={district}
                                            PaperComponent={CustomerPaper}
                                            onChange={handleChangeDistrict}
                                            renderInput={(params) => <TextField size="small" inputRef={districtRef} required {...params} label="Huyện / Quận" variant="outlined" />}
                                        />
                                        <Autocomplete
                                            options={communes}
                                            value={commune}
                                            getOptionLabel={(option) => option.name ?? ''}
                                            blurOnSelect
                                            openOnFocus
                                            disabled={communes.length === 0}
                                            PaperComponent={CustomerPaper}
                                            onChange={handleChangeCommune}
                                            renderInput={(params) => <TextField size="small" required {...params} inputRef={communeRef} label="Xã / Phường" variant="outlined" />}
                                        />
                                        <TextField helperText="Số nhà, tên đường ( không cần xã, huyện, tỉnh )" value={repo_name} onChange={e => set_repo_name(e.target.value)} fullWidth variant="outlined" required label="Địa chỉ chi tiết" />
                                        <FormControlLabel control={<Checkbox color="primary" checked={is_default} onClick={() => set_is_default(!is_default)} />} label="Đặt làm kho mặc định" />
                                    </Box>
                                </Slide>
                        }
                    </Box>
                    <Box marginY={1}>
                        {typeof whContext === 'number'
                            ?
                            <Box display="flex">
                                <Button onClick={handleSubmit} fullWidth variant="contained" size="large" color="secondary">Sửa Cửa Hàng</Button>
                                <Tooltip title={['Xóa kho hàng', 'Xác nhận xóa', 'Đang xóa'][confirm_delete]}>
                                    <Button type="button" onClick={handleDelete} variant="contained" color="default" style={{ marginLeft: 8 }}>
                                        {[<Trash />, <Check />, <CircularProgress size={24} />][confirm_delete]}
                                    </Button>
                                </Tooltip>
                            </Box>
                            :
                            <Button onClick={handleSubmit} fullWidth variant="contained" size="large" color="secondary">Thêm Cửa Hàng</Button>
                        }
                    </Box>
                </Box>
            </Drawer>
            <Box display="flex">
                <FormControl required fullWidth variant="outlined" size="small" style={{ flex: 1 }}>
                    <InputLabel>Chọn Cửa Hàng</InputLabel>
                    <Select required MenuProps={MenuProps} onChange={(e) => { setRepoSelected(e.target.value); if (onChange) onChange(e.target.value.id) }} value={repoSelected} label="Chọn Cửa Hàng" variant="outlined">
                        {
                            repo.map(v => <MenuItem key={v.id} value={v}>{v.name}</MenuItem>)
                        }
                    </Select>
                    <FormHelperText>{repoSelected.formatted_address}</FormHelperText>
                </FormControl>
                <button onClick={() => setOpen(true)} type="button" className="warehouse_cog" style={{ height: 40 }}><Setting /></button>
            </Box>
        </>
    )
})

export default Warehouse