import { Box, Button, Grid, Hidden, IconButton, Paper, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { useContext, useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import ReactDatePicker from "react-datepicker";
import { Helmet } from "react-helmet";
import { ArrowLeft, ArrowRight } from "react-iconly";
import { Link } from 'react-router-dom';
import LoadingData from "../../components/LoadingData";
import QuickView from "../../components/QuickView";
import orderModel from "../../config/order";
import { UserContext } from "../../contexts/user.context";
import ReportStyle from '../../css/Report.module.sass';

const ReportsRoute = () => {
    const { userFetch } = useContext(UserContext)
    const [data, setData] = useState({
        chart: {
            tong_don_nhan: 0,
            da_giao_hang: 0,
            khong_giao_duoc: 0,
            dang_xu_ly: 0
        },
        finance: {
            tong_thu_ho: 0,
            tong_thu_ho_da_thu: 0,
            tong_cuoc: 0
        },
        other: {
            ti_le_hoan: 0
        },
        status: {},
        cod: {
            chart: {
                tong_thu_ho_tru_phi: 0,
                da_doi_soat: 0,
                chua_doi_soat: 0
            },
            finance: {
                cod_tong_so_du_dang_xu_ly: 0,
                cod_tong_tien_doi_soat: 0
            }
        }
    })
    const [bar_chart, set_bar_chart] = useState({
        series: [{
            name: 'Đơn hàng',
            data: new Array(14).fill(0)
        }],
        options: {
            chart: {
                toolbar: {
                    show: false
                },
                height: 258.7,
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    hideOverflowingLabels: true,
                    borderRadius: 10,
                    dataLabels: {
                        maxItems: 14,
                        position: 'top', // top, center, bottom
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return Math.round(val);
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },

            xaxis: {
                categories: [...new Array(14)].map((_, i) => {
                    let now = Date.now() - 86400000 * i
                    let t = new Date(now)
                    return t.getDate() + '/' + (t.getMonth() + 1)
                }).reverse(),
                max: 2,
                position: 'bottom',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                },
            },
            yaxis: {
                title: {
                    text: 'Monthly Inflation in Argentina, 2002',
                    floating: true,
                    offsetY: 330,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                    formatter: function (val) {
                        return Math.round(val)
                    }
                },
            },

        },
    })
    const theme = useTheme()
    const isXs = useMediaQuery(theme.breakpoints.only('xs'))
    const isXl = useMediaQuery(theme.breakpoints.only('xl'))
    const { user } = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const quitPage = useRef(null)
    const live = useRef(null)
    const blockLive = useRef(false)
    const [liveKey, setLiveKey] = useState(0)
    const quickViewRef = useRef(null)
    const [dateRange, setDateRange] = useState([new Date() - 86400 * 30 * 1000, new Date()])
    const [range_date_ref, ref_date_bar] = [useRef(null), useRef(new Date())]
    const series = [data.chart.da_giao_hang, data.chart.khong_giao_duoc, data.chart.dang_xu_ly]
    const [loadingBack, setLoadingBack] = useState(false)
    const [loadingNext, setLoadingNext] = useState(false)

    const t1 = isXl ? 'h6' : 'h7'
    const t2 = isXl ? 'h4' : (isXs ? 'h6' : 'h5')

    const handleGoBar = async (direction) => {
        if (direction === 'left') {
            if (loadingBack)
                return
            setLoadingBack(true)
            let time = ref_date_bar.current.getTime() - 86400 * 1000 * 14;
            let t = new Date(time)
            ref_date_bar.current = t
            await getReport(false)
            setLoadingBack(false)
        } else if (direction === 'right') {
            if ((Date.now() - ref_date_bar.current.getTime()) < 86400000)
                return
            if (loadingNext)
                return
            setLoadingNext(true)
            let time = ref_date_bar.current.getTime() + 86400 * 1000 * 14;
            let t = new Date(time)
            ref_date_bar.current = t
            await getReport(false)
            setLoadingNext(false)
        }
    }

    const getReport = async (recall = true) => {
        quitPage.current = false
        if (recall)
            setLoading(true)
        if (!recall && (blockLive.current || series[2] === 0))
            return
        let [date_start, date_end] = range_date_ref.current.value.split('-')
        let _date_bar = null;
        let barDateMax = ref_date_bar.current;
        let _barDateMax = barDateMax.getDate() + '/' + (barDateMax.getMonth() + 1) + '/' + barDateMax.getFullYear();
        let date_bar = _barDateMax
        _date_bar = barDateMax
        let res = await userFetch('/models/reports/query.php?date_start=' + date_start + '&date_end=' + date_end + '&date_chart=' + date_bar)
        let result = await res.json()
        let startLoad = Date.now()
        let endLoad = Date.now()
        if (endLoad - startLoad < 500)
            await new Promise(res => setTimeout(() => res(true), 500))
        if (quitPage.current)
            return

        let mod = (Date.now() - (_date_bar?.getTime() ?? 0)) % 86400000
        let catFormat = [...new Array(14)].map((_, i) => -i + Math.floor(mod / 86400000)).reverse()

        set_bar_chart({
            ...bar_chart,
            series: [{
                data: result.bar_chart
            }],
            options: {
                xaxis: {
                    ...bar_chart.options.xaxis,
                    categories: catFormat.map(i => {
                        let now = (_date_bar?.getTime() ?? Date.now()) + 86400000 * i
                        let t = new Date(now)
                        return t.getDate() + '/' + (t.getMonth() + 1)
                    }),
                }
            }
        })
        setData(result.data)
        setLoading(false)
        if (recall)
            setLiveKey(liveKey + 1)
    }

    useEffect(() => {
        if (liveKey === 0) return
        clearInterval(live.current)
        live.current = setInterval(() => {
            getReport(false)
        }, 5000)
    }, [liveKey])

    useEffect(() => {
        getReport()
        return () => {
            quitPage.current = true
            clearInterval(live.current)
        }
    }, [])

    return (
        <>
            <Helmet>
                <title>Báo cáo</title>
            </Helmet>
            <QuickView ref={quickViewRef} />
            <Grid container spacing={4}>
                <Grid item xs={12} container spacing={0} alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" style={{ fontWeight: 700, display: 'flex' }}>Báo Cáo
                            <Tooltip title="Cập nhật realtime">
                                <div className={series[2] === 0 ? 'live' : 'live active'}><div>Live</div></div>
                            </Tooltip>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                        <Hidden smUp>
                            <Box height={16} />
                        </Hidden>
                        <Box display="flex" justifyContent="flex-end">
                            <Box display="flex">
                                <ReactDatePicker
                                    maxDate={new Date()}
                                    wrapperClassName="xs-w100"
                                    dateFormat="dd/MM/yyyy"
                                    onCalendarOpen={() => {
                                        blockLive.current = true
                                    }}
                                    onCalendarClose={() => {
                                        blockLive.current = false
                                    }}

                                    onChange={(update) => {
                                        setDateRange(update)
                                        ref_date_bar.current = update[1] ?? new Date()
                                    }} startDate={dateRange[0]} endDate={dateRange[1]} selectsRange isClearable
                                    customInput={(<TextField variant="outlined" size="small" label="Khoảng thời gian"
                                        inputRef={range_date_ref} fullWidth={isXs} inputProps={{ style: { minWidth: 220 } }} />)} />
                                <Box width={8} />
                                <Button onClick={getReport} disabled={data && loading} color="secondary" variant="contained"><span style={{ whiteSpace: 'nowrap' }}>{isXs ? 'Xem' : 'Tra cứu'}</span></Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                {!data && loading ? <Grid item xs={12}><LoadingData /></Grid> :
                    <>
                        <Grid item xs={12} md={5} xl={4}>
                            <ReactApexChart key={series.toString()} options={{
                                chart: {
                                    type: 'donut',
                                    width: 100,
                                },
                                labels: ['Đã Giao Hàng', 'Không Giao Được', 'Đang Xử Lý'],
                                colors: ['#8bc34a', '#e91e63', '#ff9800'],
                                dataLabels: {
                                    dropShadow: 'none',
                                    // formatter: (val,opts) => {
                                    //   return [val.toFixed(1) + '%', `(${opts.w.globals.series[opts.seriesIndex]})`]
                                    // }
                                },
                                plotOptions: {
                                    pie: {
                                        donut: {
                                            labels: {
                                                show: true,
                                                total: {
                                                    show: true,
                                                    color: '#000',
                                                    label: 'Tổng Đơn Nhận'
                                                }
                                            }
                                        }
                                    }
                                },
                                responsive: [{
                                    breakpoint: 480,
                                    options: {
                                        legend: {
                                            position: 'bottom',
                                        }
                                    }
                                }]
                            }} type="donut" series={[data.chart.da_giao_hang, data.chart.khong_giao_duoc, data.chart.dang_xu_ly]} style={{ background: '#f5fafa', borderRadius: 8 }} />
                            <Box height={16} />
                            <Grid container>
                                <Grid item xs={12}>
                                    <Paper variant="outlined" style={{ padding: 16, borderStyle: 'dashed' }}>
                                        <Box display="flex" justifyContent="space-between">
                                            <Box>Tỉ lệ hoàn đơn:</Box>
                                            <Box><b style={{ color: '#f44336' }}>{Math.ceil(data.chart.khong_giao_duoc * 10000 / data.chart.tong_don_nhan) / 100}%</b></Box>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper variant="outlined" style={{ padding: 16 }}>
                                        <Box display="flex" justifyContent="space-between" marginBottom={3}>
                                            <Typography variant="h7" style={{ fontWeight: 700 }}>Thống Kê Trạng Thái Đơn Hàng</Typography>
                                        </Box>
                                        <Grid container>
                                            {
                                                Object.keys(data.status).map((v, i) => {
                                                    if (v === 0)
                                                        return null
                                                    let sl = Object.values(data.status)[i]
                                                    let color = '#ff9800'
                                                    if ([...orderModel.status_failed, 'Không Giao Được'].indexOf(v) > -1)
                                                        color = '#e91e63'
                                                    else if (orderModel.status_success.indexOf(v) > -1)
                                                        color = '#8bc34a'
                                                    return (
                                                        <Grid item xs={6}>
                                                            <Box style={{ cursor: 'pointer' }} onClick={() => {
                                                                let [date_start, date_end] = range_date_ref.current.value.split('-')
                                                                let url_filter
                                                                if (date_start && date_end)
                                                                    url_filter = `/orders/?status=${v}&date_range=${date_start}-${date_end}#view`
                                                                else
                                                                    url_filter = `/orders/?status=${v}&date_range=null#view`
                                                                let formData = new FormData()
                                                                formData.append('status', v)
                                                                if (date_start && date_end) {
                                                                    formData.append('date_start', date_start)
                                                                    formData.append('date_end', date_end)
                                                                }
                                                                quickViewRef.current.open({
                                                                    url_filter,
                                                                    title: v + (!isXs ? ' - ' + sl.toLocaleString('en') + ' đơn' : ''),
                                                                    body: formData
                                                                })
                                                            }} className={ReportStyle.link}>{v}: <span style={{ color }}>{sl.toLocaleString('en')}</span></Box>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                            {Boolean(data.dang_cho_lay) && (
                                                <Grid item xs={6}>
                                                    <Link to="/" className={ReportStyle.link}>Đang chờ lấy: <span style={{ color: '#00bcd4' }}>{data.dang_cho_lay}</span></Link>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={7} xl={8}>
                            <Grid item container spacing={2}>
                                <Grid item xs={12}>
                                    <ReactApexChart options={bar_chart.options} series={bar_chart.series} type="bar" height={258.7} />
                                    <Box fontWeight={500} marginBottom={2} display="flex" justifyContent="space-between" alignItems="center">
                                        <Tooltip title="2 tuần trước">
                                            <IconButton disabled={loadingBack} onClick={() => handleGoBar('left')}><ArrowLeft /></IconButton>
                                        </Tooltip>
                                        Xu Hướng Lên Đơn
                                        <Tooltip title="2 tuần sau">
                                            <IconButton disabled={loadingNext || (Date.now() - ref_date_bar.current.getTime()) < 86400000} onClick={() => handleGoBar('right')}><ArrowRight /></IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Paper variant="outlined" style={{ padding: 16, borderColor: 'transparent', background: '#f4f7f9', fontSize: 14 }}>
                                        <Typography variant={t1}>Tổng thu hộ: <b style={{ fontWeight: 500 }}>{data.finance.tong_thu_ho.toLocaleString('en')}đ</b></Typography>
                                        <div className={ReportStyle.collect_wrapper}>
                                            <div className={ReportStyle.collect_value} style={{ width: (data.finance.tong_thu_ho_da_thu * 100 / data.finance.tong_thu_ho) + '%' }} />
                                        </div>
                                        Đã thu: <b style={{ fontWeight: 500 }}>{data.finance.tong_thu_ho_da_thu.toLocaleString('en')}đ</b> ( {Math.round(data.finance.tong_thu_ho_da_thu * 100 / data.finance.tong_thu_ho * 100) / 100}% )
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Paper variant="outlined" style={{ padding: 16, borderColor: 'transparent', background: '#f4f7f9', fontSize: 14 }}>
                                        <Typography variant={t1}>Tổng thu hộ: <b style={{ fontWeight: 500 }}>{data.finance.tong_thu_ho.toLocaleString('en')}đ</b></Typography>
                                        <div className={ReportStyle.collect_wrapper}>
                                            <div className={ReportStyle.collect_value} style={{ width: (data.finance.tong_cuoc * 100 / data.finance.tong_thu_ho) + '%' }} />
                                        </div>
                                        Tổng cước: <b style={{ fontWeight: 500 }}>{data.finance.tong_cuoc.toLocaleString('en')}đ</b> ( {Math.round(data.finance.tong_cuoc * 100 / data.finance.tong_thu_ho * 100) / 100}% )
                                    </Paper>
                                </Grid>
                                {!user.ung_cod
                                    ?
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <Paper variant="outlined" style={{ padding: 16, fontSize: 14 }}>
                                                <Typography variant={t1}>Tổng thu hộ các đơn hàng đang xử lý</Typography>
                                                <Typography variant={t2} style={{ fontSize: 14, fontWeight: 500 }}>{data.cod.finance.cod_tong_so_du_dang_xu_ly.toLocaleString('en')}đ</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Paper variant="outlined" style={{ padding: 16, fontSize: 14 }}>
                                                <Typography variant={t1}>Tổng tiền đã đối soát</Typography>
                                                <Typography variant={t2} style={{ fontSize: 14, fontWeight: 500 }}>{data.cod.finance.cod_tong_tien_doi_soat.toLocaleString('en')}đ</Typography>
                                            </Paper>
                                        </Grid>
                                    </>
                                    : null
                                }
                                {/*
                            <Grid item xs={12} md={6}>
                                <Paper variant="outlined" style={{ padding: 16 }}>
                                    <Typography variant={t1}>Tổng phí cả đơn đang xử lý</Typography>
                                    <Typography variant={t2}>{data.tongPhi.toLocaleString('en')}đ</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Paper variant="outlined" style={{ padding: 16 }}>
                                    <Typography variant={t1}>Cước phí trung bình / đơn lấy</Typography>
                                    <Typography variant={t2}>{data.cptb_dl.toLocaleString('en')}đ</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Paper variant="outlined" style={{ padding: 16 }}>
                                    <Typography variant={t1}>Tỉ lệ cước phí / thu hộ</Typography>
                                    <Typography variant={t2}>{data.tlcp_th.toLocaleString('en')}%</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Paper variant="outlined" style={{ padding: 16 }}>
                                    <Typography variant={t1}>Cước phí trung bình / đơn huỷ trả</Typography>
                                    <Typography variant={t2}>{data.cptb_dht.toLocaleString('en')}đ</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Paper variant="outlined" style={{ padding: 16, borderColor: '#e91e63', color: '#e91e63' }}>
                                    <Typography variant={t1}>Tổng cước phí huỷ trả</Typography>
                                    <Typography variant={t2}>{data.tong_phi_khong_giao_duoc.toLocaleString('en')}đ</Typography>
                                </Paper>
                            </Grid> */}
                            </Grid>
                        </Grid>
                    </>
                }
            </Grid>
        </>
    )
}

export default ReportsRoute