const orderModel = {
    status_success: ["Đã Giao Hàng Toàn Bộ", "Đã Giao Hàng Một Phần", "Đã Đối Soát Giao Hàng", "Đã Chuyển Kho Trả Một Phần", "Đã Trả Hàng Một Phần", "Đang Trả Hàng Một Phần", "Đã Giao Hàng"],
    status_failed: ["Đang Chuyển Kho Trả", "Đã Chuyển Kho Trả Toàn Bộ", "Đã Trả Hàng Toàn Bộ", "Đang Trả Hàng Toàn Bộ"],
    status_success_color: {
        background: "#cfe6dc",
        text: "#58a77e"
    },
    status_failed_color: {
        background: "#f1dcf4",
        text: "#b14cc3"
    },
    status_handling_color: {
        background: "#c4e6f8",
        text: "#4b95c3"
    }
}

export default orderModel