import { Badge, Box, Button, Checkbox, CircularProgress, Collapse, Fab, Grid, Hidden, IconButton, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import { Alert, Pagination } from "@material-ui/lab"
import { useSnackbar } from "notistack"
import { useContext, useEffect, useRef, useState } from "react"
import ReactDatePicker from "react-datepicker"
import { Helmet } from "react-helmet"
import { ArrowDown, ChevronUp, Filter, PaperDownload, Show, TimeSquare, Wallet } from "react-iconly"
import BalanceEl from "../../components/BalanceEl"
import LoadingData from "../../components/LoadingData"
import MobilePaper from "../../components/MobilePaper"
import QuickView from "../../components/QuickView"
import UserDetect from "../../components/UserDetect"
import color from "../../config/color"
import { dmY } from "../../config/config"
import { UserContext } from "../../contexts/user.context"
import tableStyles from '../../css/Table.module.sass'

const FinanceItem = ({ v, isXs, index, onView, handleCheck }) => {
    const [download, setDownload] = useState(false)
    const { userFetch, user } = useContext(UserContext)

    const getLink = async () => {
        if (download)
            return
        setDownload(true)
        try {
            await new Promise(res => setTimeout(() => res(true), 500))
            await userFetch('/models/finance/export_xlsx.php?control_code=' + v.control_code)
                .then(r => r.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.style.zIndex = "-1"
                    a.style.visibility = "hidden"
                    a.download = v.control_code + '.xlsx'
                    document.body.appendChild(a)
                    a.click()
                    a.remove()
                })
        } catch (e) {
            console.log(e)
        }
        setDownload(false)
    }

    if (isXs)
        return (
            <MobilePaper variant="outlined">
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={0.5}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: -12
                    }}><Checkbox onClick={() => handleCheck(index)} checked={v.checked} /><span onClick={onView} style={{ fontWeight: 700, color: color.secondary }}>{v.control_code}</span></div>
                    <Box fontSize={14}>{v.created_at}</Box>
                </Box>
                <Box display="flex" justifyContent="space-between" fontSize={14}>
                    <Box>Tổng thu hộ - {v.tong_don.toLocaleString('en')} đơn:</Box>
                    <Box><span>{v.thu_ho.toLocaleString('en')} đ</span></Box>
                </Box>
                <Box display="flex" justifyContent="space-between" fontSize={14}>
                    <Box>Tổng phí</Box>
                    <Box><span>{v.tong_phi.toLocaleString('en')} đ</span></Box>
                </Box>
                <Box display="flex" justifyContent="space-between" fontSize={14}>
                    <Box>Giảm trừ</Box>
                    <Box><span>{v.giam_tru.toLocaleString('en')} đ</span></Box>
                </Box>
                <Box display="flex" justifyContent="space-between" fontSize={14}>
                    <Box>Phí chuyển tiền</Box>
                    <Box><span>{v.phi_chuyen_tien.toLocaleString('en')} đ</span></Box>
                </Box>
                <Box display="flex" justifyContent="space-between" fontSize={14} fontWeight={500}>
                    <Box>Thực nhận</Box>
                    <Box><span>{v.thuc_nhan.toLocaleString('en')} đ</span></Box>
                </Box>
                <Box marginTop={0.5} textAlign="center" fontSize={14}>
                    <UserDetect fullSize id={v.id_shop} />
                </Box>
                {v.paid_at &&
                    <Box marginTop={0.5} textAlign="center" fontSize="14px">
                        <Wallet set="bold" color={color.svg} style={{
                            width: 16,
                            height: 16,
                            verticalAlign: -3,
                            color: 'rgb(255, 193, 68)'
                        }} /> Thanh toán lúc: {v.paid_at}
                    </Box>
                }
            </MobilePaper>
        )

    return (<Box className={tableStyles.rowWrapper}>
        <Box color="#79817A"><Checkbox onClick={() => handleCheck(index)} checked={v.checked} /></Box>
        <Box>
            <ul className={tableStyles.ul}>
                <li style={{ fontWeight: 500, fontSize: 16 }}>
                    <UserDetect style={{
                        verticalAlign: -3,
                        marginRight: 3
                    }} id={v.id_shop} />
                    <span style={{
                        color: color.secondary,
                        background: 'rgb(254 117 62 / 12%)',
                        padding: 5,
                        borderRadius: 5,
                        display: 'inline-block',
                    }}>
                        {v.control_code}
                    </span>
                </li>
                <li>
                    <Tooltip title="Ngày đối soát">
                        <Box marginTop={0.5}>
                            <TimeSquare set="broken" color={color.svg} style={{
                                width: 16,
                                height: 16,
                                verticalAlign: -3
                            }} /> Tạo lúc: {v.created_at}
                        </Box>
                    </Tooltip>
                </li>
                {v.paid_at &&
                    <li>
                        <Box marginTop={0.5}>
                            <Wallet set="bold" color={color.svg} style={{
                                width: 16,
                                height: 16,
                                verticalAlign: -3,
                                color: 'rgb(255, 193, 68)'
                            }} /> Thanh toán lúc: {v.paid_at}
                        </Box>
                    </li>}
            </ul>
        </Box>
        <Box>{v.tong_don.toLocaleString('en')}</Box>
        <Box fontWeight={500}>{v.thu_ho.toLocaleString('en')}đ</Box>
        <Box fontWeight={500}>{v.tong_phi.toLocaleString('en')}đ</Box>
        <Box fontWeight={500}>{v.giam_tru.toLocaleString('en')}đ</Box>
        <Box fontWeight={500}>{v.phi_chuyen_tien.toLocaleString('en')}đ</Box>
        <Box fontWeight="500">{v.thuc_nhan.toLocaleString('en')}đ</Box>
        <Box>
            <Tooltip title={download ? 'Đang tải' : 'Tải Excel'}>
                <IconButton onClick={getLink}>
                    {download ? <CircularProgress size={24} color="secondary" /> : <PaperDownload set="bold" color="#8bc34a" />}
                </IconButton>
            </Tooltip>
            {' '}<Tooltip title="Xem">
                <IconButton onClick={onView}>
                    <Show set="bold" color={color.svg} />
                </IconButton>
            </Tooltip>
        </Box>
    </Box>)
}

const FinanceRoute = () => {
    const [showFilter, setShowFilter] = useState(true)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const quitPage = useRef(null)
    const { userFetch, nested_account } = useContext(UserContext)
    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = useState(1)
    const [crrPage, setCrrPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [init, setInit] = useState(true)
    const isXs = useMediaQuery(useTheme().breakpoints.only('xs'))
    const [dateRangeOld, setDateRangeOld] = useState([new Date() - 86400 * 30 * 1000, new Date()])
    const [dateRange, setDateRange] = useState([new Date() - 86400 * 30 * 1000, new Date()])
    const [
        control_code_ref,
        order_code_ref,
        range_date_ref,
        quickViewRef
    ] = [useRef(null), useRef(null), useRef(null), useRef(null)]

    const handleChangePage = (_, p) => setPage(p)

    const handleSearch = () => page === 1 ? getData() : setPage(1)

    const getData = async () => {
        try {
            let [date_start, date_end] = range_date_ref.current.value.split('-')
            quitPage.current = false
            setLoading(true)
            let startTime = Date.now()
            let query = `/models/finance/query.php?page=${page}`
            if (date_start && date_end)
                query += `&date_start=${date_start}&date_end=${date_end}`
            if (control_code_ref.current.value)
                query += `&control_code=${control_code_ref.current.value}`
            if (order_code_ref.current.value)
                query += `&order_code=${order_code_ref.current.value}`
            let res = await userFetch(query)
            let resj = await res.json()
            if (quitPage.current)
                return
            let endTime = Date.now()
            if (endTime - startTime < 500)
                await new Promise(res => setTimeout(() => { res(true) }, 500 - endTime + startTime))
            setInit(false)
            setDateRangeOld(dateRange)
            setCrrPage(page)
            setData(resj.data.map(e => ({ ...e, checked: false })))
            setTotal(resj.total)
            setLoading(false)
        } catch (e) {
            enqueueSnackbar('Có lỗi xảy ra', { variant: 'error' })
        }
    }

    const handleView = (control_code, don) => {
        let formData = new FormData()
        formData.append('control_code', control_code)
        quickViewRef.current.open({
            body: formData,
            url_filter: '/orders/?control_code=' + control_code + '&date_range=null#view',
            title: isXs ? control_code : (control_code + ' - ' + don + ' đơn')
        })
    }

    const handleCheckAll = () => {
        let checked = data.filter(o => o.checked === true).length
        let cloneData
        if (checked === data.length)
            cloneData = [...data].map(v => {
                v.checked = false
                return v
            })
        else
            cloneData = [...data].map(v => {
                v.checked = true
                return v
            })
        setData(cloneData)
    }

    const handleCheck = (i) => {
        let cloneData = [...data]
        cloneData[i] = { ...cloneData[i], checked: !cloneData[i].checked }
        setData(cloneData)
    }

    useEffect(() => {
        getData()
        window.scroll({
            top: 0,
        })
        return () => {
            quitPage.current = true
        }
    }, [page])

    const checked = data.filter(e => e.checked)

    const handleDownload = () => {
        checked.map(async v => {
            await userFetch('/models/finance/export_xlsx.php?control_code=' + v.control_code)
                .then(r => r.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.style.zIndex = "-1"
                    a.style.visibility = "hidden"
                    a.download = v.control_code + '.xlsx'
                    document.body.appendChild(a)
                    a.click()
                    a.remove()
                })
        })
    }

    return (
        <>
            <Helmet>
                <title>Quản Lý Đối Soát</title>
            </Helmet>
            <QuickView ref={quickViewRef} />
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                        <Box position="relative" flex={1}>
                            <Typography variant="h6" style={{ fontWeight: 700, display: 'flex', alignItems: 'center' }}>Quản Lý Đối Soát {(loading && !init) && <CircularProgress size="1.35rem" style={{ color: color.svg, marginLeft: 12 }} />}</Typography>
                            {!init &&
                                <div className="result-searched">
                                    <b>{total.toLocaleString('en')}</b> phiên, {dmY(dateRangeOld[0]).toString() !== '01/01/70' ? `${dmY(dateRangeOld[0])} - ${dmY(dateRangeOld[1])}` : 'toàn thời gian'}
                                </div>
                            }
                        </Box>
                        <BalanceEl els={[
                            <Tooltip title="Chọn trang">
                                <IconButton size={isXs ? 'small' : 'medium'} onClick={() => window.scrollTo({
                                    top: document.body.scrollHeight,
                                    behavior: 'smooth'
                                })}>
                                    <Badge badgeContent={page} anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}>
                                        <ArrowDown set="bold" color={color.svg} size={20} />
                                    </Badge>
                                </IconButton>
                            </Tooltip>,
                            <Box width={8} />,
                            isXs ? <IconButton size="small" onClick={() => setShowFilter(!showFilter)}><Filter set="bold" color={color.svg} /></IconButton> :
                                <Button onClick={() => setShowFilter(!showFilter)} variant="outlined" style={{ color: 'rgb(121, 129, 122)', borderColor: '#aabac1' }}><Filter size={20} set="bold" style={{ marginRight: 8 }} /> Lọc Danh Sách</Button>,
                        ]} />
                    </Box>
                    <Collapse in={showFilter} style={{ marginTop: isXs ? 8 : 0 }}>
                        <Box className={tableStyles.wrapperFilter}>
                            <Grid container alignItems="center">
                                <Grid item md="auto" xs={12}>
                                    <ReactDatePicker
                                        wrapperClassName="xs-w100"
                                        maxDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(update) => {
                                            setDateRange(update);
                                        }} startDate={dateRange[0]} endDate={dateRange[1]} selectsRange isClearable
                                        customInput={(<TextField fullWidth={isXs} variant="outlined" size="small" label="Khoảng thời gian" inputRef={range_date_ref} inputProps={{ style: { minWidth: 220 } }} />)} />
                                </Grid>
                                <Grid item md="auto" xs={12}>
                                    <TextField size="small" fullWidth={isXs} inputRef={control_code_ref} variant="outlined" label="Mã đối soát" />
                                </Grid>
                                <Grid item md="auto" xs={12}>
                                    <TextField size="small" fullWidth={isXs} inputRef={order_code_ref} variant="outlined" label="Mã đơn hàng" inputProps={{ style: { minWidth: 240 } }} />
                                </Grid>
                                <Grid item md="auto" xs={12}>
                                    <Button disabled={loading} fullWidth={isXs} onClick={handleSearch} color="secondary" variant="contained">Tra cứu</Button>
                                </Grid>
                                <Hidden xsDown>
                                    <Grid item style={{ flex: 1, textAlign: 'right' }}>
                                        <IconButton onClick={() => setShowFilter(false)}>
                                            <ChevronUp color={color.svg} />
                                        </IconButton>
                                    </Grid>
                                </Hidden>
                            </Grid>
                            <Alert severity="warning" style={{ marginTop: 16 }}>
                                Mọi khiếu nại về đối soát cần liên hệ với GHSV qua hotline: <a href="tel:1900633584">1900 633 584</a> hoặc email: <a href="mailto:contact@ghsv.vn">contact@ghsv.vn</a>.<br />
                                Lưu ý: Thời hạn tiếp nhận khiếu nại <b style={{ fontWeight: 500 }}>trong vòng 72h kể từ thời điểm phiếu đối soát được tạo</b>. Mọi khiếu nại sau thời gian này sẽ không được xử lý.
                            </Alert>
                        </Box>
                    </Collapse>
                    {!isXs ?
                        <>
                            <Box className={tableStyles.preTable} />
                            <Box marginTop="-34px" />
                        </> : <Box marginTop={showFilter ? 2 : 6} />}

                    {
                        init ?
                            <Box position="relative" zIndex={2}>
                                <LoadingData />
                            </Box>
                            :
                            total === 0 ?
                                <Box className="empty">
                                    <Typography variant="h6">Trống rỗng!</Typography>
                                    <Box color={color.light}>
                                        Bạn chưa có phiên đối soát nào.
                                    </Box>
                                </Box>
                                :
                                <>
                                    {isXs ? data.map((v, i) => {
                                        return <FinanceItem handleCheck={handleCheck} onView={() => handleView(v.control_code, v.tong_don)} index={i} isXs v={v} key={i} />
                                    })
                                        :
                                        <Box className={`${tableStyles.realTable} ${tableStyles.c9}`}>
                                            <Box className={tableStyles.heading}>
                                                <Checkbox style={{ position: 'relative', top: -10 }} checked={data.filter(v => v.checked).length === data.length} onClick={handleCheckAll} />
                                            </Box>
                                            <Box className={tableStyles.heading}>Mã đối soát</Box>
                                            <Box className={tableStyles.heading}>Số đơn</Box>
                                            <Box className={tableStyles.heading}>Thu hộ</Box>
                                            <Box className={tableStyles.heading}>Tổng phí</Box>
                                            <Box className={tableStyles.heading}>Giảm trừ</Box>
                                            <Box className={tableStyles.heading}>Phí chuyển tiền</Box>
                                            <Box className={tableStyles.heading}>Thực nhận</Box>
                                            <Box className={tableStyles.heading} />
                                            {data.map((v, i) => {
                                                return <FinanceItem handleCheck={handleCheck} onView={() => handleView(v.control_code, v.tong_don)} index={i} isXs={false} v={v} key={i} />
                                            })}
                                        </Box>
                                    }
                                    {checked.length > 0 &&
                                        <Fab color="primary" style={{
                                            position: 'fixed',
                                            right: isXs ? 16 : 50,
                                            bottom: isXs ? 70 : 50,
                                        }} onClick={handleDownload}>
                                            <PaperDownload />
                                        </Fab>}
                                    <div className="pageWrapper" style={{ marginBottom: isXs ? 0 : 24 }}>
                                        <Pagination onChange={handleChangePage} page={page} color="primary" count={Math.ceil(total / 15)} shape="rounded" />
                                    </div>
                                </>
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default FinanceRoute