import { Box, Button, Container, Grid, IconButton, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import { grey } from "@material-ui/core/colors"
import { useSnackbar } from "notistack"
import { useContext, useEffect, useRef, useState } from "react"
import CountUp from "react-countup"
import { ChevronDown } from "react-feather"
import { Helmet } from "react-helmet"
import { Activity, Buy, Document, Graph, Hide, PaperUpload, Plus, Scan, Show, Star, Wallet, Work } from "react-iconly"
import { Redirect, Route, Switch, useLocation } from "react-router"
import { NavLink, useHistory } from "react-router-dom"
import BalanceEl from "../components/BalanceEl"
import CreateDialog from "../components/CreateDialog"
import Header from "../components/Header"
import Menu from "../components/Menu"
import UploadDialog from "../components/UploadDialog"
import color from "../config/color"
import { UserContext } from "../contexts/user.context"
import analyticStyles from '../css/Analytic.module.sass'
import ContactRoute from "./Contact"
import CreatersRoute from "./Creaters"
import TicketsRoute from "./Tickets"
import FinanceRoute from "./Finance"
import OrdersRoute from "./Orders"
import ReportsRoute from "./Reports"
import { app } from "../config/config"
import firebase from 'firebase'
import { Alert, AlertTitle } from "@material-ui/lab"
import platform from "platform"
import Copy from "../components/Copy"

const Analytics = ({ title, bg, count }) => {
    let icon
    let rawCount = parseInt(String(count).replace(/đ/, ''))

    switch (title) {
        case 'Đang Xử Lý':
            icon = <Scan set="bold" color="#fff" />
            break
        case 'Đơn Đã Giao':
            icon = <Star set="bold" color="#fff" />
            break
        case 'Đợi Đối Soát':
            icon = <Wallet set="bold" color="#fff" />
    }
    return (
        <BalanceEl els={[
            <div className={analyticStyles.wrapper} style={{ background: bg }}>
                {icon}
            </div>,
            <Box>
                <Box className={analyticStyles.title}>{title}</Box>
                <Box className={analyticStyles.count}>
                    {isNaN(rawCount) ? count :
                        <CountUp
                            end={rawCount}
                            duration={1.25}
                            separator="."
                            prefix={rawCount !== count ? 'đ ' : ''}
                        />
                    }
                </Box>
            </Box>
        ]} />
    )
}

const useStyles = makeStyles(theme => ({
    bottomNavigation: {
        display: 'flex',
        justifyContent: 'space-between',
        background: '#f2f2f2',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 100,
        color: '#fff',
        padding: '8px 16px',
        border: '1px solid #ddd',
        '& a': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#aab9c1',
            fontSize: 12,
            textDecoration: 'none!important',
            '&.active':
            {
                color: theme.palette.primary.main
            }
        }
    }
}))

const AppIndex = () => {
    const theme = useTheme()
    const classes = useStyles()
    const [showMoney, setShowMoney] = useState(localStorage.getItem('showBalance') === "true")
    const uploadDialogRef = useRef(null)
    const createOrderRef = useRef(null)
    const { user, miniDesign, userFetch, chi_nhanh, set_notification_token } = useContext(UserContext)
    const location = useLocation()
    const { enqueueSnackbar } = useSnackbar()
    const [cDKey, setCDKey] = useState(0)
    const [ulKey, setUlKey] = useState(0)
    const [createdKey, setCreatedKey] = useState(0)

    const touch_icon = () => {
        if (chi_nhanh.logo && chi_nhanh.logo.search('_') > -1)
            return '/system/' + chi_nhanh.logo.replace('.png', '_192.png')
        return '/v2/logo192.png'
    }
    const [analyze, setAnalyze] = useState({
        total: 0,
        process: 0,
        success: 0,
        doi_doi_soat: 0
    })
    const history = useHistory()

    const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'))

    const toggleShowMoney = () => {
        localStorage.setItem('showBalance', !showMoney)
        setShowMoney(!showMoney)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    useEffect(() => {
        if (location.state?.logged_in)
            enqueueSnackbar('Đăng nhập thành công!', { variant: 'success' })
    }, [])

    const handleCloseCreateDialog = (created) => {
        setCDKey(Date.now())
        if (created) {
            setCreatedKey(Date.now())
            history.replace('/')
        }
    }

    const handleCloseUploadDialog = (created) => {
        setUlKey(Date.now())
        if (created) {
            setCreatedKey(Date.now())
            history.replace('/')
        }
    }

    useEffect(() => {
        if (miniDesign || isMobile || !user) return
        userFetch('/models/app/fastAnalyze.php')
            .then(r => r.json())
            .then(r => {
                setAnalyze(r.data)
            })
    }, [location.pathname, user?.id])

    useEffect(() => {
        if (!user) return
        if (firebase.messaging.isSupported()) {
            console.log(app.auth().currentUser)
            app.messaging().getToken({
                vapidKey: 'BFKszka4Sy_hHRi2RoIpGfV_aA2Niq3H-G_iy5vfMp7GExNGzY7ynrxJScIRDeZK72CUIANJ9FhVbHrTRICCTNc'
            }).then((currentToken) => {
                if (currentToken) {
                    set_notification_token(currentToken)
                    let formData = new FormData()
                    formData.append('token', currentToken)
                    userFetch('/models/account/subscribe_notifications.php', {
                        method: 'POST',
                        body: formData
                    })
                    // Send the token to your server and update the UI if necessary
                    // ...
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // ...
            });
        }
    }, [])

    if (!user)
        return <Redirect to="/login" />

    return (
        <div key={user.id}>
            <Helmet>
                <meta name="theme-color" content={theme.palette.primary.main} />
                <link rel="manifest" href={chi_nhanh?.manifest ? '/system/' + chi_nhanh?.manifest : '/v2/manifest.json'} />
                <link rel="apple-touch-icon" href={touch_icon()} />
            </Helmet>
            <Header />
            {
                isMobile &&
                <div className={classes.bottomNavigation}>
                    <NavLink to="/finance">
                        <Work set="bold" />
                        Đối soát
                    </NavLink>
                    <NavLink to="/orders">
                        <Buy set="bold" />
                        Đơn hàng
                    </NavLink>
                    <NavLink exact to="/">
                        <Document set="bold" />
                        Đơn tạo
                    </NavLink>
                    <NavLink to="/report">
                        <Graph set="bold" />
                        Báo cáo
                    </NavLink>
                </div>
            }
            <Container style={{ position: 'relative' }}>
                <Grid container spacing={0}>
                    <Grid item md={2} style={{ position: 'relative' }} className="sm-hidden">
                        <Menu />
                    </Grid>
                    <Grid item style={{ flex: 1 }}>
                        <UploadDialog onClose={handleCloseUploadDialog} key={ulKey} ref={uploadDialogRef} />
                        <CreateDialog onClose={handleCloseCreateDialog} key={cDKey} ref={createOrderRef} />
                        {(!isMobile && !miniDesign) &&
                            <>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item md={4} sm={12}>
                                        <Box className="xs-hidden">
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="h6" style={{ fontWeight: 700 }}>Hành Động Nhanh</Typography> <ChevronDown style={{ marginLeft: 8 }} size={18} color={grey[500]} />
                                            </Box>
                                        </Box>
                                        <Box display="flex" marginTop={1} alignItems="center">
                                            <div className="link" onClick={() => createOrderRef.current.open()}>
                                                <BalanceEl els={[
                                                    <Plus set="bold" color={color.light} size={20} />,
                                                    <Box width={4} />,
                                                    <span style={{ color: grey[700], verticalAlign: 4, fontSize: 14 }}>Tạo đơn</span>
                                                ]} />
                                            </div>
                                            <Box width={16} />
                                            <div className="link" onClick={() => uploadDialogRef.current.open()}>
                                                <BalanceEl els={[
                                                    <PaperUpload set="bold" color={color.primary} size={20} />,
                                                    <Box width={4} />,
                                                    <span style={{ color: grey[700], verticalAlign: 4, fontSize: 14 }}>Tạo đơn bằng Excel</span>
                                                ]} />
                                            </div>
                                        </Box>
                                    </Grid>
                                    <Grid item md={5} sm={7} container>
                                        <Grid item xs={6}>
                                            <Analytics title="Đang Xử Lý" bg="#4fa8e9" count={analyze.process} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Analytics title="Đơn Đã Giao" bg="#22baa6" count={analyze.success} />
                                        </Grid>
                                    </Grid>
                                    <Grid item md={3} sm={5} xs={12} style={{ position: 'relative' }}>
                                        <div className={analyticStyles.showMoneyWrapper}>
                                            <IconButton onClick={toggleShowMoney}>
                                                {showMoney ? <Hide set="bold" color={color.svg} /> : <Show set="bold" color={color.svg} />}
                                            </IconButton>
                                        </div>
                                        <Analytics title="Đợi Đối Soát" bg="#ffc144" count={showMoney ? 'đ ' + analyze.doi_doi_soat : 'đ ••••••'} />
                                    </Grid>
                                </Grid>
                                <Box className={analyticStyles.separator} />
                            </>
                        }
                        <Box marginBottom={isMobile ? 10 : 0} marginTop={(isMobile || miniDesign) ? 0 : '60px'}>
                            {platform.os.family.match(/android/i) &&
                                <>
                                    <div style={{ position: 'relative', zIndex: 1 }}>
                                        <Alert style={{flexDirection: 'column'}} severity="warning" icon={false} action={<Button href="https://play.google.com/store/apps/details?id=com.vivoo.ghsv" target="_blank">Tải Xuống</Button>}>
                                        <AlertTitle>Đã có mặt trên Google Play</AlertTitle>
                                            Tài khoản đăng nhập app là: <span style={{ color: 'red', fontWeight: 'bold' }}><Copy text={chi_nhanh.code+'.'+user.username}>{chi_nhanh.code+'.'+user.username}</Copy></span>
                                        </Alert>
                                    </div>
                                    <Box height={isMobile ? 12 : 16} />
                                </>
                            }
                            {platform.os.family.match(/ios/i) &&
                                <>
                                    <div style={{ position: 'relative', zIndex: 1 }}>
                                        <Alert style={{flexDirection: 'column'}} severity="warning" icon={false} action={<Button href="https://apps.apple.com/us/app/giao-h%C3%A0ng-si%C3%AAu-vi%E1%BB%87t/id1602941379" target="_blank">Tải Xuống</Button>}>
                                        <AlertTitle>Đã có mặt trên App Store</AlertTitle>
                                            Tài khoản đăng nhập app là: <span style={{ color: 'red', fontWeight: 'bold' }}><Copy text={chi_nhanh.code+'.'+user.username}>{chi_nhanh.code+'.'+user.username}</Copy></span>
                                        </Alert>
                                    </div>
                                    <Box height={isMobile ? 12 : 16} />
                                </>
                            }
                            <Switch>
                                <Route path="/finance" component={FinanceRoute} />
                                <Route path="/report" component={ReportsRoute} />
                                <Route path="/orders" component={OrdersRoute} />
                                <Route path="/tickets" component={TicketsRoute} />
                                <Route path="/contact" component={ContactRoute} />
                                <Route path="*">
                                    <CreatersRoute key={createdKey} uploadDialogRef={uploadDialogRef} createOrderRef={createOrderRef} />
                                </Route>
                            </Switch>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
export default AppIndex