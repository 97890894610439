import { io } from "socket.io-client"
import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyBTOaU4cO_S-t5fJjBr9Urli29ZqEHVYzA",
  authDomain: "ghsv-ae164.firebaseapp.com",
  projectId: "ghsv-ae164",
  storageBucket: "ghsv-ae164.appspot.com",
  messagingSenderId: "849229098722",
  appId: "1:849229098722:web:11a53c8772356d376838bb",
  measurementId: "G-1PC9KR3C0F"
};
export const app = firebase.initializeApp(firebaseConfig)
export const dev = process.env.REACT_APP_DEV === "true"
export const socket = io(`//ghsv.vn:3000`, { secure: true, transports: ['websocket'] })
export const kmess = 35

export const dmY = (date) => {
    let D = new Date(date)
    let [d, m, Y] = [
        D.getDate() < 10 ? '0' + D.getDate() : D.getDate(),
        (D.getMonth() + 1) < 10 ? '0' + (D.getMonth() + 1) : D.getMonth() + 1,
        D.getFullYear().toString().substr(2, 2)
    ]
    return d + '/' + m + '/' + Y
}

export const sdmY = (date) => {
    let D = date.split('-')
    D[2] = D[2].substr(2, D[2].length)
    return D.join('/')
}

export const cities = [
    "Thành phố Hà Nội",
    "Thành phố Hồ Chí Minh",
    "Thành phố Cần Thơ",
    "Thành phố Đà Nẵng",
    "Thành phố Hải Phòng",
    "Tỉnh An Giang",
    "Tỉnh Bà Rịa - Vũng Tàu",
    "Tỉnh Bắc Giang",
    "Tỉnh Bắc Kạn",
    "Tỉnh Bạc Liêu",
    "Tỉnh Bắc Ninh",
    "Tỉnh Bến Tre",
    "Tỉnh Bình Dương",
    "Tỉnh Bình Định",
    "Tỉnh Bình Phước",
    "Tỉnh Bình Thuận",
    "Tỉnh Cà Mau",
    "Tỉnh Cao Bằng",
    "Tỉnh Đắk Lắk",
    "Tỉnh Đắk Nông",
    "Tỉnh Điện Biên",
    "Tỉnh Đồng Nai",
    "Tỉnh Đồng Tháp",
    "Tỉnh Gia Lai",
    "Tỉnh Hà Giang",
    "Tỉnh Hà Nam",
    "Tỉnh Hà Tĩnh",
    "Tỉnh Hải Dương",
    "Tỉnh Hậu Giang",
    "Tỉnh Hoà Bình",
    "Tỉnh Hưng Yên",
    "Tỉnh Khánh Hòa",
    "Tỉnh Kiên Giang",
    "Tỉnh Kon Tum",
    "Tỉnh Lai Châu",
    "Tỉnh Lâm Đồng",
    "Tỉnh Lạng Sơn",
    "Tỉnh Lào Cai",
    "Tỉnh Long An",
    "Tỉnh Nam Định",
    "Tỉnh Nghệ An",
    "Tỉnh Ninh Bình",
    "Tỉnh Ninh Thuận",
    "Tỉnh Phú Thọ",
    "Tỉnh Phú Yên",
    "Tỉnh Quảng Bình",
    "Tỉnh Quảng Nam",
    "Tỉnh Quảng Ngãi",
    "Tỉnh Quảng Ninh",
    "Tỉnh Quảng Trị",
    "Tỉnh Sóc Trăng",
    "Tỉnh Sơn La",
    "Tỉnh Tây Ninh",
    "Tỉnh Thái Bình",
    "Tỉnh Thái Nguyên",
    "Tỉnh Thanh Hóa",
    "Tỉnh Thừa Thiên Huế",
    "Tỉnh Tiền Giang",
    "Tỉnh Trà Vinh",
    "Tỉnh Tuyên Quang",
    "Tỉnh Vĩnh Long",
    "Tỉnh Vĩnh Phúc",
    "Tỉnh Yên Bái"
]
export const provinces = [
    {
        "code": "92",
        "name": "Thành phố Cần Thơ"
    },
    {
        "code": "48",
        "name": "Thành phố Đà Nẵng"
    },
    {
        "code": "1",
        "name": "Thành phố Hà Nội"
    },
    {
        "code": "31",
        "name": "Thành phố Hải Phòng"
    },
    {
        "code": "79",
        "name": "Thành phố Hồ Chí Minh"
    },
    {
        "code": "89",
        "name": "Tỉnh An Giang"
    },
    {
        "code": "77",
        "name": "Tỉnh Bà Rịa - Vũng Tàu"
    },
    {
        "code": "24",
        "name": "Tỉnh Bắc Giang"
    },
    {
        "code": "6",
        "name": "Tỉnh Bắc Kạn"
    },
    {
        "code": "95",
        "name": "Tỉnh Bạc Liêu"
    },
    {
        "code": "27",
        "name": "Tỉnh Bắc Ninh"
    },
    {
        "code": "83",
        "name": "Tỉnh Bến Tre"
    },
    {
        "code": "74",
        "name": "Tỉnh Bình Dương"
    },
    {
        "code": "52",
        "name": "Tỉnh Bình Định"
    },
    {
        "code": "70",
        "name": "Tỉnh Bình Phước"
    },
    {
        "code": "60",
        "name": "Tỉnh Bình Thuận"
    },
    {
        "code": "96",
        "name": "Tỉnh Cà Mau"
    },
    {
        "code": "4",
        "name": "Tỉnh Cao Bằng"
    },
    {
        "code": "66",
        "name": "Tỉnh Đắk Lắk"
    },
    {
        "code": "67",
        "name": "Tỉnh Đắk Nông"
    },
    {
        "code": "11",
        "name": "Tỉnh Điện Biên"
    },
    {
        "code": "75",
        "name": "Tỉnh Đồng Nai"
    },
    {
        "code": "87",
        "name": "Tỉnh Đồng Tháp"
    },
    {
        "code": "64",
        "name": "Tỉnh Gia Lai"
    },
    {
        "code": "2",
        "name": "Tỉnh Hà Giang"
    },
    {
        "code": "35",
        "name": "Tỉnh Hà Nam"
    },
    {
        "code": "42",
        "name": "Tỉnh Hà Tĩnh"
    },
    {
        "code": "30",
        "name": "Tỉnh Hải Dương"
    },
    {
        "code": "93",
        "name": "Tỉnh Hậu Giang"
    },
    {
        "code": "17",
        "name": "Tỉnh Hoà Bình"
    },
    {
        "code": "33",
        "name": "Tỉnh Hưng Yên"
    },
    {
        "code": "56",
        "name": "Tỉnh Khánh Hòa"
    },
    {
        "code": "91",
        "name": "Tỉnh Kiên Giang"
    },
    {
        "code": "62",
        "name": "Tỉnh Kon Tum"
    },
    {
        "code": "12",
        "name": "Tỉnh Lai Châu"
    },
    {
        "code": "68",
        "name": "Tỉnh Lâm Đồng"
    },
    {
        "code": "20",
        "name": "Tỉnh Lạng Sơn"
    },
    {
        "code": "10",
        "name": "Tỉnh Lào Cai"
    },
    {
        "code": "80",
        "name": "Tỉnh Long An"
    },
    {
        "code": "36",
        "name": "Tỉnh Nam Định"
    },
    {
        "code": "40",
        "name": "Tỉnh Nghệ An"
    },
    {
        "code": "37",
        "name": "Tỉnh Ninh Bình"
    },
    {
        "code": "58",
        "name": "Tỉnh Ninh Thuận"
    },
    {
        "code": "25",
        "name": "Tỉnh Phú Thọ"
    },
    {
        "code": "54",
        "name": "Tỉnh Phú Yên"
    },
    {
        "code": "44",
        "name": "Tỉnh Quảng Bình"
    },
    {
        "code": "49",
        "name": "Tỉnh Quảng Nam"
    },
    {
        "code": "51",
        "name": "Tỉnh Quảng Ngãi"
    },
    {
        "code": "22",
        "name": "Tỉnh Quảng Ninh"
    },
    {
        "code": "45",
        "name": "Tỉnh Quảng Trị"
    },
    {
        "code": "94",
        "name": "Tỉnh Sóc Trăng"
    },
    {
        "code": "14",
        "name": "Tỉnh Sơn La"
    },
    {
        "code": "72",
        "name": "Tỉnh Tây Ninh"
    },
    {
        "code": "34",
        "name": "Tỉnh Thái Bình"
    },
    {
        "code": "19",
        "name": "Tỉnh Thái Nguyên"
    },
    {
        "code": "38",
        "name": "Tỉnh Thanh Hóa"
    },
    {
        "code": "46",
        "name": "Tỉnh Thừa Thiên Huế"
    },
    {
        "code": "82",
        "name": "Tỉnh Tiền Giang"
    },
    {
        "code": "84",
        "name": "Tỉnh Trà Vinh"
    },
    {
        "code": "8",
        "name": "Tỉnh Tuyên Quang"
    },
    {
        "code": "86",
        "name": "Tỉnh Vĩnh Long"
    },
    {
        "code": "26",
        "name": "Tỉnh Vĩnh Phúc"
    },
    {
        "code": "15",
        "name": "Tỉnh Yên Bái"
    }
]