import styles from '../css/BalanceEl.module.sass'

const BalanceEl = ({els, style}) => {
    return (
        <div className={styles.wrapper} style={style}>
            {
                els.map((el, i) => (
                    <div key={i}>{el}</div>
                ))
            }
        </div>
    )
}

export default BalanceEl