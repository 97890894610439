import { Badge, Box, Button, Checkbox, Chip, CircularProgress, Collapse, FormControl, Grid, Hidden, IconButton, InputLabel, MenuItem, Select, TablePagination, TextField, Tooltip, Typography, useMediaQuery, useTheme, withStyles } from "@material-ui/core";
import { pink } from "@material-ui/core/colors";
import { Autocomplete, SpeedDial, SpeedDialAction } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Check, ChevronUp, Link } from "react-feather";
import { Helmet } from "react-helmet";
import { ArrowDown, Bag, CloseSquare, Delete, EditSquare, Filter, Image2, Login, PaperDownload, Send, Video } from "react-iconly";
import { AutoSizer, List } from 'react-virtualized';
import BalanceEl from "../../components/BalanceEl";
import Copy from "../../components/Copy";
import LoadingData from "../../components/LoadingData";
import MobilePaper from "../../components/MobilePaper";
import UserDetect from "../../components/UserDetect";
import color, { phoneStatusStyle } from "../../config/color";
import { cities, sdmY } from "../../config/config";
import textTransform from "../../config/functions/text";
import { UserContext } from "../../contexts/user.context";
import tableStyles from '../../css/Table.module.sass';

const ExcelButton = withStyles({
    root: {
        background: 'rgb(56, 167, 65)',
        '&:hover': {
            backgroundColor: 'rgb(38,135,46)!important',
        },
    },
})(Button)

const MobileCreator = ({ data, orderTool, cb, showWhat }) => {
    const bom_rate = data.bom_rate ? data.bom_rate.split(',') : false
    return <MobilePaper variant="outlined" style={{ margin: 0, borderBottom: 0 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <span style={{ fontWeight: 700 }}>
                <Copy text={data.required_code}>
                    {data.required_code}
                </Copy>
                {showWhat !== 'default' ? null : <Copy text={`https://giaohangsieuviet.com/tracking?code=${data.required_code}`} msg="Đã sao chép liên kết hành trình"><Tooltip title="Sao chép liên kết hành trình"><IconButton size="small"><Link size={20} /></IconButton></Tooltip></Copy>}
            </span>
            <Box fontSize={12}>{sdmY(data.created)}</Box>
        </Box>
        {(data.code && data.required_code != data.code) && <div style={{
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 'calc(100vw - 32px)',
            whiteSpace: 'nowrap'
        }}><Copy text={data.code}>MVĐ: {data.code}</Copy></div>}
        {data.soc && <div style={{
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 'calc(100vw - 32px)',
            whiteSpace: 'nowrap'
        }}><Copy text={data.soc}>MĐKH: {data.soc}</Copy></div>}
        <div style={{
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 'calc(100vw - 32px)',
            whiteSpace: 'nowrap',
            fontSize: 'small'
        }}>Từ: {data.send_from}</div>
        <Box marginTop={1} />
        <div style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 2
        }}><Bag set="bold" color={color.svg} size={16} style={{ marginRight: 2 }} />
            <div style={{
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 'calc(100vw - 48px)',
                whiteSpace: 'nowrap',
                fontWeight: 500,
            }}>{textTransform.ucfirst(data.product ? data.product : 'Không có sản phẩm')}</div>
        </div>
        <Box fontSize="small">
            {Number(data.weight).toLocaleString('en')}g
            {data.value == 0 ? '' : <div style={{ paddingLeft: 4, margin: '0 4px', borderLeft: '1px solid ' + color.svg, display: 'inline-block' }}>Trị giá: {Number(data.value).toLocaleString('en')}đ</div>}
            <div style={{ color: color.secondary2, display: 'inline-block', border: '1px solid ' + color.svg, borderTop: 'none', borderBottom: 'none', padding: '0 6px', margin: '0 4px' }}>COD: {Number(data.amount) >= 0 ? Number(data.amount).toLocaleString('en') + 'đ' : 'chưa tính'}</div> <span style={{ color: color.secondary }}>Phí: {Number(data.supership_value) >= 0 ? Number(data.supership_value).toLocaleString('en') + 'đ' : 'chưa tính'}</span>
        </Box>
        <Box marginTop={1} />
        <Copy text={data.phone}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 2
            }}><Send set="bold" color={color.svg} size={16} style={{ marginRight: 2 }} />
                <div style={{
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 'calc(100vw - 48px)',
                    whiteSpace: 'nowrap',
                    fontWeight: 500,
                }}><span style={{ textTransform: 'capitalize' }}>{data.name ? data.name : 'Không có người nhận'}</span>, {data.phone ? textTransform.mobile(data.phone) : 'Không có SĐT'} {bom_rate &&
                    <Tooltip title={`Tỉ lệ bom hàng`}>
                        <Chip label={bom_rate[0]} size="small" style={{ ...phoneStatusStyle[bom_rate[0]], marginLeft: 4, marginBottom: 2 }} />
                    </Tooltip>
                    }</div>
            </div>
        </Copy>
        <Box fontSize="small">{data.district ? data.district : 'Không có huyện'}, {data.province ? data.province : 'Không có tỉnh'}</Box>
        <Box fontSize="small" textOverflow="ellipsis" height={32} marginTop={1} overflow="hidden">* {data.note ? textTransform.ucfirst(data.note) : 'Không có ghi chú'}</Box>
        <Box display="flex" marginLeft={-1.5} justifyContent="space-between" alignItems="center">
            <Box display="flex">
                {cb} {orderTool}
            </Box>
            <Box fontSize={14}>
                {data.tich_xanh === "1" && <Box color={color.secondary2} fontWeight={500}>Đã IN</Box>}
                {data.error && <Box color="#f11042" fontWeight={500}>Lỗi Excel</Box>}
            </Box>
        </Box>
    </MobilePaper>
}

const OrderTool = ({ photos_url, dvvc, id, orderCode, onDelete, onTichXanh, onEdit, showWhat, customerId, raw }) => {
    const [confirmDelete, setConfirmDelete] = useState(0)
    const { enqueueSnackbar } = useSnackbar()
    const [open, setOpen] = useState(false)
    const { userFetch, user, nested_account, pVRef, chi_nhanh } = useContext(UserContext)
    const showError = showWhat === 'error'

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleDelete = async () => {
        if (confirmDelete === 2)
            return
        setConfirmDelete(confirmDelete + 1)
        if (confirmDelete === 1) {
            try {
                let url = '/models/pending/methods.php?id=' + id
                if (showError)
                    url += '&error'
                let res = await userFetch(url, {
                    method: 'DELETE',
                })
                if (res.status !== 200)
                    enqueueSnackbar('Hủy đơn ' + orderCode + ' thất bại', { variant: 'error' })
                else {
                    enqueueSnackbar('Hủy đơn ' + orderCode + ' thành công', { variant: 'success' })
                    onDelete()
                }
            } catch (e) {
                console.log(e)
                setConfirmDelete(0)
                enqueueSnackbar('Có lỗi xảy ra', { variant: 'error' })
            }
        }
    }

    useEffect(() => {
        if (confirmDelete !== 1)
            return;
        let t = setTimeout(() => {
            enqueueSnackbar('Ngừng hủy đơn ' + orderCode, { variant: 'warning' })
            setConfirmDelete(0)
        }, 5000)
        return () => {
            console.log('cleared');
            clearTimeout(t)
        }
    }, [confirmDelete])

    const handleInA5 = () => {
        if (showError)
            return enqueueSnackbar('Không thể in đơn lỗi!', { variant: 'error' })
        window.open((chi_nhanh.domain ? 'https://' + chi_nhanh.domain : window.location.origin) + '/khachhang/api/create_order/print?list_id=' + id, '_blank')
        onTichXanh()
    }

    const handleInS9 = () => {
        if (showError)
            return enqueueSnackbar('Không thể in đơn lỗi!', { variant: 'error' })
        window.open((chi_nhanh.domain ? 'https://' + chi_nhanh.domain : window.location.origin) + '/khachhang/api/create_order/print?type=s9&list_id=' + id, '_blank')
        onTichXanh()
    }

    return (
        <Box marginLeft="-4px" display="flex" alignItems="center">
            <Box>
                {(user.user_ref === "0" && nested_account.length) ?
                    <>
                        <IconButton size="small" style={{ cursor: 'default' }}>
                            <UserDetect id={customerId} style={{
                                width: 20,
                                height: 20
                            }} />
                        </IconButton>{' '}
                    </> : null
                }
                {raw.status_cancel === '1' ? <Button onClick={onEdit} color="secondary" startIcon={<Login set="bold" />}> Kéo lại đơn</Button> :
                    <>
                        <Tooltip title="Sửa">
                            <IconButton disabled={(dvvc !== null && dvvc != 'NB') ? true : false} size="small" color="secondary" onClick={onEdit}><EditSquare set="bold" /></IconButton>
                        </Tooltip>{' '}
                    </>
                }
                {raw.status_cancel !== '1' && <><Tooltip title={['Hủy', 'Xác nhận hủy', 'Đang hủy'][confirmDelete]}>
                    <IconButton onClick={handleDelete} size="small">
                        {[<CloseSquare set="bold" color={color.secondary} />, <Check color={color.secondary} />, <CircularProgress size={20} style={{ color: color.secondary }} />][confirmDelete]}
                    </IconButton>
                </Tooltip>{' '}</>}
            </Box>
            {(raw.status_cancel === '0' && !raw.error) &&
                <Box>
                    <SpeedDial onClose={handleClose} FabProps={{
                        style: {
                            background: 'transparent',
                            boxShadow: 'none',
                            width: 20,
                            marginLeft: 10,
                            height: 'auto'
                        }
                    }}
                        transitionDuration={0}
                        onOpen={handleOpen} ariaLabel="SpeedDial example" open={open} direction="right" icon={
                            <IconButton size="small">
                                <Box display="flex" alignItems="center" justifyContent="center" color="#fff" borderRadius={4} fontWeight={500} bgcolor={color.svg} fontSize={14} width={20} height={20}>in</Box>
                            </IconButton>
                        }>
                        <SpeedDialAction FabProps={{
                            style: {
                                height: 24,
                                width: 24,
                                minHeight: 24,
                                background: '#fff',
                                boxShadow: 'none'
                            }
                        }} tooltipPlacement="bottom" tooltipTitle="In A5" onClick={handleInA5} icon={<span>A5</span>} />
                        <SpeedDialAction FabProps={{
                            style: {
                                height: 24,
                                width: 24,
                                minHeight: 24,
                                background: '#fff',
                                boxShadow: 'none'
                            }
                        }} tooltipPlacement="bottom" tooltipTitle="In S9" onClick={handleInS9} icon={<span>S9</span>} />
                    </SpeedDial>
                </Box>
            }
            {(photos_url && photos_url != '') &&
                <Tooltip title="Xem hình ảnh">
                    <IconButton size="small" onClick={() => {
                        pVRef.current.open(0, photos_url.split(','))
                    }}>
                        <Image2 color={color.svg} set="bold" />
                    </IconButton>
                </Tooltip>
            }
        </Box>
    )
}

const actions = [{
    icon: <span>A5</span>,
    name: 'IN A5'
}, {
    icon: <span>S9</span>,
    name: 'IN S9',
}]

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 7.5 + 8,
            width: 250
        },
        variant: 'outlined',
    },
    getContentAnchorEl: null
}

const CreatersRoute = ({ createOrderRef, uploadDialogRef }) => {
    const [init, setInit] = useState(true)
    const [downloadExcel, setDownloadExcel] = useState(false)
    const [downloadExcelPopup, setDownloadExcelPopup] = useState(false)
    const [deletePopup, setDeletePopup] = useState(0)
    const [open, setOpen] = useState(false)
    const [hidden, setHidden] = useState(0)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [totalPagination, setTotalPagination] = useState(0)
    const [error, setError] = useState(0)
    const [cancel, setCancel] = useState(0)
    const [showWhat, setShowWhat] = useState('default') // default, error, cancel
    const [loading, setLoading] = useState(true)
    const [city, setCity] = useState('')
    const [district, setDistrict] = useState(null)
    const [districts, setDistricts] = useState([])
    const [data, setData] = useState([])
    const { enqueueSnackbar } = useSnackbar()
    const { userFetch, miniDesign, chi_nhanh } = useContext(UserContext)
    const [showFilter, setShowFilter] = useState(false)
    const [printStatus, setPrintStatus] = useState('')
    const [phoneStatus, setPhoneStatus] = useState('')
    const [rowsPerpage, setRowsPerPage] = useState(35)
    const [order_code_ref] = [useRef(null)]
    const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'))
    const [dateRange, setDateRange] = useState([new Date(Date.now() - 86400 * 30 * 1000), new Date()])
    const range_date_ref = useRef(null)
    const handleSearch = () => page === 0 ? getData() : setPage(0)
    const showError = showWhat == 'error'

    const handleShow = (what) => {
        setShowWhat(what)
        setPage(0)
    }

    useEffect(() => {
        if (init) return
        getData()
    }, [showWhat])

    const getData = async () => {
        try {
            setLoading(true)
            let formData = new FormData()
            if (showWhat != 'default')
                formData.append('showWhat', showWhat)
            if (printStatus)
                formData.append('tich_xanh', printStatus)
            if (district)
                formData.append('district', district)
            if (city)
                formData.append('province', city)
            if (order_code_ref.current.value)
                formData.append('order_code', order_code_ref.current.value)
            if (phoneStatus)
                formData.append('bom_rate', phoneStatus)
            if (dateRange[0] && dateRange[1]) {
                let [date_start, date_end] = dateRange.map(v => {
                    return v.getDate() + '/' + (v.getMonth() + 1) + '/' + v.getFullYear()
                })
                console.log(date_start, date_end)
                formData.append('date_start', date_start)
                formData.append('date_end', date_end)
            }
            const res = await userFetch('/models/pending/query.v2.php?kmess=' + rowsPerpage + '&page=' + (Number(page) + 1), {
                method: 'POST',
                body: formData
            })
            let startLoad = Date.now()
            const resj = await res.json()
            let endLoad = Date.now()
            if (endLoad - startLoad < 500)
                await new Promise(res => setTimeout(() => res(true), 500))
            setLoading(false)
            setInit(false)
            if (!showError) {
                if (showWhat === 'default')
                    setTotalPagination(resj.total ?? 0)
                else if (showWhat === 'cancel')
                    setTotalPagination(resj.cancel ?? 0)
                setTotal(resj.total ?? 0)
                setError(resj.error ?? 0)
                setCancel(resj.cancel ?? 0)
            } else {
                setTotalPagination(resj.error ?? 0)
            }
            setData(resj.orders.map(v => {
                v.checked = false
                if (showError) {
                    v.note = v.note ?? ''
                    v.error = true
                }
                return v
            }))
        } catch (e) {
            console.log(e)
            enqueueSnackbar('Có lỗi xảy ra!', {
                variant: 'error'
            })
        }
    }

    useEffect(() => {
        getData()
        setHidden(0)
        window.scroll({
            top: 0,
        })
    }, [page, rowsPerpage])

    const handleChangePage = (_, p) => setPage(p)

    const handleChangeCity = async (_, newValue) => {
        try {
            if (!newValue) {
                setCity('')
                setDistricts([])
                setDistrict(null)
                return
            }
            let res = await userFetch(`/models/app/query/address.php?act=get_district_by_city&city=${newValue}`)
            let resj = await res.json()
            setDistricts(resj.data)
            setCity(newValue)
        } catch (e) {
            console.log(e)
        }
    }

    const handleCheckAll = () => {
        let checked = data.filter(o => o.checked === true).length
        let cloneData
        if (checked === data.length)
            cloneData = [...data].map(v => {
                v.checked = false
                return v
            })
        else
            cloneData = [...data].map(v => {
                v.checked = true
                return v
            })
        setData(cloneData)
    }

    const handleDelete = (i) => {
        let cloneData = [...data]
        cloneData.splice(i, 1)
        let _total = showError ? error : total
        if (cloneData.length === 0 && _total - 1 > 0) {
            if (page < Math.ceil((_total - 1) / 35))
                getData()
            else
                setPage(page - 1)
            setHidden(0)
        } else {
            setData(cloneData)
            if (!showError)
                setTotal(total - 1)
            else
                setError(error - 1)
        }
    }

    const handleCheck = (i) => {
        let cloneData = [...data]
        cloneData[i] = { ...cloneData[i], checked: !cloneData[i].checked }
        setData(cloneData)
    }

    useEffect(() => {
        let checked = data.filter(o => o.checked === true).length
        setHidden(checked)
    }, [data.filter(o => o.checked === true)])

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleTichXanh = (i) => {
        let cloneData = [...data]
        cloneData[i].tich_xanh = "1"
        setData(cloneData)
    }

    const handleActions = (name) => {
        let list_id = data.filter(v => v.checked).map(v => v.id).join(',')
        switch (name) {
            case 'IN A5':
                if (showError)
                    return enqueueSnackbar('Không thể in đơn lỗi!', { variant: 'error' });
                window.open((chi_nhanh.domain ? 'https://' + chi_nhanh.domain : window.location.origin) + '/khachhang/api/create_order/print?list_id=' + list_id, '_blank')
                break
            case 'IN S9':
                if (showError)
                    return enqueueSnackbar('Không thể in đơn lỗi!', { variant: 'error' });
                window.open((chi_nhanh.domain ? 'https://' + chi_nhanh.domain : window.location.origin) + '/khachhang/api/create_order/print?type=s9&list_id=' + list_id, '_blank')
                break
        }
        let cloneData = data.map(v => {
            if (v.checked)
                v.tich_xanh = "1"
            return v
        })
        setData(cloneData)
        setOpen(false)
    }

    const handleDownloadExcel = async () => {
        if (downloadExcel) return
        setDownloadExcel(true)
        let formData = new FormData()
        if (printStatus)
            formData.append('tich_xanh', printStatus)
        if (district)
            formData.append('district', district)
        if (city)
            formData.append('province', city)
        if (order_code_ref.current.value)
            formData.append('order_code', order_code_ref.current.value)
        if (showWhat != 'default')
            formData.append('showWhat', showWhat)
        await userFetch('/models/pending/query.v2.php?export_excel', {
            method: 'POST',
            body: formData
        })
            .then(r => r.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.style.zIndex = "-1"
                a.style.visibility = "hidden"
                a.download = 'ĐƠN_HÀNG_ĐÃ_TẠO.xlsx'
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
        setDownloadExcel(false)
    }

    const handleDownloadExcelPopup = async () => {
        if (downloadExcelPopup) return
        setDownloadExcelPopup(true)
        let list_id = data.filter(v => v.checked).map(v => v.required_code).join("\n")
        let formData = new FormData()
        formData.append('order_code', list_id)
        if (showWhat != 'default')
            formData.append('showWhat', showWhat)
        await userFetch('/models/pending/query.v2.php?export_excel', {
            method: 'POST',
            body: formData
        })
            .then(r => r.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.style.zIndex = "-1"
                a.style.visibility = "hidden"
                a.download = 'ĐƠN_HÀNG_ĐÃ_TẠO_CHECKED.xlsx'
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
        setDownloadExcelPopup(false)
        setOpen(false)
    }

    const handleDeletePopup = async () => {
        if (deletePopup === 2)
            return
        let list_id = data.filter(v => v.checked).map(v => v.id).join(',')
        setDeletePopup(deletePopup + 1)
        if (deletePopup === 1) {
            try {
                let url = '/models/pending/methods.php?id=' + list_id
                if (showError)
                    url += '&error'
                let res = await userFetch(url, {
                    method: 'DELETE',
                })
                if (res.status !== 200)
                    enqueueSnackbar('Hủy các đơn chọn thất bại', { variant: 'error' })
                else {
                    setOpen(false)
                    enqueueSnackbar('Hủy các đơn chọn thành công', { variant: 'success' })
                    let remainData = data.filter(v => v.checked === false)
                    let removeData = data.filter(v => v.checked)
                    if (remainData.length === 0 && total - removeData.length > 0) {
                        if (page < Math.ceil((total - removeData.length) / 35))
                            getData()
                        else
                            setPage(page - 1)
                        setHidden(0)
                    } else {
                        setData(remainData)
                        if (!showError)
                            setTotal(total - removeData.length)
                        else
                            setError(error - removeData.length)
                    }
                }
            } catch (e) {
                console.log(e)
                enqueueSnackbar('Có lỗi xảy ra', { variant: 'error' })
            } finally {
                setDeletePopup(0)
            }
        }
    }

    const handleChangeRowsPerPage = (event) => {
        console.log(event.target.value)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleUpdate = (i, newData) => {
        if (data[i].error) {
            handleDelete(i)
            setError(error - 1)
        } else {
            let cloneData = [...data]
            cloneData[i] = newData
            setData(cloneData)
        }
    }

    const handleEdit = (i) => {
        createOrderRef.current.edit({ ...data[i], update: handleUpdate, index: i })
    }

    useEffect(() => {
        if (deletePopup !== 1)
            return;
        let t = setTimeout(() => {
            enqueueSnackbar('Ngừng hủy đơn chọn', { variant: 'warning' })
            setDeletePopup(0)
        }, 5000)
        return () => {
            console.log('cleared');
            clearTimeout(t)
        }
    }, [deletePopup])


    return (
        <>
            <Helmet>
                <title>Đơn Hàng Chờ Duyệt</title>
            </Helmet>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Grid container spacing={0} alignItems="center">
                        <Grid item md="auto" style={{ flex: 1, position: 'relative' }}>
                            <Typography variant="h6" style={{ fontWeight: 700, display: 'flex', alignItems: 'center' }}> Đơn Hàng Chờ Duyệt {(loading && !init) && <CircularProgress size="1.25rem" style={{ color: color.svg, marginLeft: 12 }} />}</Typography>
                            {!init &&
                                <div className="result-searched">
                                    <a href="#!" style={{ color: 'inherit' }} onClick={() => handleShow('default')}><b>{total.toLocaleString('en')}</b> đơn hàng</a>,
                                    {' '}<a onClick={() => handleShow('error')} href="#!" style={{ color: error > 0 ? '#f11747' : 'inherit' }}><b>{error.toLocaleString('en')}</b> đơn lỗi</a>,
                                    {' '}<a onClick={() => handleShow('cancel')} href="#!" style={{ color: cancel > 0 ? '#ff9800' : 'inherit' }}><b>{cancel.toLocaleString('en')}</b> đơn hủy</a>
                                </div>}
                        </Grid>
                        <Grid item md="auto">
                            <BalanceEl els={[
                                <Tooltip title="Tải xuống Excel">
                                    <IconButton size={isMobile ? 'small' : 'medium'} onClick={handleDownloadExcel}>
                                        {downloadExcel ? <CircularProgress size={20} /> : <PaperDownload size={20} set="bold" color={color.light} />}
                                    </IconButton>
                                </Tooltip>,
                                <Tooltip title="Chọn trang">
                                    <IconButton size={isMobile ? 'small' : 'medium'} onClick={() => window.scrollTo({
                                        top: document.body.scrollHeight,
                                        behavior: 'smooth'
                                    })}>
                                        <Badge badgeContent={page} anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}>
                                            <ArrowDown set="bold" color={color.svg} size={20} />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>,
                                <Box width={8} />,
                                isMobile ? <IconButton size="small" onClick={() => setShowFilter(!showFilter)}><Filter set="bold" color={color.svg} /></IconButton> :
                                    <Button onClick={() => setShowFilter(!showFilter)} variant="outlined" style={{ color: 'rgb(121, 129, 122)', borderColor: '#aabac1' }}><Filter size={20} set="bold" style={{ marginRight: 8 }} /> Lọc Danh Sách</Button>,
                                (miniDesign && !isMobile) ?
                                    <div style={{
                                        marginLeft: 8,
                                    }}>
                                        <BalanceEl
                                            els={[
                                                <ExcelButton onClick={() => uploadDialogRef.current.open()} variant="contained" style={{ background: '#38a741', color: '#fff' }}>Tải lên Excel</ExcelButton>,
                                                <Box width={8} />,
                                                <Button onClick={() => createOrderRef.current.open()} variant="contained" color="secondary">Tạo đơn hàng</Button>
                                            ]}
                                        />
                                    </div> : null
                            ]} />
                        </Grid>
                    </Grid>
                    <Hidden mdUp>
                        <Box marginTop={4} display="flex">
                            <Box marginLeft={-1.5}>
                                <Checkbox checked={hidden === data.length && hidden > 0} onClick={handleCheckAll} />
                            </Box>
                            <Button onClick={() => uploadDialogRef.current.open()} variant="outlined" style={{ color: '#38a740', borderColor: '#38a740' }} >Tải lên Excel</Button>
                            <Box width={8} />
                            <Button onClick={() => createOrderRef.current.open()} variant="outlined" color="secondary" style={{ flex: 1 }}>Tạo đơn hàng</Button>
                        </Box>
                    </Hidden>
                    <Collapse in={showFilter}>
                        <Box className={tableStyles.wrapperFilter}>
                            <Grid container>

                                <Grid item md="auto" xs={12}>
                                    <ReactDatePicker
                                        wrapperClassName="xs-w100"
                                        maxDate={new Date()}
                                        dateFormat="dd/MM/Y"
                                        onChange={(update) => {
                                            setDateRange(update);
                                        }} startDate={dateRange[0]} endDate={dateRange[1]} selectsRange isClearable
                                        customInput={(<TextField fullWidth={isMobile} variant="outlined" inputRef={range_date_ref} size="small" label="Khoảng thời gian" inputProps={{ style: { width: 220 } }} />)} />
                                </Grid>
                                <Grid item md="3" xs={12}>
                                    <Autocomplete value={city === '' ? null : city} onChange={handleChangeCity} options={cities}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="Tỉnh/Thành phố" size="small" />}
                                    />
                                </Grid>
                                <Grid item md="3" xs={12}>
                                    <Autocomplete value={district === '' ? null : district} onChange={(_, newValue) => setDistrict(newValue)} options={districts}
                                        loadingText={<CircularProgress size={20} />}
                                        loading={city && districts.length === 0}
                                        noOptionsText={city ? 'Không tìm thấy huyện' : 'Hãy chọn tỉnh trước'}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="Huyện/Quận" size="small" />}
                                    />
                                </Grid>
                                <Grid item md="auto" xs={12}>
                                    <FormControl fullWidth={isMobile} size="small" variant="outlined" style={{ minWidth: 110 }}>
                                        <InputLabel id="demo-simple-select-outlined-label">In</InputLabel>
                                        <Select
                                            inputProps={{ minWidth: 110 }}
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={printStatus}
                                            onChange={(e) => setPrintStatus(e.target.value)}
                                            label="In"
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value=""><em>Không chọn</em></MenuItem>
                                            <MenuItem value="0">Chưa In</MenuItem>
                                            <MenuItem value={1}>Đã In</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md="auto" xs={12}>
                                    <FormControl fullWidth={isMobile} size="small" variant="outlined" style={{ minWidth: 220 }}>
                                        <InputLabel id="demo-simple-select-outlined-label">Tỉ lệ bom hàng</InputLabel>
                                        <Select
                                            inputProps={{ minWidth: 220 }}
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={phoneStatus}
                                            onChange={(e) => setPhoneStatus(e.target.value)}
                                            label="Tỉ lệ hoàn đơn"
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value=""><em>Không chọn</em></MenuItem>
                                            <MenuItem value="AN TOÀN">AN TOÀN</MenuItem>
                                            <MenuItem value="NGUY CƠ THẤP">NGUY CƠ THẤP</MenuItem>
                                            <MenuItem value="NGUY CƠ CAO">NGUY CƠ CAO</MenuItem>
                                            <MenuItem value="NGUY HIỂM">NGUY HIỂM</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md="auto" xs={12}>
                                    <TextField multiline size="small" helperText="Mỗi dòng 1 giá trị" fullWidth={isMobile} inputRef={order_code_ref} variant="outlined" label="Mã yêu cầu / SĐT" inputProps={{ style: { minWidth: 220 } }} />
                                </Grid>
                                <Grid item md="auto" xs={12}>
                                    <Button style={{ height: 40 }} disabled={loading} fullWidth={isMobile} onClick={handleSearch} color="secondary" variant="contained">Tra cứu</Button>
                                </Grid>
                                <Hidden lgDown>
                                    <Grid item style={{ flex: 1, textAlign: 'right' }}>
                                        <IconButton onClick={() => setShowFilter(false)}>
                                            <ChevronUp color={color.svg} />
                                        </IconButton>
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </Box>
                    </Collapse>
                    {!isMobile ? <>
                        <Box className={tableStyles.preTable} />
                        <Box marginTop="-34px" /></>
                        :
                        <Box marginTop={showFilter ? 2 : 4} />
                    }
                    {init ? <Box position="relative" zIndex={2}>
                        <LoadingData />
                    </Box>
                        : (totalPagination === 0 || !data.length) ?
                            <Box className="empty">
                                <Typography variant="h6">Trống rỗng!</Typography>
                                <Box color={color.light}>Không tìm thấy đơn hàng nào cả.</Box>
                            </Box>
                            :
                            <>
                                {!isMobile ?
                                    <>
                                        <div className={`${tableStyles.realTable} ${tableStyles.c5}`} style={{ position: 'sticky', zIndex: 3, top: 80 }}>
                                            <div className={tableStyles.heading}>Mã yêu cầu</div>
                                            <div className={tableStyles.heading}>Sản phẩm</div>
                                            <div className={tableStyles.heading}>Người nhận</div>
                                            <div className={tableStyles.heading}>Ghi chú</div>
                                            <div className={tableStyles.heading}>
                                                <Box marginTop="-12px"><Checkbox checked={hidden === data.length && hidden > 0} onClick={handleCheckAll} /></Box>
                                            </div>
                                        </div>
                                        <div className={tableStyles.oversize}>
                                            <AutoSizer disableHeight>
                                                {({ width }) => (
                                                    <List containerStyle={{ position: 'relative' }} width={width} height={141 * (data.length >= 35 ? 35 : data.length)} rowHeight={141} rowCount={data.length}
                                                        rowRenderer={({ key, index, style }) => {
                                                            const i = index
                                                            const order = data[i]
                                                            const bom_rate = order.bom_rate ? order.bom_rate.split(',') : false
                                                            return (
                                                                <div style={style} key={key}>
                                                                    <div className={tableStyles.rowWrapperOutside}>
                                                                        <div>
                                                                            <ul className={tableStyles.ul}>
                                                                                <li style={{ fontWeight: 500 }}>
                                                                                    <Copy text={order.required_code}>
                                                                                        {order.required_code}
                                                                                    </Copy>
                                                                                </li>
                                                                                {(order.code && order.code != order.required_code) && <li style={{ color: '#333' }}><Copy text={order.code}>MVĐ: {order.code}</Copy></li>}
                                                                                {order.soc && <li style={{ color: '#333' }}><Copy text={order.soc}>MĐKH: {order.soc}</Copy></li>}
                                                                                <li>Tạo lúc: {order.created}</li>
                                                                                <li>Từ: {order.send_from}</li>
                                                                            </ul>
                                                                            <OrderTool raw={order} photos_url={order.photos_url} tich_xanh={order.tich_xanh} dvvc={order.dvvc} key={order.id} showWhat={showWhat} onEdit={() => handleEdit(i)} onTichXanh={() => handleTichXanh(i)} onDelete={() => handleDelete(i)} orderCode={order.required_code} customerId={order.customer_id} id={order.id} />
                                                                        </div>
                                                                        <div>
                                                                            <ul className={tableStyles.ul}>
                                                                                <li><Tooltip title={textTransform.ucfirst(order.product)}><span>{textTransform.ucfirst(order.product ? order.product : 'Không có sản phẩm')}</span></Tooltip></li>
                                                                                <li>Khối lượng: {Number(order.weight).toLocaleString('en')}g</li>
                                                                                {order.value == 0 ? '' : <li>Trị giá: {Number(order.value).toLocaleString('en')}đ</li>}
                                                                                <li style={{ color: color.secondary2 }}>Thu hộ: {Number(order.amount) >= 0 ? Number(order.amount).toLocaleString('en') + 'đ' : 'chưa xác định'}</li>
                                                                                <li style={{ color: color.secondary }}>Phí dịch vụ: {Number(order.supership_value) >= 0 ? Number(order.supership_value).toLocaleString('en') + 'đ' : 'chưa xác định'}</li>
                                                                            </ul>
                                                                        </div>
                                                                        <div>
                                                                            <ul className={tableStyles.ul}>
                                                                                <li>{order.province ? order.province : 'Không có tỉnh'}{' '}
                                                                                    {showWhat !== 'default' ? null : <Copy text={`https://giaohangsieuviet.com/tracking?code=${order.required_code}`} msg="Đã sao chép liên kết hành trình"><Tooltip title="Sao chép liên kết hành trình"><IconButton size="small"><Link size={20} /></IconButton></Tooltip></Copy>}
                                                                                </li>
                                                                                <li style={{ textTransform: 'capitalize' }}>{order.name ?? 'Không có người nhận'}</li>
                                                                                <li>
                                                                                    {order.phone ?
                                                                                        <Copy text={order.phone}>
                                                                                            {textTransform.mobile(order.phone)}
                                                                                        </Copy> : 'Không có sđt'
                                                                                    }
                                                                                    {bom_rate &&
                                                                                        <Tooltip title={`Tỉ lệ bom hàng`}>
                                                                                            <Chip label={bom_rate[0]} size="small" style={{ ...phoneStatusStyle[bom_rate[0]], marginLeft: 4, marginBottom: 2 }} />
                                                                                        </Tooltip>
                                                                                    }
                                                                                </li>
                                                                                {order.address ? <Tooltip title={textTransform.ucfirst(order.address)}><li>{textTransform.ucfirst(order.address)}</li></Tooltip> : 'Không có địa chỉ'}

                                                                            </ul>
                                                                        </div>
                                                                        <div>
                                                                            {(order.note || order.tich_xanh || order.error) &&
                                                                                <Tooltip title={textTransform.ucfirst(order.note ?? 'Không có ghi chú.')}>
                                                                                    <div className={tableStyles.note}>
                                                                                        {order.error && <Box color="#f11042" fontWeight={500}>Đơn lỗi Excel</Box>}
                                                                                        {order.tich_xanh === "1" && <Box color={color.secondary2} fontWeight={500}>Đã in mã vận đơn</Box>}
                                                                                        {textTransform.ucfirst(order.note || 'Không có ghi chú.')}
                                                                                    </div>
                                                                                </Tooltip>
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            <Checkbox onClick={() => handleCheck(i)} checked={order.checked} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }} />
                                                )}
                                            </AutoSizer>
                                        </div>
                                    </>
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'column', margin: '0 -16px' }}>
                                        <AutoSizer disableHeight>
                                            {({ width }) => {

                                                return (
                                                    <List width={width} height={240 * (data.length >= 35 ? 35 : data.length)} rowCount={data.length} rowHeight={240} rowRenderer={({
                                                        key,
                                                        index,
                                                        style,
                                                    }) => {
                                                        let i = index
                                                        let order = data[i]
                                                        return (
                                                            <div style={style} key={key}>
                                                                <MobileCreator showWhat={showWhat} cb={<Checkbox onClick={() => handleCheck(i)} checked={order.checked} />} orderTool={<OrderTool raw={order} photos_url={order.photos_url} tich_xanh={order.tich_xanh} dvvc={order.dvvc} key={order.id} showWhat={showWhat} onEdit={() => handleEdit(i)} onTichXanh={() => handleTichXanh(i)} onDelete={() => handleDelete(i)} orderCode={order.required_code} id={order.id} />} data={order} />
                                                            </div>
                                                        )
                                                    }} />
                                                )
                                            }}
                                        </AutoSizer>
                                    </div>
                                    // data.map((order, i) => <MobileCreator cb={<Checkbox onClick={() => handleCheck(i)} checked={order.checked} />} orderTool={<OrderTool showError={showError} onEdit={() => handleEdit(i)} onTichXanh={() => handleTichXanh(i)} onDelete={() => handleDelete(i)} orderCode={order.required_code} id={order.id} />} data={order} key={order.id} />)
                                }

                                <div className="pageWrapper" style={{ marginBottom: isMobile ? 0 : 24, marginTop: isMobile ? 0 : null }}>
                                    {/* <Pagination onChange={handleChangePage} page={page} color="primary" count={Math.ceil(totalPagination / kmess)} shape="rounded" /> */}
                                    <TablePagination
                                        style={{ overflow: 'hidden', margin: '0 -16px' }}
                                        component="div"
                                        count={totalPagination}
                                        labelRowsPerPage={isMobile ? 'Hiển thị:' : 'Hiển thị mỗi trang:'}
                                        nextIconButtonText="Trang sau"
                                        labelDisplayedRows={({ from, to, count }) => from + '-' + to + ' / ' + count}
                                        rowsPerPageOptions={[35, 100, 200, 500]}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        rowsPerPage={rowsPerpage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </div>
                            </>
                    }
                </Grid>
            </Grid>
            <SpeedDial
                ariaLabel="SpeedDial openIcon example"
                hidden={!Boolean(hidden)}
                FabProps={{
                    style: {
                        background: pink[500],
                        fontWeight: 500,
                        fontSize: 20
                    }
                }}
                icon={<span>{hidden}</span>}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                style={{
                    position: 'fixed',
                    right: isMobile ? 16 : 50,
                    bottom: isMobile ? 70 : 50,
                }}
            >
                {showWhat !== 'default' ? null : actions.map((action) => (
                    <SpeedDialAction
                        FabProps={{
                            style: {
                                color: action.bg,
                            }
                        }}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => handleActions(action.name)}
                    />
                ))}
                <SpeedDialAction
                    FabProps={{
                        style: {
                            color: color.primary
                        }
                    }}
                    icon={downloadExcelPopup ? <CircularProgress size={20} /> : <PaperDownload set="bold" />}
                    tooltipTitle={downloadExcelPopup ? 'Đang tải' : 'Tải Excel'}
                    onClick={handleDownloadExcelPopup}
                />
                {showWhat === 'cancel' ? null :
                    <SpeedDialAction
                        FabProps={{
                            style: {
                                color: color.secondary
                            }
                        }}
                        icon={[<Delete set="bold" />, <Check color={color.secondary} />, <CircularProgress size={20} style={{ color: color.secondary }} />][deletePopup]}
                        tooltipTitle={['Hủy đã chọn', 'Xác nhận', 'Đang hủy'][deletePopup]}
                        onClick={handleDeletePopup}
                    />
                }
            </SpeedDial>
        </>
    )
}

export default CreatersRoute