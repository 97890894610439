import { CircularProgress } from "@material-ui/core"
import color from "../config/color"
import BalanceEl from "./BalanceEl"

const LoadingData = () => (
    <BalanceEl els={[
        <CircularProgress color="secondary" size={35} style={{ color: color.svg }} />,
        <div className="loadingHint">Đang tải dữ liệu, vui lòng đợi...</div>
    ]} />
)

export default LoadingData
