import { Avatar, Box, Button, CircularProgress, Container, Dialog, DialogTitle, IconButton, makeStyles, Popover, Tooltip, useMediaQuery, useTheme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { contrastColor } from 'contrast-color'
import { useContext, useEffect, useRef, useState } from 'react'
import { Bell, Check, ChevronDown, Search } from 'react-feather'
import { Calling, Image2, Logout, Message, Setting, TwoUsers, User } from 'react-iconly'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { app } from '../config/config'
import textTransform from '../config/functions/text'
import { UserContext } from '../contexts/user.context'
import headerStyles from '../css/Header.module.css'
import Account from './Account'
import TicketWrapper from './LiveChat'
import Notification from './Notification'
import Staff from './Staff'

const avatar_repo = [
    require('../assets/img/avatar_0.png').default,
    require('../assets/img/avatar_1.png').default,
    require('../assets/img/avatar_2.png').default,
    require('../assets/img/avatar_3.png').default,
    require('../assets/img/avatar_4.png').default,
    require('../assets/img/avatar_5.png').default,
    require('../assets/img/avatar_6.png').default,
    require('../assets/img/avatar_7.png').default,
    require('../assets/img/avatar_8.png').default,
]

const useStyles = makeStyles(theme => ({
    search: {
        background: theme.palette.primary.main + '90',
        filter: 'constrast(1.5)',
        '&:hover, &:focus': {
            background: theme.palette.primary.main
        }
    },
    hasAlert: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '0 5px',
        height: 18,
        background: '#4ea8e8',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        zIndex: 1,
        borderRadius: 12,
        fontSize: 'small',
        fontWeight: 500,
    }
}))

const Header = () => {
    const classes = useStyles()
    const theme = useTheme()
    const { user, setUser, miniDesign, userFetch, chi_nhanh, notification_token } = useContext(UserContext)
    const [openCP, setOpenCP] = useState(user.customer_name_bank === '' ? true : false)
    const [doiSoat, setDoiSoat] = useState(0)
    const [notice_count, set_notice_count] = useState(null)
    const [switches, setSwitches] = useState(false)
    const [confirmLogout, setConfirmLogout] = useState(0)
    const [avatar, setAvatar] = useState(() => {
        let _avatar = parseInt(localStorage.getItem('avatar'))
        if (Math.abs(_avatar) < avatar_repo.length)
            return _avatar
        return 0
    })
    const logo = chi_nhanh.header_text_logo ? chi_nhanh.header_text_logo : (window.location.hostname.toUpperCase().split('.')[0])
    const logoFirst = logo[0]
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? "account-popover" : undefined;
    const isXs = useMediaQuery(useTheme().breakpoints.only('xs'))
    const isXl = useMediaQuery(useTheme().breakpoints.only('xl'))
    const history = useHistory()
    const location = useLocation()
    const [
        input_search_ref,
        notificationRef,
        staffRef
    ] = [
            useRef(null),
            useRef(null),
            useRef(null)
        ]

    const handleCloseCP = () => {
        setOpenCP(false)
    }

    const handleSwitch = () => {
        if (switches)
            return
        setSwitches(true)
        setTimeout(() => {
            setUser(user.parent)
            setSwitches(false)
        }, 500)
    }

    // const handleCP = async (e) => {
    //     e.preventDefault()
    //     setChangingPassword(true)
    //     await new Promise(res => setTimeout(() => res(true), 1000))
    //     if (changing_password)
    //         return

    //     if (input_newpw2_ref.current.value != input_newpw_ref.current.value) {
    //         setChangingPassword(false)
    //         return enqueueSnackbar('Mật khẩu nhập lại không khớp', { variant: 'error' })
    //     }
    //     let formData = new FormData()
    //     formData.append('old_password', input_oldpw_ref.current.value)
    //     formData.append('new_password', input_newpw_ref.current.value)
    //     let res = await userFetch('/models/account/change_password.php', {
    //         method: 'POST',
    //         body: formData
    //     })
    //     let resj = await res.json()
    //     setChangingPassword(false)
    //     if (resj.status === 'error')
    //         return enqueueSnackbar(resj.msg, { variant: 'error' })
    //     handleCloseCP()
    //     setUser(null)
    // }

    const handleLogout = () => {
        if (confirmLogout === 2)
            return;
        else {
            setConfirmLogout(confirmLogout + 1)
            if (confirmLogout === 1) {
                setTimeout(async () => {
                    if (notification_token) {
                        let formData = new FormData()
                        formData.append('token', notification_token)
                        try {
                            await userFetch('/models/account/unsubscribe_notifications.php', {
                                method: 'POST',
                                body: formData
                            })
                        } catch (e) {
                            console.log(e)
                        }
                    }
                    setUser(null)
                }, 1000)
            }
        }
    }

    const changeAvatar = () => {
        let _avatar
        if (avatar < avatar_repo.length - 1)
            _avatar = avatar + 1
        else
            _avatar = 0
        setAvatar(_avatar)
        localStorage.setItem('avatar', _avatar)
    }

    const showPopover = (event) => {
        setConfirmLogout(0)
        setAnchorEl(event.currentTarget);
    };

    const closePopover = () => {
        setAnchorEl(null);
    };

    const searchOrder = (e) => {
        e.preventDefault()
        history.push(`/orders/?order_code=${input_search_ref.current.value}&date_range=null`)
        input_search_ref.current.value = ''
        input_search_ref.current.blur()
    }

    useEffect(() => {
        if (!isXs) return
        try {
            userFetch('/models/app/fastAnalyze.php')
                .then(r => r.json())
                .then(r => {
                    setDoiSoat(r.data.doi_doi_soat)
                })
        } catch (e) {
            console.log(e)
        }
    }, [location.pathname, isXs])

    const noticeCount = async () => {
        const res = await userFetch('/models/app/query/notifications.php?act=count')
        const resj = await res.json()
        set_notice_count(resj.data)
        if (notificationRef.current?.init === false) {
            const alert = new Audio('https://demo.ghsv.vn/system/assets/js/ping.mp3')
            alert.play()
        }
        if (notificationRef.current)
            notificationRef.current.init = false
    }

    /* notifications */
    useEffect(() => {
        const db = app.firestore()
        const doc = window.location.host + '_' + user.id // demo.ghsv.vn_711
        db.collection('ping_notifications').doc(doc).onSnapshot(() => {
            noticeCount()
            if (notificationRef.current?.is_open)
                notificationRef.current.getNew()
        })
    }, [])

    return (
        <>
            <Staff ref={staffRef} />
            <Notification ref={notificationRef} />
            <div className={headerStyles.container} id="fixedHeader" style={{ background: theme.palette.primary.main }}>
                <Container>
                    <div className={headerStyles.wrapper}>
                        {!isXs &&
                            <div className={headerStyles.logoAndSearch}>
                                <Link to="/" className={headerStyles.home}>
                                    <Box display="flex" fontWeight={700} alignItems="center" style={{ userSelect: 'none', textTransform: 'uppercase' }}>
                                        <div className={headerStyles.highLight} style={{ color: theme.palette.primary.main }}>{logoFirst}</div> {logo}</Box>
                                </Link>
                                <Box position="relative" className="xs-hidden">
                                    <form onSubmit={searchOrder}>
                                        <input ref={input_search_ref} autoComplete="off" maxLength="30" id="research_order" type="text" placeholder="Tra cứu nhanh mã đơn hàng / SĐT" className={`${headerStyles.search} ${classes.search}`} />
                                        <label htmlFor="research_order"><Search size={18} /></label>
                                    </form>
                                </Box>
                            </div>
                        }
                        {/* <Box className="o-hidden xs-block">
                            <IconButton><Category set="bold" color="#fff" /></IconButton>
                        </Box> */}
                        <div className={`${headerStyles.avatarAlert}`}>
                            <Box marginRight={isXs ? 1.5 : 5} flex={isXs ? 1 : null} display="flex" alignItems="center">
                                <Avatar src={avatar_repo[avatar]} style={{ width: 35, height: 35, display: isXs ? 'none' : 'block' }} />
                                <div className={headerStyles.username} onClick={showPopover} style={{ cursor: 'pointer' }}>
                                    <span>{user.customer_shop_code}</span> <ChevronDown style={{ verticalAlign: -2 }} size={13} />
                                </div>
                            </Box>
                            {(user.parent || user.user_ref === "0") &&
                                <IconButton onClick={() => {
                                    if (!user.parent)
                                        staffRef.current.open()
                                    else
                                        handleSwitch()
                                }}>
                                    {user.parent ? switches ? <CircularProgress size={24} style={{ color: '#fff' }} /> : <Logout set="bold" color="#fff" /> : <TwoUsers set="bold" color="#fff" />}
                                </IconButton>
                            }
                            <TicketWrapper />
                            <IconButton onClick={() => {
                                set_notice_count(null)
                                notificationRef.current.open()
                            }} size={isXs ? 'small' : 'medium'} style={isXs ? { height: 40 } : {}}>
                                {Boolean(notice_count) && <div className={classes.hasAlert} style={{ background: contrastColor({ bgColor: theme.palette.primary.main, fgLightColor: '#4ea8e8', fgDarkColor: 'red' }) }}>{notice_count}</div>}
                                <Box position="relative" lineHeight={0}>
                                    <Bell color="#fff" fill="#fff" />
                                </Box>
                            </IconButton>
                        </div>
                    </div>
                </Container>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={closePopover}
                disableScrollLock
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                PaperProps={{
                    style: {
                        marginTop: 15,
                        padding: 16,
                        width: isXs ? '100vw' : 350
                    },
                    variant: !isXs ? 'outlined' : 'elevation'
                }}
            >
                <div className={headerStyles.info}>
                    <Calling set="bulk" />
                    {user.customer_phone ? textTransform.mobile(user.customer_phone) : 'Chưa cập nhật'}
                </div>
                <div className={headerStyles.info}>
                    <Message set="bulk" />
                    {user.customer_email}
                </div>
                <div className={headerStyles.info}>
                    <User set="bulk" />
                    <span style={{
                        background: user.user_color,
                        color: contrastColor({ bgColor: user.user_color }),
                        fontWeight: 500,
                        padding: 5,
                        fontSize: 'small',
                        borderRadius: 5
                    }}>{user.user_ref !== "0" ? 'Tài khoản nhánh' : 'Tài khoản chính'}</span>
                </div>
                <div className={headerStyles.bank}>
                    <div className={headerStyles.info} style={{ alignItems: 'flex-start' }}>
                        <div>Bank:</div>
                        <div style={{ textAlign: 'right' }}>{user.customer_name_bank}</div>
                    </div>
                    <div className={headerStyles.info}>
                        <div>Chủ TK:</div>
                        <div style={{ textAlign: 'right' }}>{user.customer_id_bank}</div>
                    </div>
                    <div className={headerStyles.info}>
                        <div>STK:</div>
                        <div style={{ textAlign: 'right' }}>{user.customer_number_bank}</div>
                    </div>
                    {isXs &&
                        <div className={headerStyles.info} style={{ fontSize: 14, fontWeight: 700 }}>
                            <div>Đợi đối soát:</div>
                            <div>{Number(doiSoat).toLocaleString('en')}đ</div>
                        </div>
                    }
                </div>
                <Box display="flex" marginX="-12px" marginTop={1} alignItems="center">
                    <Box flex={1}>
                        {!isXs ?
                            <Tooltip title="Đổi Avatar">
                                <IconButton onClick={changeAvatar}>
                                    <Image2 set="curved" />
                                </IconButton>
                            </Tooltip> : (
                                <><Button onClick={() => {
                                    closePopover()
                                    history.push('/contact')
                                }} variant="outlined" style={{ marginLeft: 8 }}>Hỗ trợ</Button>
                                    {/* {' '}<Button onClick={() => {
                                        closePopover()
                                        history.push('/download')
                                    }} variant="outlined" style={{ marginLeft: 8 }}>App</Button> */}
                                </>)}
                    </Box>
                    <Tooltip title="Cài đặt">
                        <IconButton onClick={() => {
                            closePopover()
                            setOpenCP(true)
                        }}>
                            <Setting set="curved" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={['Đăng xuất', 'Xác nhận', 'Đang đăng xuất'][confirmLogout]}>
                        <IconButton color={confirmLogout === 1 ? 'secondary' : 'default'} onClick={handleLogout}>
                            {
                                [
                                    <Logout set="curved" />,
                                    <Check />,
                                    <CircularProgress color="secondary" size={24} />
                                ][confirmLogout]
                            }
                        </IconButton>
                    </Tooltip>
                </Box>
            </Popover>
            <Box height={isXs ? 80 : miniDesign ? 90 : 100} />
            <Dialog
                open={openCP}
                onClose={handleCloseCP}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={isXl ? 'md' : 'sm'}
                fullWidth
                fullScreen={isXs}
            >
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        Thông tin tài khoản
                        <IconButton aria-label="close" onClick={handleCloseCP}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Account onClose={handleCloseCP} />
            </Dialog>
        </>
    )
}

export default Header