import { IconButton } from "@material-ui/core"
import { ArrowBack, ArrowForward } from "@material-ui/icons"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { X } from "react-feather"

const PhotoViewer = forwardRef((_, ref) => {
    const [_crr, setCrr] = useState(false)
    const [_isShow, setIsShow] = useState(false)
    const [images, setImages] = useState([])

    useImperativeHandle(ref, () => ({
        open: (crr, images) => {
            setIsShow(true)
            setCrr(crr)
            setImages(images)
        },
        close: () => setIsShow(false)
    }));

    const next = (e) => { e?.stopPropagation(); _crr < images.length - 1 && setCrr(_crr + 1)}
    const prev = (e) => { e?.stopPropagation(); _crr > 0 && setCrr(_crr - 1)}
    const byKey = (e) => {
        if (e.key == 'ArrowRight') next()
        else if (e.key == 'ArrowLeft') prev()
    }
    useEffect(() => {
        window.document.addEventListener('keydown', byKey)
        return () => window.document.removeEventListener('keydown', byKey)
    }, [_crr]);

    useEffect(() => {
        const handleClose = (e) => {
            if (e.key == 'Escape') {
                setIsShow(false);
            }
        }
        if(!_isShow){
            window.document.removeEventListener('keydown', handleClose)
            return;
        }
        window.document.addEventListener('keydown', handleClose)
    }, [_isShow])

    return (
        <div style={{
            position: 'fixed',
            zIndex: 99999,
            backgroundColor: 'rgb(0 0 0 / 82%)',
            display: _isShow ? 'block' : 'none',
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
        }} onClick={() => setIsShow(false)}>
            <div style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 8,
                display: 'flex',
            }}>
                <IconButton disabled={_crr <= 0} onClick={prev}>
                    <ArrowBack style={{ color: _crr > 0 && '#ddd' }} />
                </IconButton>

                <img onClick={e => e.stopPropagation()} src={images[_crr]} style={{ padding: 8, maxHeight: '80vh', maxWidth: '70vw' }} alt="" />

                <IconButton disabled={_crr >= images.length - 1} onClick={next}>
                    <ArrowForward style={{ color: _crr < images.length - 1 && '#ddd' }} />
                </IconButton>
            </div>
            <div style={{
                position: 'absolute',
                right: 8,
                top: 8
            }}>
                <IconButton onClick={() => setIsShow(false)}>
                    <X style={{ color: '#fff' }} />
                </IconButton>
            </div>
        </div>
    )
})

export default PhotoViewer